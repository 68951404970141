import React from 'react';
import { Pagination, Table } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import SongWriterRow from 'src/pages/songWriterListPage/songWriterRow';
import type { SongWriter } from 'src/types';

interface Props {
    category: string;
    songWriters: SongWriter[];
    page: number;
    totalPages: number;
    totalCount: number;
    pageSize: number;
    onPaginationChange: (page: number) => void;
    onSongWriterView: (songWriter: SongWriter) => void;
    onSongWriterEdit: (songWriter: SongWriter) => void;
    onSongWriterDelete: (songWriter: SongWriter) => void;
    onSetPageSize: (pageSize: number) => void;
}

const SongWriterTable: React.FC<Props> = ({
    category,
    songWriters,
    page,
    totalPages,
    totalCount,
    pageSize,
    onPaginationChange,
    onSongWriterView,
    onSongWriterEdit,
    onSongWriterDelete,
    onSetPageSize,
}) => {
    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>{formatMessage('generic.songWriter')}</th>
                        <th>{formatMessage('generic.pro')}</th>
                        <th>{formatMessage('generic.ipi')}</th>
                        <th>
                            {formatMessage(
                                'songWriters.associatedCompositions'
                            )}
                        </th>
                        <th>{formatMessage('generic.actions')}</th>
                    </tr>
                </thead>

                <tbody>
                    {songWriters.map(songWriter => (
                        <SongWriterRow
                            key={songWriter.id}
                            category={category}
                            songWriter={songWriter}
                            onSongWriterView={onSongWriterView}
                            onSongWriterEdit={onSongWriterEdit}
                            onSongWriterDelete={onSongWriterDelete}
                        />
                    ))}
                </tbody>
            </Table>

            {totalPages > 1 && (
                <div>
                    <Pagination
                        onChange={onPaginationChange}
                        currentPage={page}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        onSetPageSize={onSetPageSize}
                    />
                </div>
            )}
        </>
    );
};

export default SongWriterTable;
