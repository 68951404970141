import React from 'react';
import { Sidecar } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import type { SongWriter } from 'src/types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    songWriter: SongWriter | null;
}

const ViewSongWriter: React.FC<Props> = ({ isOpen, onClose, songWriter }) => (
    <Sidecar
        isOpen={isOpen}
        onRequestClose={onClose}
        className="ViewSongWriter"
        header={formatMessage('viewSongWriter.title')}
    >
        <div key={`main-${songWriter?.id}`}>
            <div>
                <div className="ViewSongWriter-label">
                    {formatMessage('songWriterManager.songWriterLabelName')}
                </div>
                <div className="ViewSongWriter-value">
                    {songWriter?.vendor.name} - {songWriter?.vendor.id.vendorId}
                </div>
            </div>
            <div>
                <div className="ViewSongWriter-label">
                    {formatMessage('songWriterManager.songWriterLegalName')}
                </div>
                <div className="ViewSongWriter-value">
                    {songWriter?.legalName}
                </div>
            </div>

            {(songWriter?.professionallyKnownAs?.length ?? 0) > 0 && (
                <div>
                    <div className="ViewSongWriter-label">
                        {formatMessage('songWriters.pka')}
                    </div>
                    <div className="ViewSongWriter-value">
                        {songWriter?.professionallyKnownAs.map(pka => (
                            <div key={pka}>{pka}</div>
                        ))}
                    </div>
                </div>
            )}

            {songWriter?.pro && (
                <div>
                    <div className="ViewSongWriter-label">
                        {formatMessage('songWriterManager.songwritersPro')}
                    </div>
                    <div className="ViewSongWriter-value">
                        {songWriter?.pro}
                    </div>
                </div>
            )}

            {songWriter?.ipi && (
                <div>
                    <div className="ViewSongWriter-label">
                        {formatMessage('songWriterManager.songwritersIpi')}
                    </div>
                    <div className="ViewSongWriter-value">
                        {songWriter?.ipi}
                    </div>
                </div>
            )}

            {songWriter?.agreements.map(agreement => (
                <div key={agreement.id}>
                    {agreement.publisher?.name && (
                        <div>
                            <div className="ViewSongWriter-label">
                                {formatMessage('generic.publishingCompany')}
                            </div>
                            <div className="ViewSongWriter-value">
                                {agreement.publisher?.name}
                            </div>
                        </div>
                    )}

                    {agreement.publisher?.pro && (
                        <div>
                            <div className="ViewSongWriter-label">
                                {formatMessage('generic.publishingCompanyPro')}
                            </div>
                            <div className="ViewSongWriter-value">
                                {agreement.publisher?.pro}
                            </div>
                        </div>
                    )}

                    {agreement.publisher?.ipi && (
                        <div>
                            <div className="ViewSongWriter-label">
                                {formatMessage('generic.publishingCompanyIpi')}
                            </div>
                            <div className="ViewSongWriter-value">
                                {agreement.publisher?.ipi}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    </Sidecar>
);

export default ViewSongWriter;
