import type { ObjectStringValues } from 'src/types';

export const PRO_LIST = [
    'AAS',
    'ABRAMUS',
    'ACAM',
    'ACDAM',
    'ACUM',
    'ADDAF',
    'AEPI',
    'AGADU',
    'AKKA-LAA',
    'AKM',
    'ALBAUTOR',
    'AMAR',
    'AMCOS',
    'AMRA',
    'AMUS',
    'ANACIM',
    'ANCO',
    'APA',
    'APDAYC',
    'APRA',
    'ARTISJUS',
    'ASCAP',
    'ASSIM',
    'AUSTRO-MECHANA',
    'AUTODIA',
    'BackOffice',
    'BMDAV',
    'BMI',
    'BUMA',
    'CAPAC',
    'CAPASSO',
    'CASH',
    'CHA',
    'CIS-Net AVI',
    'CMRRA',
    'COMPASS',
    'COSCAP',
    'COSON',
    'COTT',
    'EAU',
    'EDEM',
    'EUCADA',
    'FILSCAP',
    'FOX',
    'GCA',
    'GEMA',
    'GMR',
    'HDS-ZAMP',
    'ICE',
    'IMRO',
    'IPRS',
    'JACAP',
    'JASRAC',
    'KCI',
    'KODA',
    'KOMCA',
    'KOSCAP',
    'LATGA',
    'LITA',
    'MACA',
    'MACP',
    'MCPS',
    'MCSC',
    'MCT',
    'MESAM',
    'MRS',
    'MSG',
    'MUSICAUTOR',
    'MusicMark',
    'MUST',
    'NASCAM',
    'NCB',
    'NS',
    'OSA',
    'PAM CG',
    'PROCAN',
    'PRS',
    'RAO',
    'SABAM',
    'SABEM',
    'SACD',
    'SACEM',
    'SACM',
    'SACVEN',
    'SADAIC',
    'SADEMBRA',
    'SAMRO',
    'SARRAL',
    'SAYCE',
    'SAYCO',
    'SAZAS',
    'SBACEM',
    'SBAT',
    'SCD',
    'SDRM',
    'SESAC',
    'SGAE',
    'SIAE',
    'SICAM',
    'SOBODAYC',
    'SOCAN',
    'SOCINPRO',
    'SODRAC',
    'SOKOJ',
    'SOZA',
    'SPA',
    'SPAC',
    'SPACEM',
    'SQN',
    'STEF',
    'STEMRA',
    'STIM',
    'SUISA',
    'TEOSTO',
    'TONO',
    'UACRR',
    'UBC',
    'UCMR-ADA',
    'UNISON',
    'UNS',
    'VCPMC',
    'WAMI',
    'ZAIKS',
    'ZAMP',
    'AACIMH',
    'AEI-GUATEMALA',
    'AMAR SOMBRAS',
    'BBDA',
    'BCDA',
    'BGDA',
    'BMDA',
    'BSCAP',
    'BUBEDRA',
    'BUMDA',
    'BURIDA',
    'BUTODRA',
    'CMC',
    'COSOMA',
    'COSOTA',
    'ECCO',
    'KazAK',
    'MÜST',
    'NCIP',
    'NGO UACRR',
    'OMDA',
    'ONDA',
    'OTDAV',
    'SACEM LUXEMBOURG',
    'SACENC',
    'SACERAU',
    'SACIM, EGC',
    'SACS',
    'SASUR',
    'SGACEDOM',
    'SOBODAYCOM',
    'UPRS',
    'ZAMCOPS',
    'ZAMP Macedonia',
    'ZIMURA',
];

export const APP_TITLE = 'Publishing';
export const PROFILE_PUBLISHING = 'PublishingProfile';

export const FEATURE_FLAGS = {
    ALLOW_DELETE_SONG: 'publishing_admin_delete_song',
    ALLOW_DELETE_SONG_WRITER: 'publishing_admin_delete_songwriter',
    ALLOW_DISPLAY_PUBLISHERS_LIST: 'publishing_publishers_list',
    ALLOW_UNCONTROLLED_WRITERS: 'publishing_allow_no_controlled_writers',
    LIMIT_MODIFY_ACCESS: 'publishing_limit_modify_access',
    USE_SIMILARITY_SORT: 'publishing_use_sort_by_similarity',
};

export const CONTACT_EMAILS: { [key: string]: ObjectStringValues } = {
    orchard: {
        appContact: 'publishing@theorchard.com',
        orgContact: 'clientservices@theorchard.com',
    },
    awal: {
        appContact: 'publishing@awal.com',
        orgContact: 'clientservices@awal.com',
    },
};

export const LABEL_TYPE = 'Label';
export const SUBACCOUNT_TYPE = 'Subaccount';

export const SHOW_ALL_LABEL = 'Show All Labels';

export const BRAND_AWAL = 'awal';
