import type {
    PublishingCompositionsListingFilter,
    PublishingSongWritersFilterLabelInput,
} from 'src/data/queries';
import type { PublishingPublishersFilter } from 'src/data/schemaTypes';
export const newSongUrl = () => '/song/new';
export const bulkIngestionUrl = () => '/bulk-ingestion';
export const newSongFormUrl = (gsrId = '') => `/song/new/form?gsrId=${gsrId}`;
export const newSongFormUrlFromLsr = (lsrId = '') =>
    `/song/new/form?lsrId=${lsrId}`;
export const songUrl = (id: string) => `/song/${id}`;

export const publishersListUrl = (
    page: number,
    { nameSearch }: PublishingPublishersFilter
) => {
    const searchParams = new URLSearchParams();

    if (nameSearch)
        searchParams.append('publisher', encodeURIComponent(nameSearch));

    return `/publishers/${page}${searchParams.size ? `?${searchParams.toString()}` : ''}`;
};
export const songsDraftsUrl = (page: number) => `/songs/drafts?page=${page}`;
export const songsSubmittedUrl = (page: number) =>
    `/songs/submitted?page=${page}`;

export const songsUrlFromFilter = (
    tab: string,
    filter: PublishingCompositionsListingFilter,
    isEmployeeWithFullAccess: boolean = false
) => ({
    pathname: `/songs/${tab}`,
    search:
        `?page=${filter.page}` +
        (filter.label?.id.vendorId
            ? `&labelId=${filter.label?.id.vendorId}`
            : '') +
        (filter.titleSearch
            ? `&term=${encodeURIComponent(filter.titleSearch)}`
            : '') +
        (filter.songWriters
            ? `&songWriters=${encodeURIComponent(filter.songWriters?.join(','))}`
            : '') +
        (isEmployeeWithFullAccess && filter.brand
            ? `&brand=${filter.brand || ''}`
            : ''),
});

export const songWritersUrl = (
    page: string | number,
    { brand, legalNameSearch }: PublishingSongWritersFilterLabelInput,
    isEmployeeWithFullAccess: boolean = true
) => {
    const searchParams = new URLSearchParams();
    if (isEmployeeWithFullAccess && brand) searchParams.append('brand', brand);

    if (legalNameSearch)
        searchParams.append('songwriter', encodeURIComponent(legalNameSearch));

    return {
        pathname: `/song-writers/${page}`,
        search: searchParams.size ? `?${searchParams.toString()}` : '',
    };
};
