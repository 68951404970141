/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    BigInt: { input: any; output: any };
    Date: { input: string; output: string };
    DateTime: { input: string; output: string };
    Decimal: { input: any; output: any };
    JSON: { input: string; output: string };
    JSONObject: { input: any; output: any };
    KeyedKafkaEvent: { input: any; output: any };
    Long: { input: number; output: number };
    OrchardSoundRecordingDeliveryHistoryMessage: { input: any; output: any };
    OrchardSoundRecordingVersionData: { input: any; output: any };
    PPAction: { input: any; output: any };
    PPResourceType: { input: any; output: any };
    Time: { input: any; output: any };
    URL: { input: any; output: any };
};

export type AbacusAccountContractVatDetailInput = {
    accountId: Scalars['ID']['input'];
    companyCode: Scalars['String']['input'];
    contractId: Scalars['ID']['input'];
    isVatEligible: Scalars['Boolean']['input'];
    isVatRegistered: Scalars['Boolean']['input'];
    vatCountry: Scalars['String']['input'];
};

export type AbacusAccountInput = {
    accountId: Scalars['ID']['input'];
    accountName: Scalars['String']['input'];
    contractTemplateId?: InputMaybe<Scalars['ID']['input']>;
    /** Country alpha-3 iso code. */
    countryOfTaxResidence?: InputMaybe<Scalars['String']['input']>;
    createdBy?: InputMaybe<Scalars['String']['input']>;
    /** Indicates whether the account is created from the GDA onboarding or internal jenkins job. */
    creationSource?: InputMaybe<Scalars['String']['input']>;
    /** Currency alpha-3 iso code. */
    currencyCode: Scalars['String']['input'];
};

export type AbacusAccountPayeeKnrDetailsInput = {
    sapVendorId?: InputMaybe<Scalars['ID']['input']>;
    wForm?: InputMaybe<KnrWFormDetailsInput>;
};

export type AbacusAccountPayeeTaxDetailsInput = {
    /**
     * The following fields are not collected here and must be set to `null`, or omitted:
     * `company`, `firstName`, `lastName`, `phone`
     */
    address?: InputMaybe<ShopifyAddressInput>;
    /**
     * The legal name of the entity.
     *
     * If the Payee is VAT registered (`vatNumber` non-null) then this field is mandatory.
     * Otherwise it is not captured and must be omitted.
     */
    businessName?: InputMaybe<Scalars['String']['input']>;
    /**
     * The registered number of the business.
     *
     * If the Payee is VAT registered (`vatNumber` non-null) then this field is optional and may be `null`.
     * Otherwise it is not captured and must be omitted.
     */
    businessNumber?: InputMaybe<Scalars['String']['input']>;
    vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum AbacusAccountPayeeTaxDetailsVatNumberError {
    /** The call to the API for validating VAT numbers did not succeed because the API was unreachable. */
    API_UNREACHABLE = 'API_UNREACHABLE',
    /** The call to the HMRC API for validating VAT numbers does not recognize the provided number as valid. */
    HMRC_API_CHECK_FAILED = 'HMRC_API_CHECK_FAILED',
    /** The VAT number provided contains an invalid country code. */
    INVALID_COUNTRY_CODE = 'INVALID_COUNTRY_CODE',
    /** The Payee's account has a Payment Entity based in the UK and the format of the VAT number input does not match the regex `(^[0-9]{9}$)|(^[0-9]{12}$)|(^GB[0-9]{9}$)|(^GB[0-9]{12}$)`. */
    UK_VAT_FORMAT = 'UK_VAT_FORMAT',
    /** The call to the VIES API for validating VAT numbers does not recognize the provided number as valid. */
    VIES_API_CHECK_FAILED = 'VIES_API_CHECK_FAILED',
}

export type AbacusAccountPayeeUpdate = {
    accountPayeeId: Scalars['ID']['input'];
    payoneerPayeeId?: InputMaybe<Scalars['ID']['input']>;
    payoneerPayeeName?: InputMaybe<Scalars['String']['input']>;
    sapVendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type AbacusAccountPaymentTermInput = {
    accountId: Scalars['ID']['input'];
    agreementTypeId?: InputMaybe<Scalars['ID']['input']>;
    currencyCode: Scalars['String']['input'];
    paymentEntityId?: InputMaybe<Scalars['ID']['input']>;
    paymentMinimum?: InputMaybe<Scalars['String']['input']>;
    /**
     * One of '30_days_after_month_end',
     * '45_days_after_month_end',
     * '60_days_after_month_end',
     * '90_days_after_month_end',
     * '30_days_after_quarter_end',
     * '45_days_after_quarter_end',
     * '60_days_after_quarter_end',
     * or '90_days_after_quarter_end'.
     */
    paymentSchedule?: InputMaybe<Scalars['String']['input']>;
    /** To be deprecated in favor of AbacusReferencePaymentEntity. */
    signingEntityId?: InputMaybe<Scalars['ID']['input']>;
};

export type AbacusAccountPaymentTermUpdate = {
    accountPaymentTermId: Scalars['ID']['input'];
    agreementTypeId?: InputMaybe<Scalars['ID']['input']>;
    currencyCode?: InputMaybe<Scalars['String']['input']>;
    paymentEntityId?: InputMaybe<Scalars['ID']['input']>;
    paymentMinimum?: InputMaybe<Scalars['String']['input']>;
    /**
     * One of '30_days_after_month_end',
     * '45_days_after_month_end',
     * '60_days_after_month_end',
     * '90_days_after_month_end',
     * '30_days_after_quarter_end',
     * '45_days_after_quarter_end',
     * '60_days_after_quarter_end',
     * or '90_days_after_quarter_end'.
     */
    paymentSchedule?: InputMaybe<Scalars['String']['input']>;
    /** To be deprecated in favor of AbacusReferencePaymentEntity. */
    signingEntityId?: InputMaybe<Scalars['ID']['input']>;
};

export type AbacusAccountTaxInfoCreate = {
    accountId: Scalars['ID']['input'];
    /** Country alpha-3 iso code. */
    countryOfTaxResidence?: InputMaybe<Scalars['String']['input']>;
    isSbaSigned?: InputMaybe<Scalars['Boolean']['input']>;
    /** Relevant for US taxes */
    isTaxTreatyClaimed?: InputMaybe<Scalars['Boolean']['input']>;
    isVatExempt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AbacusAccountTaxInfoInput = {
    accountTaxInfoId: Scalars['ID']['input'];
    /** Country alpha-3 iso code. */
    countryOfTaxResidence?: InputMaybe<Scalars['String']['input']>;
    isSbaSigned?: InputMaybe<Scalars['Boolean']['input']>;
    /** Relevant for US taxes */
    isTaxTreatyClaimed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AbacusAccountUpdate = {
    accountId: Scalars['ID']['input'];
    accountName?: InputMaybe<Scalars['String']['input']>;
};

export type AbacusAccountingPeriodInput = {
    accountingPeriodName: Scalars['String']['input'];
    /** One of 'distribution', 'legacy_distribution', or 'neighbouring_rights'. Defaults to 'distribution'. */
    contractType: Scalars['String']['input'];
    statementPeriodId: Scalars['ID']['input'];
};

export type AbacusAccountingPeriodStateUpdate = {
    accountingPeriodId: Scalars['ID']['input'];
    actionStates: Array<InputMaybe<AbacusStateUpdate>>;
};

export type AbacusAccountingPeriodUpdate = {
    accountingPeriodId: Scalars['ID']['input'];
    accountingPeriodName?: InputMaybe<Scalars['String']['input']>;
    accountingPeriodStatus?: InputMaybe<Scalars['String']['input']>;
};

export type AbacusAccountingRunUpdate = {
    accountingRunId: Scalars['ID']['input'];
    accountingRunStatus?: InputMaybe<Scalars['String']['input']>;
};

export type AbacusContractAdvanceInput = {
    accountId?: InputMaybe<Scalars['ID']['input']>;
    accountPaymentCurrencyCode?: InputMaybe<Scalars['String']['input']>;
    advanceDescription: Scalars['String']['input'];
    advanceStatus?: InputMaybe<AbacusContractAdvanceStatus>;
    amount: Scalars['Float']['input'];
    contractId: Scalars['ID']['input'];
    currencyCode: Scalars['String']['input'];
    exchangeRate?: InputMaybe<Scalars['Float']['input']>;
    milestone: AbacusContractAdvanceMilestone;
    milestoneDate?: InputMaybe<Scalars['Date']['input']>;
    milestoneDescription: Scalars['String']['input'];
    note?: InputMaybe<Scalars['String']['input']>;
    referencePaymentTypeId?: InputMaybe<Scalars['ID']['input']>;
    statementPeriodId?: InputMaybe<Scalars['ID']['input']>;
    usSourceIncomeRate?: InputMaybe<Scalars['Float']['input']>;
    vatAmount?: InputMaybe<Scalars['Float']['input']>;
    withholdingTaxAmount?: InputMaybe<Scalars['Float']['input']>;
};

export enum AbacusContractAdvanceMilestone {
    contract_execution = 'contract_execution',
    delivery = 'delivery',
    other = 'other',
    recoupment = 'recoupment',
    sales_milestone = 'sales_milestone',
    scheduled_installment = 'scheduled_installment',
}

export enum AbacusContractAdvanceStatus {
    approved = 'approved',
    deleted = 'deleted',
    in_review = 'in_review',
    not_qualified = 'not_qualified',
    paid = 'paid',
    pending_payment = 'pending_payment',
    qualified = 'qualified',
}

export type AbacusContractAdvanceUpdateInput = {
    accountId?: InputMaybe<Scalars['ID']['input']>;
    accountPaymentCurrencyCode?: InputMaybe<Scalars['String']['input']>;
    advanceDescription?: InputMaybe<Scalars['String']['input']>;
    advanceStatus?: InputMaybe<AbacusContractAdvanceStatus>;
    amount?: InputMaybe<Scalars['Float']['input']>;
    contractAdvanceId: Scalars['ID']['input'];
    currencyCode?: InputMaybe<Scalars['String']['input']>;
    exchangeRate?: InputMaybe<Scalars['Float']['input']>;
    milestone?: InputMaybe<AbacusContractAdvanceMilestone>;
    milestoneDate?: InputMaybe<Scalars['Date']['input']>;
    milestoneDescription?: InputMaybe<Scalars['String']['input']>;
    note?: InputMaybe<Scalars['String']['input']>;
    referencePaymentTypeId?: InputMaybe<Scalars['ID']['input']>;
    statementPeriodId?: InputMaybe<Scalars['ID']['input']>;
    usSourceIncomeRate?: InputMaybe<Scalars['Float']['input']>;
    vatAmount?: InputMaybe<Scalars['Float']['input']>;
    withholdingTaxAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type AbacusContractExclusionInput = {
    contractId: Scalars['ID']['input'];
    countries: Array<InputMaybe<Scalars['String']['input']>>;
    stores: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AbacusContractInput = {
    accountId: Scalars['ID']['input'];
    contractName: Scalars['String']['input'];
    /** One of 'distribution', 'legacy_distribution', or 'neighbouring_rights'. Defaults to 'distribution'. */
    contractType: Scalars['String']['input'];
    /** Formatted YYYY-MM-DD. */
    executionDate?: InputMaybe<Scalars['Date']['input']>;
    generalNote?: InputMaybe<Scalars['String']['input']>;
    oaContractId?: InputMaybe<Scalars['Int']['input']>;
    /** Used to update parent account payment entity when the account's payment entity does not exist. */
    referencePaymentEntityId?: InputMaybe<Scalars['ID']['input']>;
    /** To be deprecated in favor of AbacusReferenceSigningEntity. */
    referenceSapProfitCenterId?: InputMaybe<Scalars['ID']['input']>;
    referenceSigningEntityId?: InputMaybe<Scalars['ID']['input']>;
    runControllerId: Scalars['ID']['input'];
    summaryNote?: InputMaybe<Scalars['String']['input']>;
    /** Formatted YYYY-MM-DD. To be deprecated. */
    termEnd?: InputMaybe<Scalars['Date']['input']>;
    /** Formatted YYYY-MM-DD. To be deprecated in favor of AbacusContractLifecycleInput.lifecycleTermStart. */
    termStart?: InputMaybe<Scalars['Date']['input']>;
};

export type AbacusContractLifecycleInput = {
    /** Formatted YYYY-MM-DD. */
    lifecycleTermStart: Scalars['Date']['input'];
};

export type AbacusContractLifecycleScheduleInput = {
    collectionPeriodDetailInterval?: InputMaybe<Scalars['Int']['input']>;
    collectionPeriodDetailType?: InputMaybe<AbacusContractLifecycleSchedulePeriodType>;
    renewalOffsetDetailInterval?: InputMaybe<Scalars['Int']['input']>;
    renewalOffsetDetailType?: InputMaybe<AbacusContractLifecycleSchedulePeriodType>;
    renewalType: AbacusContractLifecycleScheduleRenewalType;
    /** Formatted YYYY-MM-DD. */
    scheduleEnd?: InputMaybe<Scalars['Date']['input']>;
    terminationNoticeDetailInterval: Scalars['Int']['input'];
    terminationNoticeDetailType: AbacusContractLifecycleSchedulePeriodType;
};

export enum AbacusContractLifecycleSchedulePeriodType {
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum AbacusContractLifecycleScheduleRenewalType {
    CONTINUOUSLY_ACTIVE = 'CONTINUOUSLY_ACTIVE',
    RENEW_AFTER_CERTAIN_DATE = 'RENEW_AFTER_CERTAIN_DATE',
    RENEW_PERIODICALLY = 'RENEW_PERIODICALLY',
}

export type AbacusContractLifecycleScheduleUpdate = {
    collectionPeriodDetailInterval?: InputMaybe<Scalars['Int']['input']>;
    collectionPeriodDetailType?: InputMaybe<AbacusContractLifecycleSchedulePeriodType>;
    /** The contractLifecycleScheduleId is optional because the mutation is functionally an upsert. */
    contractLifecycleScheduleId?: InputMaybe<Scalars['ID']['input']>;
    renewalOffsetDetailInterval?: InputMaybe<Scalars['Int']['input']>;
    renewalOffsetDetailType?: InputMaybe<AbacusContractLifecycleSchedulePeriodType>;
    renewalType: AbacusContractLifecycleScheduleRenewalType;
    /** Formatted YYYY-MM-DD. */
    scheduleEnd?: InputMaybe<Scalars['Date']['input']>;
    terminationNoticeDetailInterval: Scalars['Int']['input'];
    terminationNoticeDetailType: AbacusContractLifecycleSchedulePeriodType;
};

export enum AbacusContractLifecycleStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INIT = 'INIT',
    IN_COLLECTION_PERIOD = 'IN_COLLECTION_PERIOD',
    TERMINATED = 'TERMINATED',
    TO_BE_TERMINATED = 'TO_BE_TERMINATED',
}

export enum AbacusContractMechDeductionAdminType {
    BOTH = 'BOTH',
    BUSINESS = 'BUSINESS',
    CUSTOMER = 'CUSTOMER',
}

export enum AbacusContractMechDeductionMechanicalType {
    DIGITAL = 'DIGITAL',
    PHYSICAL = 'PHYSICAL',
}

export enum AbacusContractMechDeductionTerritory {
    CAN = 'CAN',
    ROW = 'ROW',
    USA = 'USA',
}

export type AbacusContractMechanicalDeductionCreate = {
    adminFee?: InputMaybe<Scalars['Float']['input']>;
    adminType: AbacusContractMechDeductionAdminType;
    contractId: Scalars['ID']['input'];
    isWorldWide?: InputMaybe<Scalars['Boolean']['input']>;
    mechanicalType: Array<AbacusContractMechDeductionMechanicalType>;
    territory?: InputMaybe<AbacusContractMechDeductionTerritory>;
};

export type AbacusContractMechanicalDeductionUpdate = {
    adminFee?: InputMaybe<Scalars['Float']['input']>;
    adminType?: InputMaybe<AbacusContractMechDeductionAdminType>;
    contractMechanicalDeductionId: Scalars['ID']['input'];
    mechanicalType?: InputMaybe<
        Array<AbacusContractMechDeductionMechanicalType>
    >;
};

export type AbacusContractPartyInput = {
    contractId: Scalars['ID']['input'];
    targetId: Scalars['String']['input'];
    targetType: AbacusContractPartyTargetType;
};

export enum AbacusContractPartyTargetType {
    CONTRIBUTOR = 'CONTRIBUTOR',
    LABEL = 'LABEL',
}

export type AbacusContractReserveInput = {
    contractId: Scalars['ID']['input'];
    installmentsInMonths: Scalars['Int']['input'];
    reserveRate: Scalars['Float']['input'];
    reserveReleaseOffsetInMonths: Scalars['Int']['input'];
};

export type AbacusContractTermAndConditionBulkInput = {
    /** List of identifiers of the specified termType. */
    attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
    attachmentsRelations?: InputMaybe<AbacusContractTermAttachmentsRelationsInput>;
    contractId: Scalars['ID']['input'];
    contractTermConditions?: InputMaybe<
        Array<InputMaybe<AbacusContractTermConditionInput>>
    >;
    /** Defaults to true. */
    isBaseTerm?: InputMaybe<Scalars['Boolean']['input']>;
    /** One of 'label', 'track', or 'product'. */
    termType: Scalars['String']['input'];
};

export type AbacusContractTermAttachmentsRelationsInput = {
    contributorIds?: InputMaybe<Array<Scalars['String']['input']>>;
    labelIds?: InputMaybe<Array<Scalars['String']['input']>>;
    upcs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AbacusContractTermConditionBulkInput = {
    conditionsPayload?: InputMaybe<Array<AbacusContractTermConditionInput>>;
    contractTermId: Scalars['ID']['input'];
};

export type AbacusContractTermConditionBulkUpdate = {
    conditionsPayload?: InputMaybe<Array<AbacusContractTermConditionUpdate>>;
    contractTermId: Scalars['ID']['input'];
};

export type AbacusContractTermConditionInput = {
    commission?: InputMaybe<Scalars['Float']['input']>;
    conditions?: InputMaybe<AbacusContractTermConditionSetInput>;
    contractTermConditionName?: InputMaybe<Scalars['String']['input']>;
    priority: Scalars['Int']['input'];
    termRate?: InputMaybe<Scalars['Float']['input']>;
};

export type AbacusContractTermConditionSetInput = {
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    stores?: InputMaybe<Array<Scalars['String']['input']>>;
    transactionTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AbacusContractTermConditionSoftDelete = {
    contractTermConditionId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AbacusContractTermConditionUpdate = {
    commission?: InputMaybe<Scalars['Float']['input']>;
    conditions?: InputMaybe<AbacusContractTermConditionSetInput>;
    contractTermConditionId: Scalars['ID']['input'];
    contractTermConditionName?: InputMaybe<Scalars['String']['input']>;
    priority?: InputMaybe<Scalars['Int']['input']>;
    termRate?: InputMaybe<Scalars['Float']['input']>;
};

/** Hard delete AbacusContractTerms and AbacusContractTermConditions by parent contractId. */
export type AbacusContractTermConditionsBulkDelete = {
    contractIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AbacusContractTermInput = {
    /** List of identifiers of the specified termType. */
    attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
    attachmentsRelations?: InputMaybe<AbacusContractTermAttachmentsRelationsInput>;
    contractId: Scalars['ID']['input'];
    contractTermName?: InputMaybe<Scalars['String']['input']>;
    /** Defaults to true. */
    isBaseTerm?: InputMaybe<Scalars['Boolean']['input']>;
    /** One of 'label', 'track', or 'product'. */
    scheduleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    termType: Scalars['String']['input'];
};

export type AbacusContractTermUpdate = {
    /** List of identifiers of the specified termType. */
    attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
    attachmentsRelations?: InputMaybe<AbacusContractTermAttachmentsRelationsInput>;
    contractTermId: Scalars['ID']['input'];
    contractTermName?: InputMaybe<Scalars['String']['input']>;
    scheduleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    termType?: InputMaybe<Scalars['String']['input']>;
};

export type AbacusContractTerminate = {
    contractId: Scalars['ID']['input'];
    terminationEffective: Scalars['Date']['input'];
    terminationNoticeReceived?: InputMaybe<Scalars['Date']['input']>;
};

export enum AbacusContractType {
    DISTRIBUTION = 'DISTRIBUTION',
    NEIGHBOURING_RIGHTS = 'NEIGHBOURING_RIGHTS',
}

export type AbacusContractUpdate = {
    contractId: Scalars['ID']['input'];
    contractName?: InputMaybe<Scalars['String']['input']>;
    /** Formatted YYYY-MM-DD. */
    executionDate?: InputMaybe<Scalars['Date']['input']>;
    generalNote?: InputMaybe<Scalars['String']['input']>;
    initialStartDate?: InputMaybe<Scalars['Date']['input']>;
    isExcludedFromAccountingRun?: InputMaybe<Scalars['Boolean']['input']>;
    /** To be deprecated in favor of AbacusReferenceSigningEntity. */
    referenceSapProfitCenterId?: InputMaybe<Scalars['ID']['input']>;
    referenceSigningEntityId?: InputMaybe<Scalars['ID']['input']>;
    summaryNote?: InputMaybe<Scalars['String']['input']>;
    /** Formatted YYYY-MM-DD. To be deprecated. */
    termEnd?: InputMaybe<Scalars['Date']['input']>;
    /** Formatted YYYY-MM-DD. To be deprecated in favor of AbacusContractLifecycleInput.lifecycleTermStart. */
    termStart?: InputMaybe<Scalars['Date']['input']>;
};

export type AbacusContractWithLifecyclesInput = {
    contract: AbacusContractInput;
    lifecycle: AbacusContractLifecycleInput;
    lifecycleSchedules: Array<AbacusContractLifecycleScheduleInput>;
};

export enum AbacusDownloadType {
    ACCOUNTING_RUN_SUMMARY = 'ACCOUNTING_RUN_SUMMARY',
    ACCOUNT_PAYEE_TAX_DETAILS_RECEIPT = 'ACCOUNT_PAYEE_TAX_DETAILS_RECEIPT',
    ADJUSTMENTS_FILE_ERROR_REPORT = 'ADJUSTMENTS_FILE_ERROR_REPORT',
    ADJUSTMENTS_FILE_SUMMARY_REPORT = 'ADJUSTMENTS_FILE_SUMMARY_REPORT',
    ADJUSTMENTS_TEMPLATE = 'ADJUSTMENTS_TEMPLATE',
    CUSTOM_REPORT = 'CUSTOM_REPORT',
    PAYMENT_GROUP_PAYMENT_APPROVAL = 'PAYMENT_GROUP_PAYMENT_APPROVAL',
    PAYMENT_GROUP_PAYMENT_SUMMARY = 'PAYMENT_GROUP_PAYMENT_SUMMARY',
    STATEMENT_ATTACHMENT = 'STATEMENT_ATTACHMENT',
    VAT_APPLIED_GBR = 'VAT_APPLIED_GBR',
    VAT_EXEMPT = 'VAT_EXEMPT',
}

export type AbacusEventCreate = {
    eventName: Scalars['String']['input'];
    /** If not specified, will default to the current statementPeriod. */
    statementPeriodId?: InputMaybe<Scalars['ID']['input']>;
    targetId: Scalars['Int']['input'];
    targetType: Scalars['String']['input'];
};

export type AbacusExchangeRatesInput = {
    /** Expected to be a base64 encoded string */
    exchangeRates: Scalars['String']['input'];
    statementPeriodId: Scalars['ID']['input'];
};

export type AbacusLedgerAccountContractSearchFilterInput = {
    contractIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    eventNames?: InputMaybe<Array<Scalars['String']['input']>>;
    statementPeriodIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AbacusPaymentGroupCreate = {
    groupCriteria?: InputMaybe<AbacusPaymentGroupCriteriaCreate>;
    groupName: Scalars['String']['input'];
    isReusable?: InputMaybe<Scalars['Boolean']['input']>;
    paymentName?: InputMaybe<Scalars['String']['input']>;
};

export type AbacusPaymentGroupCriteriaCreate = {
    accountId?: InputMaybe<Scalars['ID']['input']>;
    currencyCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    paymentSchedules?: InputMaybe<
        Array<InputMaybe<Scalars['String']['input']>>
    >;
    referencePaymentEntities?: InputMaybe<
        Array<InputMaybe<Scalars['ID']['input']>>
    >;
    signingEntities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type AbacusPaymentGroupPaymentCreate = {
    paymentGroupId: Scalars['ID']['input'];
    paymentName: Scalars['String']['input'];
};

export type AbacusPaymentGroupPaymentUpdate = {
    actionStates: Array<InputMaybe<AbacusStateUpdate>>;
    paymentGroupPaymentId: Scalars['ID']['input'];
};

export type AbacusPaymentHoldInput = {
    accountId: Scalars['ID']['input'];
    isOnHold: Scalars['Boolean']['input'];
    reason: Scalars['String']['input'];
    startDate: Scalars['String']['input'];
};

export type AbacusPaymentMinimumUpdate = {
    checkAmount?: InputMaybe<Scalars['String']['input']>;
    paymentMinimumId: Scalars['ID']['input'];
    westernUnionAmount?: InputMaybe<Scalars['String']['input']>;
    wireTransferAmount?: InputMaybe<Scalars['String']['input']>;
};

export type AbacusRunControllerInput = {
    /** One of 'distribution', 'legacy_distribution', or 'neighbouring_rights'. Defaults to 'distribution'. */
    contractType?: InputMaybe<Scalars['String']['input']>;
    runControllerName: Scalars['String']['input'];
};

export type AbacusSalesFileInput = {
    accountingPeriodId: Scalars['ID']['input'];
    fileName: Scalars['String']['input'];
};

export enum AbacusScheduleAttachmentTargetType {
    CONTRIBUTION = 'CONTRIBUTION',
}

export type AbacusScheduleConditionsInput = {
    autoAdd?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AbacusScheduleTargetType {
    CONTRIBUTOR = 'CONTRIBUTOR',
}

/**
 * Abacus State is used as a state machine to track the actions and statuses of various ABACUS types.
 * It is a polymorphic representation where the parentTableId and parentTableName refer to the same table.
 * For example, a state for an accounting period would have a parentTableName of "accounting_period" and
 * the parentTableId would equal the accountingPeriodId.
 */
export type AbacusStateUpdate = {
    abacusStateId: Scalars['ID']['input'];
    actionStatus: Scalars['String']['input'];
    message?: InputMaybe<Scalars['String']['input']>;
};

export enum AbacusStatementPeriodAdjustmentFileErrorType {
    CONTENT_ERROR = 'CONTENT_ERROR',
    FORMAT_ERROR = 'FORMAT_ERROR',
}

export type AbacusStatementPeriodAdjustmentFileInput = {
    errorType?: InputMaybe<AbacusStatementPeriodAdjustmentFileErrorType>;
    fileName: Scalars['String']['input'];
    invalidFileLocation?: InputMaybe<Scalars['String']['input']>;
    invalidRowCount?: InputMaybe<Scalars['Int']['input']>;
    md5sum?: InputMaybe<Scalars['String']['input']>;
    statementPeriodId: Scalars['ID']['input'];
    totalFileAmountMulticurrency?: InputMaybe<Scalars['Float']['input']>;
    totalRoundedAmountMulticurrency?: InputMaybe<Scalars['Float']['input']>;
    validFileLocation?: InputMaybe<Scalars['String']['input']>;
    validRowCount?: InputMaybe<Scalars['Int']['input']>;
};

export type AbacusStatementPeriodAdjustmentFileSearchFilterInput = {
    createdBy?: InputMaybe<Scalars['String']['input']>;
    fileName?: InputMaybe<Scalars['String']['input']>;
    statementPeriodId?: InputMaybe<Scalars['ID']['input']>;
};

export type AbacusStatementPeriodAdjustmentFileUpdate = {
    errorType?: InputMaybe<AbacusStatementPeriodAdjustmentFileErrorType>;
    fileName?: InputMaybe<Scalars['String']['input']>;
    invalidFileLocation?: InputMaybe<Scalars['String']['input']>;
    invalidRowCount?: InputMaybe<Scalars['Int']['input']>;
    md5sum?: InputMaybe<Scalars['String']['input']>;
    statementPeriodAdjustmentFileId: Scalars['ID']['input'];
    statementPeriodId: Scalars['ID']['input'];
    totalFileAmountMulticurrency?: InputMaybe<Scalars['Float']['input']>;
    totalRoundedAmountMulticurrency?: InputMaybe<Scalars['Float']['input']>;
    validFileLocation?: InputMaybe<Scalars['String']['input']>;
    validRowCount?: InputMaybe<Scalars['Int']['input']>;
};

export type AbacusStatementPeriodInput = {
    statementPeriodId: Scalars['ID']['input'];
};

export enum AbacusUploadType {
    ADJUSTMENTS_FILE = 'ADJUSTMENTS_FILE',
    PAYMENT_GROUP_PAYMENT_APPROVAL = 'PAYMENT_GROUP_PAYMENT_APPROVAL',
}

export type AccountSearchFilter = {
    labelType?: InputMaybe<LabelType>;
    searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export enum AccountSummaryType {
    COUNTRY = 'COUNTRY',
    IMPRINT = 'IMPRINT',
    PARTICIPANT = 'PARTICIPANT',
    PRODUCT = 'PRODUCT',
    PROJECT = 'PROJECT',
    SOS = 'SOS',
    SOUND_RECORDING_FAMILY = 'SOUND_RECORDING_FAMILY',
    STORE = 'STORE',
    SUBACCOUNT = 'SUBACCOUNT',
    SUBSCRIPTION = 'SUBSCRIPTION',
    TOTAL = 'TOTAL',
    TRACK = 'TRACK',
}

export enum AccountTimeseriesType {
    ACCOUNT_ALBUM_DOWNLOADS = 'ACCOUNT_ALBUM_DOWNLOADS',
    ACCOUNT_ALBUM_DOWNLOADS_BY_COUNTRY = 'ACCOUNT_ALBUM_DOWNLOADS_BY_COUNTRY',
    ACCOUNT_ALBUM_DOWNLOADS_BY_IMPRINT = 'ACCOUNT_ALBUM_DOWNLOADS_BY_IMPRINT',
    ACCOUNT_ALBUM_DOWNLOADS_BY_PARTICIPANT = 'ACCOUNT_ALBUM_DOWNLOADS_BY_PARTICIPANT',
    ACCOUNT_ALBUM_DOWNLOADS_BY_PRODUCT = 'ACCOUNT_ALBUM_DOWNLOADS_BY_PRODUCT',
    ACCOUNT_ALBUM_DOWNLOADS_BY_PROJECT = 'ACCOUNT_ALBUM_DOWNLOADS_BY_PROJECT',
    ACCOUNT_ALBUM_DOWNLOADS_BY_STORE = 'ACCOUNT_ALBUM_DOWNLOADS_BY_STORE',
    ACCOUNT_ALBUM_DOWNLOADS_BY_SUBACCOUNT = 'ACCOUNT_ALBUM_DOWNLOADS_BY_SUBACCOUNT',
    ACCOUNT_STREAMS = 'ACCOUNT_STREAMS',
    ACCOUNT_STREAMS_BY_COUNTRY = 'ACCOUNT_STREAMS_BY_COUNTRY',
    ACCOUNT_STREAMS_BY_IMPRINT = 'ACCOUNT_STREAMS_BY_IMPRINT',
    ACCOUNT_STREAMS_BY_PARTICIPANT = 'ACCOUNT_STREAMS_BY_PARTICIPANT',
    ACCOUNT_STREAMS_BY_PRODUCT = 'ACCOUNT_STREAMS_BY_PRODUCT',
    ACCOUNT_STREAMS_BY_PROJECT = 'ACCOUNT_STREAMS_BY_PROJECT',
    ACCOUNT_STREAMS_BY_SOS = 'ACCOUNT_STREAMS_BY_SOS',
    ACCOUNT_STREAMS_BY_SOUND_RECORDING_FAMILY = 'ACCOUNT_STREAMS_BY_SOUND_RECORDING_FAMILY',
    ACCOUNT_STREAMS_BY_STORE = 'ACCOUNT_STREAMS_BY_STORE',
    ACCOUNT_STREAMS_BY_SUBACCOUNT = 'ACCOUNT_STREAMS_BY_SUBACCOUNT',
    ACCOUNT_STREAMS_BY_SUBSCRIPTION = 'ACCOUNT_STREAMS_BY_SUBSCRIPTION',
    ACCOUNT_STREAMS_BY_TRACK = 'ACCOUNT_STREAMS_BY_TRACK',
    ACCOUNT_TRACK_DOWNLOADS = 'ACCOUNT_TRACK_DOWNLOADS',
    ACCOUNT_TRACK_DOWNLOADS_BY_COUNTRY = 'ACCOUNT_TRACK_DOWNLOADS_BY_COUNTRY',
    ACCOUNT_TRACK_DOWNLOADS_BY_IMPRINT = 'ACCOUNT_TRACK_DOWNLOADS_BY_IMPRINT',
    ACCOUNT_TRACK_DOWNLOADS_BY_PARTICIPANT = 'ACCOUNT_TRACK_DOWNLOADS_BY_PARTICIPANT',
    ACCOUNT_TRACK_DOWNLOADS_BY_PRODUCT = 'ACCOUNT_TRACK_DOWNLOADS_BY_PRODUCT',
    ACCOUNT_TRACK_DOWNLOADS_BY_PROJECT = 'ACCOUNT_TRACK_DOWNLOADS_BY_PROJECT',
    ACCOUNT_TRACK_DOWNLOADS_BY_SOUND_RECORDING_FAMILY = 'ACCOUNT_TRACK_DOWNLOADS_BY_SOUND_RECORDING_FAMILY',
    ACCOUNT_TRACK_DOWNLOADS_BY_STORE = 'ACCOUNT_TRACK_DOWNLOADS_BY_STORE',
    ACCOUNT_TRACK_DOWNLOADS_BY_SUBACCOUNT = 'ACCOUNT_TRACK_DOWNLOADS_BY_SUBACCOUNT',
    ACCOUNT_TRACK_DOWNLOADS_BY_TRACK = 'ACCOUNT_TRACK_DOWNLOADS_BY_TRACK',
}

export enum AccountTopContentType {
    DOWNLOADS = 'DOWNLOADS',
    STREAMS = 'STREAMS',
}

export enum AccountVideoViewsQueryType {
    COUNTRY = 'COUNTRY',
    PARTICIPANT = 'PARTICIPANT',
    /** @deprecated Currently not supported. */
    SUBACCOUNT = 'SUBACCOUNT',
    TOTAL = 'TOTAL',
    TRACK = 'TRACK',
    TRACK_FAMILY = 'TRACK_FAMILY',
    VIDEO = 'VIDEO',
}

export enum AccountingPeriodType {
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
}

export type AdAccountLabelPairInput = {
    adAccountId: Scalars['ID']['input'];
    subaccountId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export enum AdReadStatus {
    COMPLETED = 'COMPLETED',
    NEW = 'NEW',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED',
}

export enum AdReportingActionType {
    CLICKS = 'CLICKS',
    EAR = 'EAR',
    FOLLOWS = 'FOLLOWS',
    ORDERS_PLACED = 'ORDERS_PLACED',
    PURCHASES = 'PURCHASES',
    REACH = 'REACH',
    VIEWS = 'VIEWS',
}

export enum AdReportingAdEffectiveStatus {
    GOOGLE_ACTIVE = 'GOOGLE_ACTIVE',
    GOOGLE_DELETED = 'GOOGLE_DELETED',
    GOOGLE_PAUSED = 'GOOGLE_PAUSED',
    META_ACTIVE = 'META_ACTIVE',
    META_ADSET_PAUSED = 'META_ADSET_PAUSED',
    META_ARCHIVED = 'META_ARCHIVED',
    META_CAMPAIGN_PAUSED = 'META_CAMPAIGN_PAUSED',
    META_DELETED = 'META_DELETED',
    META_DISAPPROVED = 'META_DISAPPROVED',
    META_IN_PROCESS = 'META_IN_PROCESS',
    META_PAUSED = 'META_PAUSED',
    META_PENDING_BILLING_INFO = 'META_PENDING_BILLING_INFO',
    META_PENDING_REVIEW = 'META_PENDING_REVIEW',
    META_PREAPPROVED = 'META_PREAPPROVED',
    META_WITH_ISSUES = 'META_WITH_ISSUES',
    TIKTOK_ADGROUP_STATUS_FROZEN = 'TIKTOK_ADGROUP_STATUS_FROZEN',
    TIKTOK_ADVERTISER_ACCOUNT_PUNISH = 'TIKTOK_ADVERTISER_ACCOUNT_PUNISH',
    TIKTOK_ADVERTISER_CONTRACT_PENDING = 'TIKTOK_ADVERTISER_CONTRACT_PENDING',
    TIKTOK_AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST = 'TIKTOK_AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST',
    TIKTOK_AD_STATUS_ADGROUP_AUDIT_DENY = 'TIKTOK_AD_STATUS_ADGROUP_AUDIT_DENY',
    TIKTOK_AD_STATUS_ADGROUP_DELETE = 'TIKTOK_AD_STATUS_ADGROUP_DELETE',
    TIKTOK_AD_STATUS_ADGROUP_DISABLE = 'TIKTOK_AD_STATUS_ADGROUP_DISABLE',
    TIKTOK_AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY = 'TIKTOK_AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY',
    TIKTOK_AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED = 'TIKTOK_AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED',
    TIKTOK_AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING = 'TIKTOK_AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING',
    TIKTOK_AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY = 'TIKTOK_AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY',
    TIKTOK_AD_STATUS_ADVERTISER_AUDIT = 'TIKTOK_AD_STATUS_ADVERTISER_AUDIT',
    TIKTOK_AD_STATUS_ADVERTISER_AUDIT_DENY = 'TIKTOK_AD_STATUS_ADVERTISER_AUDIT_DENY',
    TIKTOK_AD_STATUS_AD_PRE_ONLINE = 'TIKTOK_AD_STATUS_AD_PRE_ONLINE',
    TIKTOK_AD_STATUS_AD_QUOTA_LIMIT = 'TIKTOK_AD_STATUS_AD_QUOTA_LIMIT',
    TIKTOK_AD_STATUS_ANCHOR_UNAVAILABLE = 'TIKTOK_AD_STATUS_ANCHOR_UNAVAILABLE',
    TIKTOK_AD_STATUS_ASSET_AUTHORIZATION_LOST = 'TIKTOK_AD_STATUS_ASSET_AUTHORIZATION_LOST',
    TIKTOK_AD_STATUS_AUDIT = 'TIKTOK_AD_STATUS_AUDIT',
    TIKTOK_AD_STATUS_AUDIT_DENY = 'TIKTOK_AD_STATUS_AUDIT_DENY',
    TIKTOK_AD_STATUS_BALANCE_EXCEED = 'TIKTOK_AD_STATUS_BALANCE_EXCEED',
    TIKTOK_AD_STATUS_BUDGET_EXCEED = 'TIKTOK_AD_STATUS_BUDGET_EXCEED',
    TIKTOK_AD_STATUS_CAMPAIGN_DELETE = 'TIKTOK_AD_STATUS_CAMPAIGN_DELETE',
    TIKTOK_AD_STATUS_CAMPAIGN_DISABLE = 'TIKTOK_AD_STATUS_CAMPAIGN_DISABLE',
    TIKTOK_AD_STATUS_CAMPAIGN_EXCEED = 'TIKTOK_AD_STATUS_CAMPAIGN_EXCEED',
    TIKTOK_AD_STATUS_DELETE = 'TIKTOK_AD_STATUS_DELETE',
    TIKTOK_AD_STATUS_DELIVERY_OK = 'TIKTOK_AD_STATUS_DELIVERY_OK',
    TIKTOK_AD_STATUS_DISABLE = 'TIKTOK_AD_STATUS_DISABLE',
    TIKTOK_AD_STATUS_DONE = 'TIKTOK_AD_STATUS_DONE',
    TIKTOK_AD_STATUS_LIVE_OFFLINE = 'TIKTOK_AD_STATUS_LIVE_OFFLINE',
    TIKTOK_AD_STATUS_MUSIC_AUTHORIZATION_MISSING = 'TIKTOK_AD_STATUS_MUSIC_AUTHORIZATION_MISSING',
    TIKTOK_AD_STATUS_NOT_START = 'TIKTOK_AD_STATUS_NOT_START',
    TIKTOK_AD_STATUS_NO_AUTHORIZATION_OF_SHOWCASE = 'TIKTOK_AD_STATUS_NO_AUTHORIZATION_OF_SHOWCASE',
    TIKTOK_AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK = 'TIKTOK_AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK',
    TIKTOK_AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY = 'TIKTOK_AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY',
    TIKTOK_AD_STATUS_PROCESS_AUDIO = 'TIKTOK_AD_STATUS_PROCESS_AUDIO',
    TIKTOK_AD_STATUS_PRODUCT_UNAVAILABLE = 'TIKTOK_AD_STATUS_PRODUCT_UNAVAILABLE',
    TIKTOK_AD_STATUS_PROMOTE_ADGROUP_CLOSED = 'TIKTOK_AD_STATUS_PROMOTE_ADGROUP_CLOSED',
    TIKTOK_AD_STATUS_PROMOTE_AD_OFFLINE_AUDIT = 'TIKTOK_AD_STATUS_PROMOTE_AD_OFFLINE_AUDIT',
    TIKTOK_AD_STATUS_REAUDIT = 'TIKTOK_AD_STATUS_REAUDIT',
    TIKTOK_AD_STATUS_RF_ADGROUP_CLOSED = 'TIKTOK_AD_STATUS_RF_ADGROUP_CLOSED',
    TIKTOK_AD_STATUS_TRANSCODING_FAIL = 'TIKTOK_AD_STATUS_TRANSCODING_FAIL',
    TIKTOK_AD_STAUS_PIXEL_UNBIND = 'TIKTOK_AD_STAUS_PIXEL_UNBIND',
}

export enum AdReportingAdSetEffectiveStatus {
    GOOGLE_ACTIVE = 'GOOGLE_ACTIVE',
    GOOGLE_DELETED = 'GOOGLE_DELETED',
    GOOGLE_PAUSED = 'GOOGLE_PAUSED',
    META_ACTIVE = 'META_ACTIVE',
    META_ARCHIVED = 'META_ARCHIVED',
    META_CAMPAIGN_PAUSED = 'META_CAMPAIGN_PAUSED',
    META_DELETED = 'META_DELETED',
    META_IN_PROCESS = 'META_IN_PROCESS',
    META_PAUSED = 'META_PAUSED',
    META_WITH_ISSUES = 'META_WITH_ISSUES',
    TIKTOK_ADGROUP_STATUS_ADGROUP_PRE_ONLINE = 'TIKTOK_ADGROUP_STATUS_ADGROUP_PRE_ONLINE',
    TIKTOK_ADGROUP_STATUS_ADGROUP_QUOTA_LIMIT = 'TIKTOK_ADGROUP_STATUS_ADGROUP_QUOTA_LIMIT',
    TIKTOK_ADGROUP_STATUS_ADVERTISER_AUDIT = 'TIKTOK_ADGROUP_STATUS_ADVERTISER_AUDIT',
    TIKTOK_ADGROUP_STATUS_ADVERTISER_AUDIT_DENY = 'TIKTOK_ADGROUP_STATUS_ADVERTISER_AUDIT_DENY',
    TIKTOK_ADGROUP_STATUS_ASSET_AUTHORIZATION_LOST = 'TIKTOK_ADGROUP_STATUS_ASSET_AUTHORIZATION_LOST',
    TIKTOK_ADGROUP_STATUS_AUDIT = 'TIKTOK_ADGROUP_STATUS_AUDIT',
    TIKTOK_ADGROUP_STATUS_AUDIT_DENY = 'TIKTOK_ADGROUP_STATUS_AUDIT_DENY',
    TIKTOK_ADGROUP_STATUS_BALANCE_EXCEED = 'TIKTOK_ADGROUP_STATUS_BALANCE_EXCEED',
    TIKTOK_ADGROUP_STATUS_BUDGET_EXCEED = 'TIKTOK_ADGROUP_STATUS_BUDGET_EXCEED',
    TIKTOK_ADGROUP_STATUS_CAMPAIGN_DELETE = 'TIKTOK_ADGROUP_STATUS_CAMPAIGN_DELETE',
    TIKTOK_ADGROUP_STATUS_CAMPAIGN_DISABLE = 'TIKTOK_ADGROUP_STATUS_CAMPAIGN_DISABLE',
    TIKTOK_ADGROUP_STATUS_CAMPAIGN_EXCEED = 'TIKTOK_ADGROUP_STATUS_CAMPAIGN_EXCEED',
    TIKTOK_ADGROUP_STATUS_CREATE = 'TIKTOK_ADGROUP_STATUS_CREATE',
    TIKTOK_ADGROUP_STATUS_DELETE = 'TIKTOK_ADGROUP_STATUS_DELETE',
    TIKTOK_ADGROUP_STATUS_DELIVERY_OK = 'TIKTOK_ADGROUP_STATUS_DELIVERY_OK',
    TIKTOK_ADGROUP_STATUS_DISABLE = 'TIKTOK_ADGROUP_STATUS_DISABLE',
    TIKTOK_ADGROUP_STATUS_FROZEN = 'TIKTOK_ADGROUP_STATUS_FROZEN',
    TIKTOK_ADGROUP_STATUS_IDENTITY_USED_BY_GMV_MAX_AD = 'TIKTOK_ADGROUP_STATUS_IDENTITY_USED_BY_GMV_MAX_AD',
    TIKTOK_ADGROUP_STATUS_INDUSTRY_QUALIFICATION_DENY = 'TIKTOK_ADGROUP_STATUS_INDUSTRY_QUALIFICATION_DENY',
    TIKTOK_ADGROUP_STATUS_INDUSTRY_QUALIFICATION_EXPIRED = 'TIKTOK_ADGROUP_STATUS_INDUSTRY_QUALIFICATION_EXPIRED',
    TIKTOK_ADGROUP_STATUS_INDUSTRY_QUALIFICATION_MISSING = 'TIKTOK_ADGROUP_STATUS_INDUSTRY_QUALIFICATION_MISSING',
    TIKTOK_ADGROUP_STATUS_LIVE_NOT_START = 'TIKTOK_ADGROUP_STATUS_LIVE_NOT_START',
    TIKTOK_ADGROUP_STATUS_NOT_START = 'TIKTOK_ADGROUP_STATUS_NOT_START',
    TIKTOK_ADGROUP_STATUS_PARTIAL_AUDIT_DELIVERY_OK = 'TIKTOK_ADGROUP_STATUS_PARTIAL_AUDIT_DELIVERY_OK',
    TIKTOK_ADGROUP_STATUS_PARTIAL_AUDIT_NO_DELIVERY = 'TIKTOK_ADGROUP_STATUS_PARTIAL_AUDIT_NO_DELIVERY',
    TIKTOK_ADGROUP_STATUS_PIXEL_UNBIND = 'TIKTOK_ADGROUP_STATUS_PIXEL_UNBIND',
    TIKTOK_ADGROUP_STATUS_PROMOTE_AD_NOT_APPROVED = 'TIKTOK_ADGROUP_STATUS_PROMOTE_AD_NOT_APPROVED',
    TIKTOK_ADGROUP_STATUS_PROMOTE_WITHDRAW_ORDER = 'TIKTOK_ADGROUP_STATUS_PROMOTE_WITHDRAW_ORDER',
    TIKTOK_ADGROUP_STATUS_REAUDIT = 'TIKTOK_ADGROUP_STATUS_REAUDIT',
    TIKTOK_ADGROUP_STATUS_REVIEW_PARTIALLY_APPROVED = 'TIKTOK_ADGROUP_STATUS_REVIEW_PARTIALLY_APPROVED',
    TIKTOK_ADGROUP_STATUS_RF_ADGROUP_INVALID = 'TIKTOK_ADGROUP_STATUS_RF_ADGROUP_INVALID',
    TIKTOK_ADGROUP_STATUS_RF_AD_AUDIT_DENY = 'TIKTOK_ADGROUP_STATUS_RF_AD_AUDIT_DENY',
    TIKTOK_ADGROUP_STATUS_RF_BOOKING = 'TIKTOK_ADGROUP_STATUS_RF_BOOKING',
    TIKTOK_ADGROUP_STATUS_RF_CLOSED_OTHERS = 'TIKTOK_ADGROUP_STATUS_RF_CLOSED_OTHERS',
    TIKTOK_ADGROUP_STATUS_RF_DEDUCTION_FAILED = 'TIKTOK_ADGROUP_STATUS_RF_DEDUCTION_FAILED',
    TIKTOK_ADGROUP_STATUS_RF_NO_DELIVERY_CREATIVE = 'TIKTOK_ADGROUP_STATUS_RF_NO_DELIVERY_CREATIVE',
    TIKTOK_ADGROUP_STATUS_RF_NO_VALID_CREATIVE = 'TIKTOK_ADGROUP_STATUS_RF_NO_VALID_CREATIVE',
    TIKTOK_ADGROUP_STATUS_RF_SCHEDULE = 'TIKTOK_ADGROUP_STATUS_RF_SCHEDULE',
    TIKTOK_ADGROUP_STATUS_RF_SHORT_BALANCE = 'TIKTOK_ADGROUP_STATUS_RF_SHORT_BALANCE',
    TIKTOK_ADGROUP_STATUS_RF_TERMINATE = 'TIKTOK_ADGROUP_STATUS_RF_TERMINATE',
    TIKTOK_ADGROUP_STATUS_RF_TIME_DONE = 'TIKTOK_ADGROUP_STATUS_RF_TIME_DONE',
    TIKTOK_ADGROUP_STATUS_RF_WITHDRAW_ORDER = 'TIKTOK_ADGROUP_STATUS_RF_WITHDRAW_ORDER',
    TIKTOK_ADGROUP_STATUS_SEARCH_KEYWORDS_IN_REVIEW = 'TIKTOK_ADGROUP_STATUS_SEARCH_KEYWORDS_IN_REVIEW',
    TIKTOK_ADGROUP_STATUS_SEARCH_KEYWORDS_NOT_AVAILABLE = 'TIKTOK_ADGROUP_STATUS_SEARCH_KEYWORDS_NOT_AVAILABLE',
    TIKTOK_ADGROUP_STATUS_SEARCH_KEYWORDS_PARTIAL_APPROVED = 'TIKTOK_ADGROUP_STATUS_SEARCH_KEYWORDS_PARTIAL_APPROVED',
    TIKTOK_ADGROUP_STATUS_SHADOW_ADGROUP_REAUDIT = 'TIKTOK_ADGROUP_STATUS_SHADOW_ADGROUP_REAUDIT',
    TIKTOK_ADGROUP_STATUS_TIME_DONE = 'TIKTOK_ADGROUP_STATUS_TIME_DONE',
    TIKTOK_ADGROUP_STATUS_TRANSCODING_FAIL = 'TIKTOK_ADGROUP_STATUS_TRANSCODING_FAIL',
    TIKTOK_ADVERTISER_ACCOUNT_INVALID = 'TIKTOK_ADVERTISER_ACCOUNT_INVALID',
    TIKTOK_ADVERTISER_ACCOUNT_PUNISH = 'TIKTOK_ADVERTISER_ACCOUNT_PUNISH',
    TIKTOK_ADVERTISER_CONTRACT_PENDING = 'TIKTOK_ADVERTISER_CONTRACT_PENDING',
}

export enum AdReportingBudgetType {
    AD_SET_BUDGET = 'AD_SET_BUDGET',
    CAMPAIGN_BUDGET = 'CAMPAIGN_BUDGET',
    DAILY_BUDGET = 'DAILY_BUDGET',
    LIFETIME_BUDGET = 'LIFETIME_BUDGET',
    UNKNOWN = 'UNKNOWN',
}

export enum AdReportingCampaignEffectiveStatus {
    GOOGLE_ACTIVE = 'GOOGLE_ACTIVE',
    GOOGLE_DELETED = 'GOOGLE_DELETED',
    GOOGLE_PAUSED = 'GOOGLE_PAUSED',
    META_ACTIVE = 'META_ACTIVE',
    META_ARCHIVED = 'META_ARCHIVED',
    META_DELETED = 'META_DELETED',
    META_IN_PROCESS = 'META_IN_PROCESS',
    META_PAUSED = 'META_PAUSED',
    META_WITH_ISSUES = 'META_WITH_ISSUES',
    TIKTOK_ADVERTISER_ACCOUNT_PUNISH = 'TIKTOK_ADVERTISER_ACCOUNT_PUNISH',
    TIKTOK_ADVERTISER_CONTRACT_PENDING = 'TIKTOK_ADVERTISER_CONTRACT_PENDING',
    TIKTOK_CAMPAIGN_STATUS_ADVERTISER_AUDIT = 'TIKTOK_CAMPAIGN_STATUS_ADVERTISER_AUDIT',
    TIKTOK_CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY = 'TIKTOK_CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY',
    TIKTOK_CAMPAIGN_STATUS_AWAITING_RELEASE = 'TIKTOK_CAMPAIGN_STATUS_AWAITING_RELEASE',
    TIKTOK_CAMPAIGN_STATUS_BUDGET_EXCEED = 'TIKTOK_CAMPAIGN_STATUS_BUDGET_EXCEED',
    TIKTOK_CAMPAIGN_STATUS_DELETE = 'TIKTOK_CAMPAIGN_STATUS_DELETE',
    TIKTOK_CAMPAIGN_STATUS_DISABLE = 'TIKTOK_CAMPAIGN_STATUS_DISABLE',
    TIKTOK_CAMPAIGN_STATUS_ENABLE = 'TIKTOK_CAMPAIGN_STATUS_ENABLE',
    TIKTOK_CAMPAIGN_STATUS_IDENTITY_USED_BY_GMV_MAX_AD = 'TIKTOK_CAMPAIGN_STATUS_IDENTITY_USED_BY_GMV_MAX_AD',
}

export enum AdReportingCreativeType {
    IMAGE = 'IMAGE',
    IMAGE_THUMBNAIL = 'IMAGE_THUMBNAIL',
    INSTAGRAM_POST = 'INSTAGRAM_POST',
    LINK = 'LINK',
    TIKTOK_POST = 'TIKTOK_POST',
    VIDEO = 'VIDEO',
    YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
}

export enum AdReportingObjective {
    APP_PROMOTION = 'APP_PROMOTION',
    DEMAND_GEN = 'DEMAND_GEN',
    DISPLAY = 'DISPLAY',
    ENGAGEMENT = 'ENGAGEMENT',
    LEAD_GENERATION = 'LEAD_GENERATION',
    OUTCOME_APP_PROMOTION = 'OUTCOME_APP_PROMOTION',
    OUTCOME_AWARENESS = 'OUTCOME_AWARENESS',
    OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT',
    OUTCOME_LEADS = 'OUTCOME_LEADS',
    OUTCOME_SALES = 'OUTCOME_SALES',
    OUTCOME_TRAFFIC = 'OUTCOME_TRAFFIC',
    PRODUCT_SALES = 'PRODUCT_SALES',
    REACH = 'REACH',
    SEARCH = 'SEARCH',
    TRAFFIC = 'TRAFFIC',
    VIDEO = 'VIDEO',
    VIDEO_VIEWS = 'VIDEO_VIEWS',
    WEB_CONVERSIONS = 'WEB_CONVERSIONS',
}

export enum AdReportingPlatform {
    GOOGLE = 'GOOGLE',
    META = 'META',
    TIKTOK = 'TIKTOK',
}

export enum AdReportingRateType {
    CR = 'CR',
    CTR = 'CTR',
    EARR = 'EARR',
    FR = 'FR',
    FREQUENCY = 'FREQUENCY',
    VTR = 'VTR',
}

export type AddReportsToRoyaltiesInput = {
    reportIds: Array<Scalars['Int']['input']>;
};

export enum AllProductSearchQueryType {
    EXACT = 'EXACT',
}

export enum AppConnectionStatus {
    CONNECTED = 'CONNECTED',
    DELETED = 'DELETED',
    ERROR = 'ERROR',
    NOT_CONNECTED = 'NOT_CONNECTED',
    PENDING = 'PENDING',
    SYNCING = 'SYNCING',
    SYNCING_INITIAL = 'SYNCING_INITIAL',
    WAITING_FOR_PROCESSING = 'WAITING_FOR_PROCESSING',
}

export enum ApplicationType {
    ABACUS_APP = 'ABACUS_APP',
    ACCOUNT360_APP = 'ACCOUNT360_APP',
    AUDIENCE_DEVELOPMENT_APP = 'AUDIENCE_DEVELOPMENT_APP',
    COLLABORATORS_APP = 'COLLABORATORS_APP',
    CONTENT_APP = 'CONTENT_APP',
    DISTRIBUTION_SUITE_APP = 'DISTRIBUTION_SUITE_APP',
    DOCUMENTS_APP = 'DOCUMENTS_APP',
    HELP_CENTER_APP = 'HELP_CENTER_APP',
    INSIGHTS_APP = 'INSIGHTS_APP',
    MONEYHUB_APP = 'MONEYHUB_APP',
    ORCHADMIN_APP = 'ORCHADMIN_APP',
    ORCHARDGO_APP = 'ORCHARDGO_APP',
    PODCASTS_APP = 'PODCASTS_APP',
    PUBLISHING_APP = 'PUBLISHING_APP',
    SETTINGS_APP = 'SETTINGS_APP',
    SONGWHIP_APP = 'SONGWHIP_APP',
    WORKSTATION_APP = 'WORKSTATION_APP',
}

export type ApproveProductInput = {
    productId: Scalars['Int']['input'];
};

export type ApproveVideoSingleProductInput = {
    approvalType: VideoSingleProductApprovalType;
    bypassValidation?: InputMaybe<Scalars['Boolean']['input']>;
    productId: Scalars['Int']['input'];
    rejectionReason?: InputMaybe<Scalars['String']['input']>;
};

export enum ArtistDataType {
    ACQUISITION_CHANNEL = 'ACQUISITION_CHANNEL',
    ACQUISITION_COUNTRY = 'ACQUISITION_COUNTRY',
    FAN_SEGMENT = 'FAN_SEGMENT',
    HEAVY_ROTATION = 'HEAVY_ROTATION',
}

export type AssetInfoInput = {
    assetType: Scalars['String']['input'];
    filename: Scalars['String']['input'];
    isCorrection?: InputMaybe<Scalars['Boolean']['input']>;
    originalFilename?: InputMaybe<Scalars['String']['input']>;
    productId: Scalars['Int']['input'];
    token: Scalars['String']['input'];
    trackUniqueId: Scalars['Int']['input'];
    upc: Scalars['String']['input'];
};

/** AssetUploadAssetType for ows-assets' V2 asset approach. */
export enum AssetsV2AssetUploadAssetType {
    FLAC = 'FLAC',
    IMAGE = 'IMAGE',
    JPEG = 'JPEG',
    JPG = 'JPG',
    M4A = 'M4A',
    MP3 = 'MP3',
    PDF = 'PDF',
    TIF = 'TIF',
    TIFF = 'TIFF',
    TXT = 'TXT',
    WAV = 'WAV',
    WAVE = 'WAVE',
}

export type AssociatePublishingCompositionInput = {
    gsrs: Array<Scalars['String']['input']>;
    pubSongId: Scalars['Int']['input'];
};

export type AudienceDspFilterInput = {
    operator: AudienceFilterOperator;
    value: Array<AudienceDigitalServiceProvider>;
};

export enum AudienceDataFansStatsAgeRange {
    FROM_13_TO_17 = 'FROM_13_TO_17',
    FROM_18_TO_22 = 'FROM_18_TO_22',
    FROM_23_TO_30 = 'FROM_23_TO_30',
    FROM_31_TO_40 = 'FROM_31_TO_40',
    FROM_41_TO_51 = 'FROM_41_TO_51',
    FROM_52_TO_60 = 'FROM_52_TO_60',
    FROM_61 = 'FROM_61',
}

export enum AudienceDataGender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    NON_BINARY_OTHER = 'NON_BINARY_OTHER',
    PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
}

export enum AudienceDataHeavyRotation {
    IN = 'IN',
    NOT_IN = 'NOT_IN',
    UNKNOWN = 'UNKNOWN',
}

export enum AudienceDigitalServiceProvider {
    AMAZON = 'AMAZON',
    APPLE = 'APPLE',
    DEEZER = 'DEEZER',
    SPOTIFY = 'SPOTIFY',
    YOUTUBE = 'YOUTUBE',
}

export enum AudienceEmailCampaignAction {
    CLICKED = 'CLICKED',
    DELIVERED = 'DELIVERED',
    OPENED = 'OPENED',
}

export enum AudienceExportJustification {
    ARTIST_WEBSITE_USE = 'ARTIST_WEBSITE_USE',
    CONTEST_WINNER_SELECTION = 'CONTEST_WINNER_SELECTION',
    DROPPED_ARTIST = 'DROPPED_ARTIST',
    EVENT_RSVP_LIST = 'EVENT_RSVP_LIST',
    LABEL_ARTIST_GIFT_TO_FANS = 'LABEL_ARTIST_GIFT_TO_FANS',
    OTHER = 'OTHER',
    PARTNER_COLLABORATION = 'PARTNER_COLLABORATION',
    RETARGETING = 'RETARGETING',
    SEND_TO_ARTIST_MANAGEMENT = 'SEND_TO_ARTIST_MANAGEMENT',
}

export enum AudienceExportReason {
    ADS_GOOGLE = 'ADS_GOOGLE',
    ADS_META = 'ADS_META',
    ADS_SNAPCHAT = 'ADS_SNAPCHAT',
    ADS_SPOTIFY = 'ADS_SPOTIFY',
    ADS_TIKTOK = 'ADS_TIKTOK',
    EMAIL_SALESFORCE = 'EMAIL_SALESFORCE',
    TEXT_COMMUNITY = 'TEXT_COMMUNITY',
    TEXT_LAYOUT = 'TEXT_LAYOUT',
    TEXT_SUBTEXT = 'TEXT_SUBTEXT',
}

export enum AudienceExportStatus {
    COMPLETED = 'COMPLETED',
    DELETED = 'DELETED',
    FAILED = 'FAILED',
    PENDING = 'PENDING',
    PROCESSING = 'PROCESSING',
}

export enum AudienceFanHeavyRotation {
    IN = 'IN',
    NOT_IN = 'NOT_IN',
}

export enum AudienceFanSegment {
    CASUAL_FANS = 'CASUAL_FANS',
    ENGAGED_FANS = 'ENGAGED_FANS',
    FANS_TO_WIN_BACK = 'FANS_TO_WIN_BACK',
    NEW_FANS = 'NEW_FANS',
    SECONDARY_FANS = 'SECONDARY_FANS',
    SUPER_FANS = 'SUPER_FANS',
    UNSEGMENTED_FANS = 'UNSEGMENTED_FANS',
}

export type AudienceFanSegmentFilterInput = {
    operator: AudienceFilterOperator;
    value: Array<AudienceFanSegment>;
};

export enum AudienceFilterOperator {
    EXCLUDE = 'EXCLUDE',
    INCLUDE = 'INCLUDE',
}

export type AudienceFiltersInput = {
    cities?: InputMaybe<Array<FsAudienceCityFilterInput>>;
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    dsp?: InputMaybe<AudienceDspFilterInput>;
    emailCampaigns?: InputMaybe<FsAudienceEmailCampaignFilterInput>;
    fanSegments?: InputMaybe<AudienceFanSegmentFilterInput>;
    heavyRotationV2?: InputMaybe<AudienceHeavyRotationFilterInput>;
    lastEngagement?: InputMaybe<AudienceLastEngagementFilterInput>;
    radius?: InputMaybe<Scalars['Float']['input']>;
    radiusUnit?: InputMaybe<AudienceFiltersRadiusUnit>;
    songwhipPresavePageIds?: InputMaybe<FsSongwhipPresavePageInFilterInput>;
    spendMax?: InputMaybe<Scalars['Int']['input']>;
    spendMin?: InputMaybe<Scalars['Int']['input']>;
};

export enum AudienceFiltersRadiusUnit {
    KM = 'KM',
    MI = 'MI',
}

export type AudienceHeavyRotationFilterInput = {
    operator: AudienceFilterOperator;
    value: Array<AudienceFanHeavyRotation>;
};

export enum AudienceLastEngagement {
    PAST_2_WEEKS = 'PAST_2_WEEKS',
    PAST_6_MONTHS = 'PAST_6_MONTHS',
    PAST_28_DAYS = 'PAST_28_DAYS',
    PAST_WEEK = 'PAST_WEEK',
    PAST_YEAR = 'PAST_YEAR',
}

export type AudienceLastEngagementFilterInput = {
    operator: AudienceFilterOperator;
    value: AudienceLastEngagement;
};

export enum AudienceSharePlatform {
    GOOGLE = 'GOOGLE',
    META = 'META',
    SENDGRID = 'SENDGRID',
    TIKTOK = 'TIKTOK',
}

export enum AudienceTarget {
    ADS = 'ADS',
    EMAIL = 'EMAIL',
    TEXT = 'TEXT',
}

export enum BundleType {
    AUDIO_VIDEO = 'AUDIO_VIDEO',
    VIDEO = 'VIDEO',
}

export enum CacheControlScope {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
}

export type CancelTransferWiseBatchPaymentInput = {
    batchId: Scalars['Int']['input'];
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
};

export enum CatalogProductsContextType {
    all = 'all',
    digital = 'digital',
    physical = 'physical',
}

export enum CatalogProductsOrdering {
    releaseDate = 'releaseDate',
}

export enum ChangeType {
    BACKFILL = 'BACKFILL',
    CREATE = 'CREATE',
    DELETE = 'DELETE',
    DELIVERY = 'DELIVERY',
    UPDATE = 'UPDATE',
}

export enum ChangelogRecordsOrderByField {
    ID = 'ID',
}

export enum ChangelogReportOrderByField {
    CREATED_AT = 'CREATED_AT',
}

export type ChartFilterInput = {
    country?: InputMaybe<Scalars['String']['input']>;
    frequency?: InputMaybe<Scalars['String']['input']>;
    genre?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    platform?: InputMaybe<Scalars['String']['input']>;
    target?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<Scalars['String']['input']>;
};

export enum ChartSoundRecordingsItemsFilter {
    ALL = 'ALL',
    ENTRY = 'ENTRY',
    MAJOR_MOVE = 'MAJOR_MOVE',
}

/**
 * Filter for the placments on a chart on a particular date.
 *
 * If multiple conditions are specified, they are logically AND-ed.
 */
export type ChartV2DatePlacementFilterInput = {
    /**
     * Filters based on the brand UUID of the placements.
     *
     * This filter can only be called by a Profile with the `fullCatalogAccess` flag.
     *
     * A placement is returned if any of the provided UUIDs appear in the
     * placement's brand list.
     *
     * A special sentinel value `👾` matches placements that have no brand
     * association, i.e. they are not part of the catalog. This value can be combined
     * with other uuids freely to create a mixture of no-in-catalog and specific brands.
     */
    brandUuidIn?: InputMaybe<Array<Scalars['ID']['input']>>;
    /**
     * Filters based on the user's catalog.
     *
     * If the value is `true`, only items in the user's catalog are returned.
     * If the value is `false`, only items not in the user's catalog are returned.
     */
    isPartOfCatalog?: InputMaybe<Scalars['Boolean']['input']>;
    /**
     * Filter on the position change of the placement compared to the previous
     * entry for the chart.
     */
    positionChange?: InputMaybe<NullableIntFilterInput>;
    /**
     * Filter on the absolute value of the position change of the placement compared
     * to the previous entry for the chart.
     */
    positionChangeAbs?: InputMaybe<NullableIntFilterInput>;
};

export enum ChartV2DatePlacementOrderBy {
    POSITION = 'POSITION',
    POSITION_CHANGE = 'POSITION_CHANGE',
}

export enum ChartV2DatePlacementOrderDir {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type ChartV2DatePlacementOrderInput = {
    by: ChartV2DatePlacementOrderBy;
    dir: ChartV2DatePlacementOrderDir;
};

export type CloseStatementPeriodInput = {
    name: Scalars['String']['input'];
    vendorId: Scalars['Int']['input'];
};

export type CollaboratorInput = {
    collaboratorType?: InputMaybe<CollaboratorType>;
    currency?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    internalId?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    participantId?: InputMaybe<Scalars['ID']['input']>;
    performanceRights?: InputMaybe<Scalars['Boolean']['input']>;
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export enum CollaboratorSplitRateType {
    GROSS = 'GROSS',
    NET = 'NET',
}

export enum CollaboratorTransactionType {
    CREDIT = 'CREDIT',
    EXPENSE = 'EXPENSE',
    PAYMENT = 'PAYMENT',
    REVENUE = 'REVENUE',
    VOID = 'VOID',
}

export enum CollaboratorType {
    COLLABORATOR = 'COLLABORATOR',
    SUBACCOUNT = 'SUBACCOUNT',
}

export type CollaboratorsAgreeTermsAndConditionsInput = {
    termsAndConditionsId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export enum CollaboratorsApplicationType {
    ORCHARD_SUITE = 'ORCHARD_SUITE',
    ORCHARD_WS = 'ORCHARD_WS',
}

export enum CollaboratorsPaymentStatus {
    BOUNCED_BACK = 'BOUNCED_BACK',
    CANCELLED = 'CANCELLED',
    CHARGED_BACK = 'CHARGED_BACK',
    FUNDS_CONVERTED = 'FUNDS_CONVERTED',
    FUNDS_REFUNDED = 'FUNDS_REFUNDED',
    INCOMING_PAYMENT_WAITING = 'INCOMING_PAYMENT_WAITING',
    OUTGOING_PAYMENT_SENT = 'OUTGOING_PAYMENT_SENT',
    PROCESSING = 'PROCESSING',
    UNKNOWN = 'UNKNOWN',
    WAITING_RECIPIENT_INPUT_TO_PROCEED = 'WAITING_RECIPIENT_INPUT_TO_PROCEED',
}

export enum CollaboratorsStatementPeriodStatus {
    CLOSED = 'CLOSED',
    OPEN = 'OPEN',
}

export type CollaboratorsVendorAgreementInput = {
    vendorId: Scalars['Int']['input'];
};

export type CollectionArtistAssociation = {
    collectionId: Scalars['Float']['input'];
    globalParticipantId: Scalars['String']['input'];
};

export enum CompanyBrandName {
    AWAL = 'AWAL',
    THEORCHARD = 'THEORCHARD',
}

export type CompanyBrandsFilterInput = {
    brandUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
    parentCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CompleteBulkMetadataUploadInput = {
    parts: Array<UploadPartInput>;
    s3Filename: Scalars['String']['input'];
};

export type ConnectGoogleAdReportingInput = {
    redirectUri: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
};

export type ConnectGoogleUserInput = {
    authCode: Scalars['String']['input'];
    codeVerifier: Scalars['String']['input'];
    redirectUri: Scalars['String']['input'];
};

export type ConnectMetaAdReportingInput = {
    redirectUri: Scalars['String']['input'];
};

export type ConnectMetaUserInput = {
    accessToken: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type ConnectTiktokAdReportingInput = {
    redirectUri: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
};

export enum ContractAdvanceStatus {
    IN_REVIEW = 'IN_REVIEW',
    PAID = 'PAID',
    PENDING = 'PENDING',
    QUALIFIED = 'QUALIFIED',
}

export enum ContractType {
    ABACUS = 'ABACUS',
    LEGACY = 'LEGACY',
}

export type CorrectionDetailInput = {
    fieldName: Scalars['String']['input'];
    keyId?: InputMaybe<Scalars['Int']['input']>;
    keyValue: Scalars['String']['input'];
    tableName: Scalars['String']['input'];
};

export type CreateAbacusScheduleInput = {
    conditions?: InputMaybe<AbacusScheduleConditionsInput>;
    scheduleName: Scalars['String']['input'];
    targetId: Scalars['String']['input'];
    targetType: AbacusScheduleTargetType;
};

export type CreateAdReadCommentInput = {
    adReadId: Scalars['Int']['input'];
    comment: Scalars['String']['input'];
};

export type CreateAdReadInput = {
    advertisementId: Scalars['String']['input'];
    assigneeIds: Array<Scalars['Int']['input']>;
    campaignId: Scalars['String']['input'];
    copyFilename?: InputMaybe<Scalars['String']['input']>;
    copyUrlPath: Scalars['String']['input'];
    dueDate: Scalars['DateTime']['input'];
    orderId: Scalars['String']['input'];
    requiresApproval: Scalars['Boolean']['input'];
    rollType: Scalars['String']['input'];
    title: Scalars['String']['input'];
};

export type CreateArtistInput = {
    artistId?: InputMaybe<Scalars['Int']['input']>;
    artistInfoId?: InputMaybe<Scalars['Int']['input']>;
    artistName: Scalars['String']['input'];
    artistType?: InputMaybe<Scalars['String']['input']>;
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateBulkSessionInput = {
    vendorUUID: Scalars['String']['input'];
};

export type CreateBundledProductInput = {
    audioProductId: Scalars['ID']['input'];
    bundleType: BundleType;
    selectedThumbnailId: Scalars['ID']['input'];
    volumes: Array<Array<Scalars['ID']['input']>>;
};

export type CreateChangelogRecordInput = {
    changeType: ChangeType;
    compositionId: Scalars['ID']['input'];
    compositionMetadata?: InputMaybe<Scalars['String']['input']>;
    deleted: Scalars['Boolean']['input'];
    delivered: Scalars['Boolean']['input'];
    entityCreatedAt: Scalars['DateTime']['input'];
    entityCreatedBy: Scalars['String']['input'];
    entityLastModifiedAt: Scalars['DateTime']['input'];
    entityLastModifiedBy: Scalars['String']['input'];
    recordId?: InputMaybe<Scalars['Int']['input']>;
    songwriterMetadata?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCollaboratorSplitInput = {
    collaboratorId: Scalars['ID']['input'];
    identifier: Scalars['String']['input'];
    productId: Scalars['Int']['input'];
    rateType?: InputMaybe<CollaboratorSplitRateType>;
    splitRate: Scalars['Float']['input'];
    splitTypeId: Scalars['Int']['input'];
};

export type CreateCollaboratorTransactionInput = {
    collaboratorId: Scalars['ID']['input'];
    collaboratorShare?: InputMaybe<Scalars['Float']['input']>;
    currency?: InputMaybe<Scalars['String']['input']>;
    date?: InputMaybe<Scalars['Date']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    originalAmount: Scalars['Float']['input'];
    reportId?: InputMaybe<Scalars['Int']['input']>;
    type: CollaboratorTransactionType;
};

export type CreateEmailCampaignInput = {
    cssContent: Scalars['String']['input'];
    fanDataListId: FsAudienceFanDataListItemInput;
    htmlContent: Scalars['String']['input'];
    name: Scalars['String']['input'];
    subaccountId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export type CreateEpisodeInput = {
    appleId?: InputMaybe<Scalars['String']['input']>;
    artworkFilename?: InputMaybe<Scalars['String']['input']>;
    audioFilename?: InputMaybe<Scalars['String']['input']>;
    content?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    draft: Scalars['Boolean']['input'];
    episodeNumber?: InputMaybe<Scalars['Int']['input']>;
    episodeType?: InputMaybe<Scalars['String']['input']>;
    externalId?: InputMaybe<Scalars['String']['input']>;
    feedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    plannedMidRollCount?: InputMaybe<Scalars['Int']['input']>;
    plannedPostRollCount?: InputMaybe<Scalars['Int']['input']>;
    plannedPreRollCount?: InputMaybe<Scalars['Int']['input']>;
    podcastId: Scalars['Int']['input'];
    publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
    seasonId?: InputMaybe<Scalars['Int']['input']>;
    seasonNumber?: InputMaybe<Scalars['Int']['input']>;
    title: Scalars['String']['input'];
    trailerType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEpisodeInsertionPointInput = {
    count: Scalars['Int']['input'];
    pointType: Scalars['String']['input'];
    timecode: Scalars['String']['input'];
};

export type CreateEpisodeInsertionPointsInput = {
    episodeId: Scalars['Int']['input'];
    insertionPoints: Array<CreateEpisodeInsertionPointInput>;
    podcastId: Scalars['Int']['input'];
};

export type CreateEpisodeLinkInput = {
    episodeId: Scalars['Int']['input'];
    link: Scalars['String']['input'];
    podcastId: Scalars['Int']['input'];
    storeId: Scalars['Int']['input'];
};

export type CreateFeedInput = {
    artworkFilename: Scalars['String']['input'];
    channelId?: InputMaybe<Scalars['String']['input']>;
    description: Scalars['String']['input'];
    episodeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    feedType: Scalars['String']['input'];
    isCopyAdLocations?: InputMaybe<Scalars['Boolean']['input']>;
    podcastId: Scalars['Int']['input'];
    showFamilyId: Scalars['Int']['input'];
    slug?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
};

export type CreateGlobalParticipantLinkInput = {
    chartMode?: InputMaybe<Scalars['String']['input']>;
    chartName?: InputMaybe<Scalars['String']['input']>;
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    dimension?: InputMaybe<Scalars['String']['input']>;
    endDate?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['String']['input'];
    page?: InputMaybe<Scalars['String']['input']>;
    period?: InputMaybe<Scalars['String']['input']>;
    playlistAppearances?: InputMaybe<Scalars['String']['input']>;
    playlistCuratorMkt?: InputMaybe<Scalars['String']['input']>;
    playlistTerritory?: InputMaybe<Scalars['String']['input']>;
    playlistTypes?: InputMaybe<Array<Scalars['String']['input']>>;
    pot?: InputMaybe<Scalars['String']['input']>;
    potGraphType?: InputMaybe<PotGraphType>;
    song?: InputMaybe<Scalars['String']['input']>;
    source?: InputMaybe<Scalars['String']['input']>;
    startDate?: InputMaybe<Scalars['String']['input']>;
    storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateGlobalProductLinkInput = {
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    dimension?: InputMaybe<Scalars['String']['input']>;
    endDate?: InputMaybe<Scalars['String']['input']>;
    page?: InputMaybe<Scalars['String']['input']>;
    period?: InputMaybe<Scalars['String']['input']>;
    pot?: InputMaybe<Scalars['String']['input']>;
    potGraphType?: InputMaybe<PotGraphType>;
    potIds?: InputMaybe<Array<Scalars['String']['input']>>;
    source?: InputMaybe<Scalars['String']['input']>;
    startDate?: InputMaybe<Scalars['String']['input']>;
    storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    upc: Scalars['String']['input'];
};

export type CreateGlobalSoundRecordingLinkInput = {
    chartName?: InputMaybe<Scalars['String']['input']>;
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    dimension?: InputMaybe<Scalars['String']['input']>;
    endDate?: InputMaybe<Scalars['String']['input']>;
    isrc: Scalars['String']['input'];
    page?: InputMaybe<Scalars['String']['input']>;
    period?: InputMaybe<Scalars['String']['input']>;
    playlistAppearances?: InputMaybe<Scalars['String']['input']>;
    playlistCuratorMkt?: InputMaybe<Scalars['String']['input']>;
    playlistTerritory?: InputMaybe<Scalars['String']['input']>;
    playlistTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    pot?: InputMaybe<Scalars['String']['input']>;
    potGraphType?: InputMaybe<PotGraphType>;
    potStore?: InputMaybe<Array<Scalars['ID']['input']>>;
    sosDetails?: InputMaybe<Scalars['Boolean']['input']>;
    source?: InputMaybe<Scalars['String']['input']>;
    startDate?: InputMaybe<Scalars['String']['input']>;
    storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateInventoryInput = {
    dates: Array<Scalars['DateTime']['input']>;
    midRolls: Scalars['Int']['input'];
    podcastId: Scalars['Int']['input'];
    postRolls: Scalars['Int']['input'];
    preRolls: Scalars['Int']['input'];
    titlePrefix: Scalars['String']['input'];
};

export type CreateNetworkInput = {
    code: Scalars['String']['input'];
    isSony: Scalars['Boolean']['input'];
    name: Scalars['String']['input'];
};

export type CreateNrContributorInput = {
    forename: Scalars['String']['input'];
    middleNames?: InputMaybe<Array<Scalars['String']['input']>>;
    surname: Scalars['String']['input'];
    vendorId: Scalars['Int']['input'];
};

export type CreateNrDeliveryJobsInput = {
    nrContributorUuids: Array<Scalars['ID']['input']>;
    orderId: Scalars['ID']['input'];
};

export type CreateNrParticipantInput = {
    appleMusicId?: InputMaybe<Scalars['Int']['input']>;
    name: Scalars['String']['input'];
    spotifyId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNrSoundRecordingInput = {
    albumName?: InputMaybe<Scalars['String']['input']>;
    catalogNumber?: InputMaybe<Scalars['String']['input']>;
    countriesOfRecording?: InputMaybe<Array<Scalars['String']['input']>>;
    countryOfLabel?: InputMaybe<Scalars['String']['input']>;
    countryOfMaster?: InputMaybe<Scalars['String']['input']>;
    duration?: InputMaybe<Scalars['Int']['input']>;
    featuredArtistIds: Array<Scalars['ID']['input']>;
    firstReleaseYear?: InputMaybe<Scalars['Int']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    mainArtist: Scalars['String']['input'];
    originalLabel?: InputMaybe<Scalars['String']['input']>;
    primaryArtistIds: Array<Scalars['ID']['input']>;
    priority: Priority;
    recordingTitle: Scalars['String']['input'];
    recordingType: Scalars['String']['input'];
    recordingYear?: InputMaybe<Scalars['Int']['input']>;
    totalFeaturedPerformers?: InputMaybe<Scalars['Int']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrDeleteAbacusScheduleAttachmentInput = {
    assignedTargetIds?: InputMaybe<
        Array<InputMaybe<Scalars['String']['input']>>
    >;
    scheduleId: Scalars['Int']['input'];
    targetType: Scalars['String']['input'];
    unassignedTargetIds?: InputMaybe<
        Array<InputMaybe<Scalars['String']['input']>>
    >;
};

export type CreateOwnershipContributorInput = {
    name: Scalars['String']['input'];
};

export type CreateOwnershipNrProductInput = {
    code?: InputMaybe<Scalars['String']['input']>;
    contentType: OwnershipNrProductContentType;
    displayArtistName: Scalars['String']['input'];
    externalId?: InputMaybe<Scalars['String']['input']>;
    featuredArtists?: InputMaybe<Array<Scalars['String']['input']>>;
    format: OwnershipNrProductFormat;
    imprint?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    pLineLabel: Scalars['String']['input'];
    pLineYear: Scalars['Int']['input'];
    primaryArtists: Array<Scalars['String']['input']>;
    recordingType?: InputMaybe<OwnershipNrTrackRecordingType>;
    salesStartDate: Scalars['Date']['input'];
    subType?: InputMaybe<OwnershipNrProductSubType>;
    upc: Scalars['String']['input'];
    version?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOwnershipNrRuleInput = {
    endDate?: InputMaybe<Scalars['Date']['input']>;
    externalId?: InputMaybe<Scalars['String']['input']>;
    percentage: Scalars['Float']['input'];
    startDate: Scalars['Date']['input'];
    territoriesExcluded?: Array<Scalars['String']['input']>;
    territoriesIncluded: Array<Scalars['String']['input']>;
    vendorId: Scalars['Int']['input'];
};

export type CreateOwnershipNrSoundRecordingInput = {
    audioLanguage?: InputMaybe<Scalars['String']['input']>;
    countriesOfFunding: Array<Scalars['String']['input']>;
    countriesOfRecording: Array<Scalars['String']['input']>;
    displayArtistName: Scalars['String']['input'];
    displayComposer?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    duration: Scalars['Int']['input'];
    explicit?: InputMaybe<Scalars['Boolean']['input']>;
    featuredArtists?: InputMaybe<Array<Scalars['String']['input']>>;
    firstYearOfRecording: Scalars['Int']['input'];
    firstYearOfRelease: Scalars['Int']['input'];
    genre?: InputMaybe<Scalars['String']['input']>;
    isrc: Scalars['String']['input'];
    primaryArtists: Array<Scalars['String']['input']>;
    recordingTitle: Scalars['String']['input'];
    trackIds: Array<Scalars['ID']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePhysicalDeliveryOrderInput = {
    deliveryStoreId: Scalars['ID']['input'];
    deliveryType: PhysicalDeliveryType;
    productIds: Array<Scalars['Int']['input']>;
};

export type CreatePlaylistPlacementLinkInput = {
    isrc: Scalars['String']['input'];
    playlistId: Scalars['String']['input'];
    storeId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePodcastInput = {
    artworkFilename: Scalars['String']['input'];
    categories: Array<Scalars['Int']['input']>;
    channelId?: InputMaybe<Scalars['String']['input']>;
    copyright: Scalars['String']['input'];
    description: Scalars['String']['input'];
    email: Scalars['String']['input'];
    explicit: Scalars['String']['input'];
    feedType?: InputMaybe<PodcastFeedType>;
    host: Scalars['String']['input'];
    language: Scalars['String']['input'];
    link?: InputMaybe<Scalars['String']['input']>;
    networkId: Scalars['Int']['input'];
    owner: Scalars['String']['input'];
    participants?: InputMaybe<Array<PodcastParticipantInput>>;
    seasons?: InputMaybe<Array<PodcastSeasonInput>>;
    showType: Scalars['String']['input'];
    slug?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
};

export type CreatePodcastLinkInput = {
    link: Scalars['String']['input'];
    storeId: Scalars['Int']['input'];
};

export type CreatePodcastLinksInput = {
    links: Array<CreatePodcastLinkInput>;
    podcastId: Scalars['Int']['input'];
};

export type CreatePodcastUserInput = {
    admin?: InputMaybe<Scalars['Boolean']['input']>;
    allNetworks?: InputMaybe<Scalars['Boolean']['input']>;
    email: Scalars['String']['input'];
    name: Scalars['String']['input'];
    networkIds: Array<Scalars['Int']['input']>;
    organization: Scalars['String']['input'];
    podcastIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    role?: InputMaybe<Scalars['String']['input']>;
    showFamilyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateProductCorrectionInput = {
    productId: Scalars['ID']['input'];
};

export type CreateProductInput = {
    accountId?: InputMaybe<Scalars['Int']['input']>;
    accountType?: InputMaybe<Scalars['String']['input']>;
    cLine?: InputMaybe<Scalars['String']['input']>;
    channelSelection?: InputMaybe<Scalars['String']['input']>;
    deliveredVersion?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    format?: InputMaybe<Scalars['String']['input']>;
    genreId?: InputMaybe<Scalars['Int']['input']>;
    imprint?: InputMaybe<Scalars['String']['input']>;
    manufacturerUpc?: InputMaybe<Scalars['String']['input']>;
    metaLanguage?: InputMaybe<Scalars['String']['input']>;
    notForDistribution?: InputMaybe<Scalars['String']['input']>;
    pLine?: InputMaybe<Scalars['String']['input']>;
    participations?: InputMaybe<Array<ProductParticipationInput>>;
    /** Prefer to use `participations` instead of this field. */
    primaryArtists?: InputMaybe<Array<InputMaybe<CreateArtistInput>>>;
    productCode?: InputMaybe<Scalars['String']['input']>;
    productConfiguration?: InputMaybe<ProductConfiguration>;
    productHighlights: Scalars['String']['input'];
    productLocalizations?: InputMaybe<Array<SetProductLocalizationInput>>;
    productName: Scalars['String']['input'];
    projectId: Scalars['Int']['input'];
    specialInstructions?: InputMaybe<Scalars['String']['input']>;
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    subgenreId?: InputMaybe<Scalars['Int']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
    vendorReleaseIdentifier?: InputMaybe<Scalars['String']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductTimedRelease = {
    productId: Scalars['Int']['input'];
    storeIds: Array<Scalars['Int']['input']>;
    timeOfDayProduct: Scalars['String']['input'];
    timeZone: Scalars['String']['input'];
};

export type CreateProjectInput = {
    accountId?: InputMaybe<Scalars['Int']['input']>;
    artistId?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    projectCode: Scalars['String']['input'];
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePublishingAgreementInput = {
    ipi?: InputMaybe<Scalars['String']['input']>;
    label: LabelUuid;
    name: Scalars['String']['input'];
    pro?: InputMaybe<Scalars['String']['input']>;
    pubWriterID?: InputMaybe<Scalars['Int']['input']>;
    publisher: PublishingSongWriterPublishersInput;
};

export type CreatePublishingCompositionInput = {
    agreements: Array<PublishingCompositionAgreementInput>;
    alternateTitles: Array<Scalars['String']['input']>;
    associatedGlobalSoundRecordings: Array<Scalars['String']['input']>;
    associatedLabelSoundRecordings: Array<Scalars['String']['input']>;
    containsPublicDomain?: InputMaybe<Scalars['Boolean']['input']>;
    containsSample?: InputMaybe<Scalars['Boolean']['input']>;
    draft: Scalars['Boolean']['input'];
    iswc?: InputMaybe<Scalars['String']['input']>;
    label: LabelUuid;
    pubSongID?: InputMaybe<Scalars['Int']['input']>;
    title: Scalars['String']['input'];
};

export type CreatePublishingSongWriterInput = {
    ipi?: InputMaybe<Scalars['String']['input']>;
    label: LabelUuid;
    name: Scalars['String']['input'];
    pro?: InputMaybe<Scalars['String']['input']>;
    professionallyKnownAs?: InputMaybe<Array<Scalars['String']['input']>>;
    pubWriterID?: InputMaybe<Scalars['Int']['input']>;
    publishers: Array<PublishingSongWriterPublishersInput>;
};

export type CreateSampleInput = {
    artists?: InputMaybe<Array<InputMaybe<SampleArtistInput>>>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    pLine?: InputMaybe<Scalars['String']['input']>;
    sampleType?: InputMaybe<Scalars['String']['input']>;
    trackLength?: InputMaybe<Scalars['Int']['input']>;
    trackName: Scalars['String']['input'];
};

export type CreateStoreCarveoutInput = {
    distributionTypeIds: Array<Scalars['Int']['input']>;
    storeId: Scalars['Int']['input'];
};

export type CreateTrackArtistInput = {
    artistInfoId?: InputMaybe<Scalars['Int']['input']>;
    artistName: Scalars['String']['input'];
    artistType: Scalars['String']['input'];
    trackArtistId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTrackInput = {
    /** Prefer to use `participations` instead of this field. */
    artists?: InputMaybe<Array<InputMaybe<CreateTrackArtistInput>>>;
    explicit: Scalars['String']['input'];
    isrc?: InputMaybe<Scalars['String']['input']>;
    localizations?: InputMaybe<Array<InputMaybe<SetTrackLocalizationInput>>>;
    lyrics?: InputMaybe<Scalars['String']['input']>;
    metaLanguageCode?: InputMaybe<Scalars['String']['input']>;
    originalRightsHolderCountryId?: InputMaybe<Scalars['Int']['input']>;
    ownershipRights?: InputMaybe<Scalars['String']['input']>;
    pInfo?: InputMaybe<Scalars['String']['input']>;
    participations?: InputMaybe<Array<InputMaybe<TrackParticipationInput>>>;
    performers?: InputMaybe<Array<InputMaybe<CreateTrackPerformerInput>>>;
    previewStartTime?: InputMaybe<Scalars['Int']['input']>;
    publishers?: InputMaybe<Array<InputMaybe<CreateTrackPublisherInput>>>;
    recordingCountryId?: InputMaybe<Scalars['Int']['input']>;
    samples?: InputMaybe<Array<InputMaybe<CreateSampleInput>>>;
    trackName: Scalars['String']['input'];
    trackNumber?: InputMaybe<Scalars['Int']['input']>;
    trackType?: InputMaybe<Scalars['String']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
    usPublishingObligation?: InputMaybe<UsPublishingObligation>;
    version?: InputMaybe<Scalars['String']['input']>;
    volumeNumber?: InputMaybe<Scalars['Int']['input']>;
    /** Prefer to use `participations` instead of this field. */
    writers?: InputMaybe<Array<InputMaybe<CreateTrackWriterInput>>>;
};

export type CreateTrackPerformerInput = {
    name: Scalars['String']['input'];
    roleId: Scalars['Int']['input'];
    type: Scalars['String']['input'];
};

export type CreateTrackPublisherInput = {
    name: Scalars['String']['input'];
};

export type CreateTrackWriterInput = {
    artistInfoId?: InputMaybe<Scalars['Int']['input']>;
    trackWriterId?: InputMaybe<Scalars['Int']['input']>;
    writerName: Scalars['String']['input'];
    writerType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTracksInput = {
    productId: Scalars['Int']['input'];
    tracks?: InputMaybe<Array<InputMaybe<CreateTrackInput>>>;
};

export type CreateTransferWiseProfileInput = {
    application?: InputMaybe<CollaboratorsApplicationType>;
    code: Scalars['String']['input'];
    profileId: Scalars['Int']['input'];
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTransferWiseRecipientInput = {
    accountHolderName?: InputMaybe<Scalars['String']['input']>;
    collaboratorId: Scalars['ID']['input'];
    currency: Scalars['String']['input'];
    details: Scalars['JSONObject']['input'];
    profileId: Scalars['Int']['input'];
    sourceCurrency?: InputMaybe<Scalars['String']['input']>;
    type: Scalars['String']['input'];
};

export type CreateVendorInput = {
    companyBrandName: Scalars['String']['input'];
    currency: Scalars['String']['input'];
    name: Scalars['String']['input'];
    owner: Scalars['String']['input'];
    serviceTierUuid: Scalars['ID']['input'];
};

export type CreateVideoLink = {
    claims?: InputMaybe<Scalars['String']['input']>;
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    endDate?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['String']['input'];
    metric?: InputMaybe<Scalars['String']['input']>;
    page?: InputMaybe<Scalars['String']['input']>;
    period?: InputMaybe<Scalars['String']['input']>;
    startDate?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoSingleProductInput = {
    accountId?: InputMaybe<Scalars['Int']['input']>;
    associatedTrackId?: InputMaybe<Scalars['Int']['input']>;
    channelSelection?: InputMaybe<Scalars['String']['input']>;
    clineCopyrightHolder?: InputMaybe<Scalars['String']['input']>;
    clineYear?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    genreId?: InputMaybe<Scalars['Int']['input']>;
    imprint?: InputMaybe<Scalars['String']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    keywords?: InputMaybe<Array<Scalars['String']['input']>>;
    languageOfVideoContent?: InputMaybe<Scalars['String']['input']>;
    languageOfVideoTitle?: InputMaybe<Scalars['String']['input']>;
    lyrics?: InputMaybe<Scalars['String']['input']>;
    newRelease?: InputMaybe<Scalars['Boolean']['input']>;
    notForDistribution?: InputMaybe<Scalars['String']['input']>;
    originalReleaseDate?: InputMaybe<Scalars['String']['input']>;
    parentalAdvisory?: InputMaybe<Scalars['String']['input']>;
    plineCopyrightHolder?: InputMaybe<Scalars['String']['input']>;
    plineYear?: InputMaybe<Scalars['Int']['input']>;
    primaryArtists?: InputMaybe<Array<InputMaybe<CreateArtistInput>>>;
    productCode?: InputMaybe<Scalars['String']['input']>;
    projectId: Scalars['String']['input'];
    releaseDate?: InputMaybe<Scalars['String']['input']>;
    specialInstructions?: InputMaybe<Scalars['String']['input']>;
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    subgenreId?: InputMaybe<Scalars['Int']['input']>;
    thumbnailPath?: InputMaybe<Scalars['String']['input']>;
    typeOfVideo?: InputMaybe<Scalars['String']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
    vendorCatalogNumber?: InputMaybe<Scalars['String']['input']>;
    vendorReleaseIdentifier?: InputMaybe<Scalars['String']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
    videoTitle?: InputMaybe<Scalars['String']['input']>;
};

export enum DatePeriod {
    ALL_TIME = 'ALL_TIME',
}

export type DeleteAdReadCommentInput = {
    id: Scalars['ID']['input'];
};

export type DeleteAdReadInput = {
    id: Scalars['ID']['input'];
};

export type DeleteCollaboratorReportsInput = {
    reportIds: Array<Scalars['ID']['input']>;
};

export type DeleteCollaboratorSplitInput = {
    id: Scalars['Int']['input'];
};

export type DeleteDeliveryQueueItemInput = {
    cmoId: Scalars['ID']['input'];
    contributionId: Scalars['ID']['input'];
};

export type DeleteEpisodeInput = {
    id: Scalars['ID']['input'];
    podcastId: Scalars['Int']['input'];
};

export type DeleteEpisodeLinkInput = {
    episodeId: Scalars['Int']['input'];
    storeId: Scalars['Int']['input'];
};

export type DeleteGoogleAdAccountInput = {
    adAccountId: Scalars['ID']['input'];
    subaccountId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export type DeleteMetaAdAccountInput = {
    adAccountId: Scalars['ID']['input'];
    subaccountId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export type DeletePodcastInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type DeletePodcastTranscriptionInput = {
    episodeId: Scalars['Int']['input'];
};

export type DeletePodcastUserInput = {
    id: Scalars['ID']['input'];
};

export type DeleteProductCorrectionInput = {
    productId: Scalars['ID']['input'];
    releaseCorrectionId: Scalars['ID']['input'];
};

export type DeleteProductTimedRelease = {
    productId: Scalars['Int']['input'];
};

export type DeleteTiktokAdAccountInput = {
    adAccountId: Scalars['ID']['input'];
    subaccountId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export type DeleteTracksInput = {
    productId: Scalars['ID']['input'];
    tracks: Array<Scalars['ID']['input']>;
};

export type DeleteTransferWiseRecipientInput = {
    collaboratorId: Scalars['ID']['input'];
    recipientId: Scalars['Int']['input'];
};

export enum DeletionStatus {
    N = 'N',
    Y = 'Y',
}

export type DeliveryHistory = {
    dateDelivered: Scalars['Date']['input'];
    encoderId: Scalars['Int']['input'];
    packageSize?: InputMaybe<Scalars['Int']['input']>;
    storeId: Scalars['Int']['input'];
    upc: Scalars['String']['input'];
};

export type DeliveryHistoryInput = {
    deliveryHistory?: InputMaybe<Array<DeliveryHistory>>;
};

export enum DeliveryStoreClassification {
    AGGREGATOR = 'AGGREGATOR',
    CHARTING = 'CHARTING',
    COLLECTIONS = 'COLLECTIONS',
    FILM_TV = 'FILM_TV',
    FINGERPRINTING = 'FINGERPRINTING',
    MARKETING = 'MARKETING',
    MUSIC = 'MUSIC',
    MUSIC_VIDEO = 'MUSIC_VIDEO',
    NR_OWNER = 'NR_OWNER',
    NR_PERFORMER = 'NR_PERFORMER',
    PHYSICAL = 'PHYSICAL',
    PHYSICAL_SUPPLY_CHAIN = 'PHYSICAL_SUPPLY_CHAIN',
    REPORTING_ONLY = 'REPORTING_ONLY',
    RINGTONE = 'RINGTONE',
    TESTING = 'TESTING',
}

export type DeliveryStoreInput = {
    id: Scalars['ID']['input'];
};

export enum DeliveryStoreOrderBy {
    NAME = 'NAME',
}

export enum DeliveryStoreStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    TERMINATED = 'TERMINATED',
}

export enum DeltaGrowthPeriod {
    _1_DAY = '_1_DAY',
    _1_TO_2_MONTHS = '_1_TO_2_MONTHS',
    _2_TO_3_MONTHS = '_2_TO_3_MONTHS',
    _3_TO_4_MONTHS = '_3_TO_4_MONTHS',
    _7_DAYS = '_7_DAYS',
    _14_DAYS = '_14_DAYS',
    _28_DAYS = '_28_DAYS',
    _90_DAYS = '_90_DAYS',
    _180_DAYS = '_180_DAYS',
    _183_DAYS = '_183_DAYS',
    _365_DAYS = '_365_DAYS',
    _ALL_TIME = '_ALL_TIME',
}

export enum DemographicAgeCategory {
    UNKNOWN = 'UNKNOWN',
    _17 = '_17',
    _18_22 = '_18_22',
    _23_27 = '_23_27',
    _28_34 = '_28_34',
    _35_44 = '_35_44',
    _45_59 = '_45_59',
    _60_ = '_60_',
}

export enum DisplayStatus {
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    COMPLETED = 'COMPLETED',
    ERROR_CORRECTION = 'ERROR_CORRECTION',
    IN_CONTENT = 'IN_CONTENT',
    IN_PROGRESS = 'IN_PROGRESS',
    LABEL_PROCESSING = 'LABEL_PROCESSING',
    SUBMITTED = 'SUBMITTED',
    TRANSFER_TO_CONTENT = 'TRANSFER_TO_CONTENT',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    action_required = 'action_required',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    completed = 'completed',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    error_correction = 'error_correction',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    in_progress = 'in_progress',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    submitted = 'submitted',
}

export enum Distributor {
    AWAL = 'AWAL',
    SME = 'SME',
    THEORCHARD = 'THEORCHARD',
}

export enum EmailCampaignStatus {
    CANCELLED = 'CANCELLED',
    DRAFT = 'DRAFT',
    IN_PROGRESS = 'IN_PROGRESS',
    SCHEDULED = 'SCHEDULED',
    SENT = 'SENT',
}

export type EpisodeAudioInput = {
    episodeId: Scalars['Int']['input'];
};

export enum EpisodeStates {
    ALL = 'ALL',
    DONE = 'DONE',
    DRAFT = 'DRAFT',
}

export type ErrorMetadataInput = {
    errorKey: ReportErrorKey;
    message?: InputMaybe<Scalars['String']['input']>;
};

export type ExportAudienceInput = {
    justification: AudienceExportJustification;
    reason: AudienceExportReason;
    reasonNotes?: InputMaybe<Scalars['String']['input']>;
};

export type FsAudienceCityFilterInput = {
    placeId: Scalars['ID']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type FsAudienceEmailCampaignFilterInput = {
    action: AudienceEmailCampaignAction;
    operator: AudienceFilterOperator;
    value: Array<Scalars['String']['input']>;
};

export type FsSongwhipPresavePageInFilterInput = {
    operator: AudienceFilterOperator;
    value: Array<Scalars['String']['input']>;
};

export enum Featured {
    FEATURING_PERFORMER = 'FEATURING_PERFORMER',
    MAIN_PERFORMER = 'MAIN_PERFORMER',
    SESSION_MUSICIAN = 'SESSION_MUSICIAN',
}

export enum FeaturedInput {
    FEATURED = 'FEATURED',
    FEATURING_PERFORMER = 'FEATURING_PERFORMER',
    MAIN_PERFORMER = 'MAIN_PERFORMER',
    NON_FEATURED = 'NON_FEATURED',
    OTHER_FEATURED = 'OTHER_FEATURED',
    SESSION_MUSICIAN = 'SESSION_MUSICIAN',
}

export type FingerprintRuleInput = {
    end?: InputMaybe<Scalars['DateTime']['input']>;
    policy: Scalars['String']['input'];
    service: Scalars['String']['input'];
    start?: InputMaybe<Scalars['DateTime']['input']>;
    territory: Scalars['String']['input'];
};

export type FollowChannelInput = {
    channelId: Scalars['String']['input'];
};

export type FollowGlobalProductInput = {
    upc: Scalars['String']['input'];
};

export type FollowGlobalSoundRecordingInput = {
    isrc: Scalars['String']['input'];
};

export type FollowParticipantInput = {
    participantId: Scalars['ID']['input'];
};

export enum FollowedEntity {
    LABEL = 'LABEL',
    PARTICIPANT = 'PARTICIPANT',
    SOUND_RECORDING = 'SOUND_RECORDING',
    SUBACCOUNT = 'SUBACCOUNT',
    VENDOR = 'VENDOR',
}

export type FsAudienceFanDataListItemInput = {
    id: Scalars['ID']['input'];
    type: FsFanDataListType;
};

export enum FsFanDataListType {
    ARTIST = 'ARTIST',
    CUSTOM_LIST = 'CUSTOM_LIST',
}

export type FulfillNrDeliveryJobInput = {
    jobIds: Array<Scalars['ID']['input']>;
    status: NrDeliveryJobStatus;
};

export enum GlobalParticipantAggregatedRankingsOrderingV2 {
    CHANGE = 'CHANGE',
    CHART_COUNTRYCODE = 'CHART_COUNTRYCODE',
    CHART_NAME = 'CHART_NAME',
    CHART_PLATFORM = 'CHART_PLATFORM',
    DAYS_ON_CHART = 'DAYS_ON_CHART',
    EXIT_TIMESTAMP = 'EXIT_TIMESTAMP',
    FIRST_POSITION = 'FIRST_POSITION',
    FIRST_TIMESTAMP = 'FIRST_TIMESTAMP',
    MOST_RECENT_POSITION = 'MOST_RECENT_POSITION',
    MOST_RECENT_TIMESTAMP = 'MOST_RECENT_TIMESTAMP',
    PEAK_POSITION = 'PEAK_POSITION',
    PEAK_TIMESTAMP = 'PEAK_TIMESTAMP',
    POSITION = 'POSITION',
    PREVIOUS_POSITION = 'PREVIOUS_POSITION',
    PREVIOUS_TIMESTAMP = 'PREVIOUS_TIMESTAMP',
    SOUND_RECORDING_NAME = 'SOUND_RECORDING_NAME',
}

export enum GlobalParticipantAnalyticsSummaryType {
    COUNTRY = 'COUNTRY',
    PRODUCT = 'PRODUCT',
    SOS = 'SOS',
    SOUND_RECORDING = 'SOUND_RECORDING',
    STORE = 'STORE',
    TOTAL = 'TOTAL',
}

export enum GlobalParticipantAnalyticsTimeseriesType {
    ALBUM_DOWNLOADS = 'ALBUM_DOWNLOADS',
    ALBUM_DOWNLOADS_BY_COUNTRY = 'ALBUM_DOWNLOADS_BY_COUNTRY',
    ALBUM_DOWNLOADS_BY_PRODUCT = 'ALBUM_DOWNLOADS_BY_PRODUCT',
    ALBUM_DOWNLOADS_BY_STORE = 'ALBUM_DOWNLOADS_BY_STORE',
    TRACK_DOWNLOADS = 'TRACK_DOWNLOADS',
    TRACK_DOWNLOADS_BY_COUNTRY = 'TRACK_DOWNLOADS_BY_COUNTRY',
    TRACK_DOWNLOADS_BY_PRODUCT = 'TRACK_DOWNLOADS_BY_PRODUCT',
    TRACK_DOWNLOADS_BY_SOUND_RECORDING = 'TRACK_DOWNLOADS_BY_SOUND_RECORDING',
    TRACK_DOWNLOADS_BY_STORE = 'TRACK_DOWNLOADS_BY_STORE',
    TRACK_STREAMS = 'TRACK_STREAMS',
    TRACK_STREAMS_BY_COUNTRY = 'TRACK_STREAMS_BY_COUNTRY',
    TRACK_STREAMS_BY_PRODUCT = 'TRACK_STREAMS_BY_PRODUCT',
    TRACK_STREAMS_BY_SOS = 'TRACK_STREAMS_BY_SOS',
    TRACK_STREAMS_BY_SOUND_RECORDING = 'TRACK_STREAMS_BY_SOUND_RECORDING',
    TRACK_STREAMS_BY_STORE = 'TRACK_STREAMS_BY_STORE',
}

export type GlobalParticipantSearchFilterInput = {
    companyBrandUUIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
    companyBrandUuid?: InputMaybe<Scalars['ID']['input']>;
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    followers?: InputMaybe<NumberRangeInput>;
    labelIds?: InputMaybe<Array<LabelIdFilterInput>>;
    labelName?: InputMaybe<Scalars['String']['input']>;
    monthlyListeners?: InputMaybe<NumberRangeInput>;
    popularity?: InputMaybe<NumberRangeInput>;
    streams7Days?: InputMaybe<NumberRangeInput>;
    streams28Days?: InputMaybe<NumberRangeInput>;
    streamsAllTime?: InputMaybe<NumberRangeInput>;
};

export enum GlobalParticipantSearchOrderByField {
    MONTHLY_LISTENERS = 'MONTHLY_LISTENERS',
    POPULARITY = 'POPULARITY',
    SCORE = 'SCORE',
    STREAMS_7_DAYS = 'STREAMS_7_DAYS',
    STREAMS_28_DAYS = 'STREAMS_28_DAYS',
    STREAMS_ALL_TIME = 'STREAMS_ALL_TIME',
}

export type GlobalParticipantSearchOrderByInput = {
    dir?: InputMaybe<OrderDir>;
    field: GlobalParticipantSearchOrderByField;
};

export type GlobalProductSearchFilterInput = {
    companyBrandUUIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
    spotifyArtistIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum GlobalProductSearchOrderByField {
    RELEASE_DATE = 'RELEASE_DATE',
}

export type GlobalProductSearchOrderByInput = {
    dir?: InputMaybe<OrderDir>;
    field: GlobalProductSearchOrderByField;
};

export type GlobalSoundRecordingFamilyAnalyticsFilterInput = {
    companyBrandId?: InputMaybe<Scalars['ID']['input']>;
    countryCodes?: InputMaybe<Array<Scalars['String']['input']>>;
    distributors?: InputMaybe<Array<Distributor>>;
    /**
     * Financial label IDs to filter results. Usually, each account (label) has corresponding financial label ID.
     * For RCA Records this is E009.
     */
    finLabelIds?: InputMaybe<Array<Scalars['String']['input']>>;
    globalParticipantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    parentCompanyId?: InputMaybe<Scalars['ID']['input']>;
    serviceTierId?: InputMaybe<Scalars['ID']['input']>;
    storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    /** Upper profit center IDs to filter the results. */
    upperProfitCenters?: InputMaybe<Array<UpperProfitCenter>>;
};

export type GlobalSoundRecordingSearchFilterInput = {
    companyBrandUUIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
    companyBrandUuid?: InputMaybe<Scalars['ID']['input']>;
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    followers?: InputMaybe<NumberRangeInput>;
    globalParticipantIds?: InputMaybe<Array<Scalars['String']['input']>>;
    labelIds?: InputMaybe<Array<LabelIdFilterInput>>;
    labelName?: InputMaybe<Scalars['String']['input']>;
    monthlyListeners?: InputMaybe<NumberRangeInput>;
    participantName?: InputMaybe<Scalars['String']['input']>;
    popularity?: InputMaybe<NumberRangeInput>;
    releaseDate?: InputMaybe<StringRangeInput>;
    streams7Days?: InputMaybe<NumberRangeInput>;
    streams28Days?: InputMaybe<NumberRangeInput>;
    streamsAllTime?: InputMaybe<NumberRangeInput>;
};

export enum GlobalSoundRecordingSearchOrderByField {
    MONTHLY_LISTENERS = 'MONTHLY_LISTENERS',
    POPULARITY = 'POPULARITY',
    RELEASE_DATE = 'RELEASE_DATE',
    SCORE = 'SCORE',
    STREAMS_7_DAYS = 'STREAMS_7_DAYS',
    STREAMS_28_DAYS = 'STREAMS_28_DAYS',
    STREAMS_ALL_TIME = 'STREAMS_ALL_TIME',
}

export type GlobalSoundRecordingSearchOrderByInput = {
    dir?: InputMaybe<OrderDir>;
    field: GlobalSoundRecordingSearchOrderByField;
};

export enum GlobalSoundRecordingSummaryType {
    COUNTRY = 'COUNTRY',
    PRODUCT = 'PRODUCT',
    SOS = 'SOS',
    SOS_V2 = 'SOS_V2',
    STORE = 'STORE',
    TOTAL = 'TOTAL',
}

export enum GlobalSoundRecordingTimeseriesType {
    TRACK_DOWNLOADS = 'TRACK_DOWNLOADS',
    TRACK_DOWNLOADS_BY_COUNTRY = 'TRACK_DOWNLOADS_BY_COUNTRY',
    TRACK_DOWNLOADS_BY_PRODUCT = 'TRACK_DOWNLOADS_BY_PRODUCT',
    TRACK_DOWNLOADS_BY_STORE = 'TRACK_DOWNLOADS_BY_STORE',
    TRACK_STREAMS = 'TRACK_STREAMS',
    TRACK_STREAMS_BY_COUNTRY = 'TRACK_STREAMS_BY_COUNTRY',
    TRACK_STREAMS_BY_PRODUCT = 'TRACK_STREAMS_BY_PRODUCT',
    TRACK_STREAMS_BY_SOS = 'TRACK_STREAMS_BY_SOS',
    TRACK_STREAMS_BY_SOS_V2 = 'TRACK_STREAMS_BY_SOS_V2',
    TRACK_STREAMS_BY_STORE = 'TRACK_STREAMS_BY_STORE',
}

export enum GoogleConnectionStatus {
    CONNECTED = 'CONNECTED',
    INVALID = 'INVALID',
    NOT_CONNECTED = 'NOT_CONNECTED',
    SCOPES_MISSING = 'SCOPES_MISSING',
    TOS_NOT_ACCEPTED = 'TOS_NOT_ACCEPTED',
}

export enum HighWaterMarkType {
    DOWNLOAD_STORES = 'DOWNLOAD_STORES',
    STREAMING_STORES = 'STREAMING_STORES',
    VIDEO_STORES = 'VIDEO_STORES',
}

export enum HistoryEventType {
    FULL_DELIVERY = 'FULL_DELIVERY',
    METADATA_UPDATE = 'METADATA_UPDATE',
}

/** IdentityInput describes all basic that should be set for create an identity. */
export type IdentityInput = {
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    localization?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

/**
 * IdentityTenantRolesCreateInput is used to create an Identity's roles for a single Tenant.
 * This input type is used by the INVITE workflow.
 *
 * **Only use this type if your application is on-boarded to PP.**
 */
export type IdentityTenantRolesCreateInput = {
    /**
     * rolesToAttach contains the list of roles to add
     * to the identity's roles for the tenant.
     */
    rolesToAttach: Array<PpRoleInput>;
    /** Tenant's type for which roles should be added. */
    tenantType: TenantType;
    /** Tenant's UUID for which roles should be added. */
    tenantUuid: Scalars['ID']['input'];
};

/**
 * IdentityTenantRolesInput is used to update an Identity's roles for a single Tenant.
 *
 * **Only use this type if your application is on-boarded to PP.**
 */
export type IdentityTenantRolesInput = {
    /** The Identity to update. */
    identityId: Scalars['ID']['input'];
    /**
     * rolesToAttach contains the list of roles to add/update
     * to the identity's existing roles for the tenant.
     *
     * Existing roles that are not specified in this list
     * will not be updated.
     *
     * If a role is present in both rolesToAttach and rolesToDetach,
     * the role will be removed for the identity's tenant roles.
     */
    rolesToAttach: Array<PpRoleInput>;
    /**
     * rolesToDetach contains the list of roles to remove (if present)
     * from the identity's existing roles for the tenant.
     *
     * Existing roles that are not specified in this list
     * will not be updated.
     *
     * If a role is present in both rolesToAttach and rolesToDetach,
     * the role will be removed for the identity's tenant roles.
     */
    rolesToDetach: Array<PpRoleInput>;
    /** Tenant's type for which roles should be updated. */
    tenantType: TenantType;
    /** Tenant's UUID for which roles should be updated. */
    tenantUuid: Scalars['ID']['input'];
};

/** InvitationInput describes all basic attributes that should be set for sent invitation: brand, email and vendContactId of user. */
export type InvitationInput = {
    defaultBrand: Scalars['String']['input'];
    email: Scalars['String']['input'];
    vendContactId?: InputMaybe<Scalars['Int']['input']>;
};

export type KnrWFormDetailsInput = {
    wFormExpiry?: InputMaybe<Scalars['Date']['input']>;
    wFormType?: InputMaybe<Scalars['String']['input']>;
};

export enum LabelAccess {
    ALL = 'ALL',
    NONE = 'NONE',
    SOME = 'SOME',
}

export type LabelIdFilterInput = {
    /**
     *  Use this field to filter on labels with matching subaccountIds.
     * - If used without vendorId it will match on subaccountId only.
     * - If used in combination with vendorId, it will match both values with an AND clause. This is not required since it will return the same result as just specifying the subaccountId.
     * - If you want to match a single vendorId without also matching its potential subaccounts, set this field to 0.
     */
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    /**
     *  Use this field to filter on labels with matching vendorIds.
     * - If used without subaccountId it will match on vendorId only, thus also return any related subaccounts.
     * - If used in combination with subaccountId it will match both values with an AND clause.
     * - If you want to match a single vendorId without also matching its potential subaccounts, set the subaccountId field to 0.
     */
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type LabelIdInput = {
    /** This field should be 0 for a Vendor. */
    subaccountId: Scalars['Int']['input'];
    /** This field can be null for a Subaccount. If it is non-null, it must be checked on the server. */
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export enum LabelIdentifier {
    CATALOG = 'CATALOG',
    CLIENT_SERVICES = 'CLIENT_SERVICES',
    D3 = 'D3',
    FILM = 'FILM',
    FRONTLINE = 'FRONTLINE',
    TEST = 'TEST',
    TV = 'TV',
}

export type LabelParticipantInput = {
    appleMusicId?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    spotifyId?: InputMaybe<Scalars['String']['input']>;
};

export type LabelProductFilterInput = {
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    includeProductsWithDeletions?: InputMaybe<Scalars['Boolean']['input']>;
    saleStartDateGte?: InputMaybe<Scalars['Date']['input']>;
    saleStartDateMonthsBack?: InputMaybe<Scalars['Int']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
    storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LabelProductOrder = {
    by: LabelProductOrderBy;
    direction: OrderDir;
};

export enum LabelProductOrderBy {
    DOWNLOADS = 'DOWNLOADS',
    DOWNLOADS_ALL_TIME = 'DOWNLOADS_ALL_TIME',
    DOWNLOADS_GROWTH_PERCENTAGE_7_DAYS = 'DOWNLOADS_GROWTH_PERCENTAGE_7_DAYS',
    DOWNLOADS_GROWTH_PERCENTAGE_28_DAYS = 'DOWNLOADS_GROWTH_PERCENTAGE_28_DAYS',
    SALE_START_DATE = 'SALE_START_DATE',
    STREAMS = 'STREAMS',
    STREAMS_1_DAY = 'STREAMS_1_DAY',
    STREAMS_7_DAYS = 'STREAMS_7_DAYS',
    STREAMS_28_DAYS = 'STREAMS_28_DAYS',
    STREAMS_ALL_TIME = 'STREAMS_ALL_TIME',
    STREAMS_GROWTH_PERCENTAGE_1_DAY = 'STREAMS_GROWTH_PERCENTAGE_1_DAY',
    STREAMS_GROWTH_PERCENTAGE_7_DAYS = 'STREAMS_GROWTH_PERCENTAGE_7_DAYS',
    STREAMS_GROWTH_PERCENTAGE_28_DAYS = 'STREAMS_GROWTH_PERCENTAGE_28_DAYS',
}

export type LabelSoundRecordingParticipationInput = {
    labelParticipantId: Scalars['ID']['input'];
    /** The `name` of a `ParticipationRole`. */
    participationRoleName: Scalars['String']['input'];
    sequenceNumber: Scalars['Int']['input'];
};

export type LabelTrackParticipationFilterInput = {
    /**
     * Filter on the participatedAs field. This is the raw database field which has a bunch of bad data in it.
     * See `LabelTrackParticipation.participatedAs` for more information
     */
    participated_as?: InputMaybe<Scalars['String']['input']>;
    participated_as_in?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum LabelType {
    ALL = 'ALL',
    SUBACCOUNT = 'SUBACCOUNT',
    VENDOR = 'VENDOR',
}

export type LabelUuid = {
    uuid: Scalars['String']['input'];
};

export type LogoutMobileDeviceInput = {
    deviceId: Scalars['String']['input'];
    refreshToken: Scalars['ID']['input'];
};

export enum MainArtist {
    HAS_FEATURED_ARTIST = 'HAS_FEATURED_ARTIST',
    HAS_PRIMARY_ARTIST = 'HAS_PRIMARY_ARTIST',
}

export enum MarketingHighlightStore {
    MARKETING_HIGHLIGHTS_STORE_APPLE = 'MARKETING_HIGHLIGHTS_STORE_APPLE',
    MARKETING_HIGHLIGHTS_STORE_SPOTIFY = 'MARKETING_HIGHLIGHTS_STORE_SPOTIFY',
}

export type MarketingHighlightsInput = {
    countryId?: InputMaybe<Scalars['Int']['input']>;
    marketingHighlight: Scalars['String']['input'];
    store?: InputMaybe<MarketingHighlightStore>;
};

export enum MarketingProgram {
    ARTISTS_BIO_ADDED_OR_UPDATED = 'ARTISTS_BIO_ADDED_OR_UPDATED',
    ARTIST_PHOTOS_ADDED_OR_UPDATED = 'ARTIST_PHOTOS_ADDED_OR_UPDATED',
    AWARDS_FOR_THE_ARTIST_OR_RELEASE = 'AWARDS_FOR_THE_ARTIST_OR_RELEASE',
    FEATURED_BLURB = 'FEATURED_BLURB',
    FLASHLIGHT_BLURB = 'FLASHLIGHT_BLURB',
    GREAT_CHART_POSITION_ACHIEVED = 'GREAT_CHART_POSITION_ACHIEVED',
    MARKETING_BLURB = 'MARKETING_BLURB',
    MARKETING_DRIVERS = 'MARKETING_DRIVERS',
    MARKETING_HIGHLIGHTS = 'MARKETING_HIGHLIGHTS',
    NEW_DIGITAL_ONLY_RELEASE_COMING = 'NEW_DIGITAL_ONLY_RELEASE_COMING',
    NEW_RELEASE_COMING_SOON = 'NEW_RELEASE_COMING_SOON',
    ONE_SHEET = 'ONE_SHEET',
    OTHER_MAJOR_PUBLIC_APPEARANCE = 'OTHER_MAJOR_PUBLIC_APPEARANCE',
    PRESS_COVERAGE = 'PRESS_COVERAGE',
    PRESS_KIT = 'PRESS_KIT',
    RADIO_ADD = 'RADIO_ADD',
    SOUNDS_LIKE = 'SOUNDS_LIKE',
    STRONG_CD_SALES_INFO = 'STRONG_CD_SALES_INFO',
    SYNC_HIGHLIGHTS = 'SYNC_HIGHLIGHTS',
    TOUR_PLANS = 'TOUR_PLANS',
    TRACK_LYRICS = 'TRACK_LYRICS',
    TV_APPEARANCE = 'TV_APPEARANCE',
}

export enum MarketingProgramInfoClient {
    MARKETING_PROGRAM_INFO_ALW_CLIENT = 'MARKETING_PROGRAM_INFO_ALW_CLIENT',
    MARKETING_PROGRAM_INFO_OA_CLIENT = 'MARKETING_PROGRAM_INFO_OA_CLIENT',
}

export type MarketingProgramInfoDataInput = {
    client: MarketingProgramInfoClient;
    description: Scalars['String']['input'];
    entityId: Scalars['Int']['input'];
    entityType: MarketingProgramInfoEntity;
    marketingProgram: MarketingProgram;
    subject?: InputMaybe<Scalars['String']['input']>;
};

export enum MarketingProgramInfoEntity {
    MARKETING_PROGRAM_INFO_ARTIST = 'MARKETING_PROGRAM_INFO_ARTIST',
    MARKETING_PROGRAM_INFO_LABEL = 'MARKETING_PROGRAM_INFO_LABEL',
    MARKETING_PROGRAM_INFO_PRODUCT = 'MARKETING_PROGRAM_INFO_PRODUCT',
    MARKETING_PROGRAM_INFO_PROJECT = 'MARKETING_PROGRAM_INFO_PROJECT',
}

export type MarketingProgramInfoInput = {
    data?: InputMaybe<Array<MarketingProgramInfoDataInput>>;
};

export enum MarketingProgramInfoScope {
    MARKETING_PROGRAM_INFO_INTERNAL_SCOPE = 'MARKETING_PROGRAM_INFO_INTERNAL_SCOPE',
    MARKETING_PROGRAM_INFO_PUBLIC_SCOPE = 'MARKETING_PROGRAM_INFO_PUBLIC_SCOPE',
}

export type MarketingProgramInfoUpsert = {
    attachment?: InputMaybe<Scalars['Boolean']['input']>;
    client: MarketingProgramInfoClient;
    description: Scalars['String']['input'];
    marketingProgram: MarketingProgram;
    marketingProgramInfoId?: InputMaybe<Scalars['Int']['input']>;
    scope?: InputMaybe<MarketingProgramInfoScope>;
    subject?: InputMaybe<Scalars['String']['input']>;
};

export type MechanicalDeductionInput = {
    adminFee?: InputMaybe<Scalars['Float']['input']>;
    adminType: Scalars['String']['input'];
    contractId: Scalars['ID']['input'];
    mechanicalType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MechanicalDeductionUpdate = {
    adminFee?: InputMaybe<Scalars['Float']['input']>;
    adminType?: InputMaybe<Scalars['String']['input']>;
    contractId: Scalars['ID']['input'];
    mechanicalDeductionId: Scalars['ID']['input'];
    mechanicalType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum MetaConnectionStatus {
    CONNECTED = 'CONNECTED',
    INVALID = 'INVALID',
    NOT_CONNECTED = 'NOT_CONNECTED',
    SCOPES_MISSING = 'SCOPES_MISSING',
    TOS_NOT_ACCEPTED = 'TOS_NOT_ACCEPTED',
}

export type MoneyhubCreateCustomReportInput = {
    accountId: Scalars['ID']['input'];
    contractId?: InputMaybe<Scalars['ID']['input']>;
    dimensionColumn: ReportDimensionColumnType;
    dimensionRow: ReportDimensionRowType;
    revenueType: ReportRevenueType;
    statementPeriodIds: Array<Scalars['ID']['input']>;
};

export type NetworkParticipantCreateInput = {
    name: Scalars['String']['input'];
    networkId: Scalars['Int']['input'];
};

export type NewMusicFridayDateGlobalSoundRecordingPlacementFilterInput = {
    appearsOn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewMusicFridayDateMarketFilterInput = {
    dataReceived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewMusicFridayGlobalSoundRecordingFilterInput = {
    brandUuidIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum NewMusicFridayGlobalSoundRecordingOrderEnum {
    FEATURING = 'FEATURING',
    POSITION_IN_MARKET = 'POSITION_IN_MARKET',
}

export type NewMusicFridayGlobalSoundRecordingOrderInput = {
    by: NewMusicFridayGlobalSoundRecordingOrderEnum;
    /** This field is required if `by` is set to `POSITION_IN_MARKET`, but must be unset otherwise. */
    market?: InputMaybe<Scalars['String']['input']>;
};

export enum NotificationFeedType {
    PLAYLIST_PLACEMENTS = 'PLAYLIST_PLACEMENTS',
    SOCIAL_SPIKE = 'SOCIAL_SPIKE',
    TRENDING_TRACKS = 'TRENDING_TRACKS',
}

export type NotificationMutationInput = {
    feedType: NotificationFeedType;
    followedEntity?: InputMaybe<FollowedEntity>;
    notificationType: NotificationType;
};

export enum NotificationType {
    EMAIL = 'EMAIL',
    PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
}

export type NrContributionInput = {
    contributorId: Scalars['ID']['input'];
    countryOfPerformance?: InputMaybe<Scalars['String']['input']>;
    evidence?: InputMaybe<Scalars['String']['input']>;
    featured: FeaturedInput;
    instruments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    nrsr?: InputMaybe<Scalars['ID']['input']>;
    performanceName?: InputMaybe<Scalars['String']['input']>;
};

export type NrContributionScheduleInput = {
    abacusScheduleId?: InputMaybe<Scalars['Int']['input']>;
    contributionId: Scalars['ID']['input'];
};

export type NrContributionSearchFilterInput = {
    abacusScheduleId?: InputMaybe<Scalars['Int']['input']>;
    account?: InputMaybe<Scalars['Int']['input']>;
    contributionId?: InputMaybe<Scalars['ID']['input']>;
    contributorId?: InputMaybe<Scalars['ID']['input']>;
    hasAbacusSchedule?: InputMaybe<Scalars['Boolean']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    mainArtist?: InputMaybe<Scalars['String']['input']>;
    participantId?: InputMaybe<Scalars['ID']['input']>;
    priority?: InputMaybe<Priority>;
    recordingTitle?: InputMaybe<Scalars['String']['input']>;
    soundRecordingId?: InputMaybe<Scalars['ID']['input']>;
    validContributions?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NrContributionSearchOrderByField {
    CONTRIBUTOR_RELATIONSHIP_NAME = 'CONTRIBUTOR_RELATIONSHIP_NAME',
    LAST_MODIFIED = 'LAST_MODIFIED',
    NRSR_NAME_VERSION = 'NRSR_NAME_VERSION',
    PRIMARY_ARTIST = 'PRIMARY_ARTIST',
    RELEASE_YEAR = 'RELEASE_YEAR',
}

export type NrContributorDeliveryStoreFilter = {
    statuses?: InputMaybe<Array<NrContributorDeliveryStoreStatus>>;
    storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum NrContributorDeliveryStoreStatus {
    AGREEMENT_ON_HOLD = 'AGREEMENT_ON_HOLD',
    AGREEMENT_TERMINATED = 'AGREEMENT_TERMINATED',
    AWAITING_CLIENT_INFO = 'AWAITING_CLIENT_INFO',
    AWAITING_CONTENT = 'AWAITING_CONTENT',
    CLIENT_ACTION_REQUIRED = 'CLIENT_ACTION_REQUIRED',
    CLIENT_INFO_RECEIVED = 'CLIENT_INFO_RECEIVED',
    DISPUTE = 'DISPUTE',
    NOT_CONTROLLED_IN_TERRITORY = 'NOT_CONTROLLED_IN_TERRITORY',
    NOT_QUALIFY = 'NOT_QUALIFY',
    REGISTERED = 'REGISTERED',
    REGISTERED_VIA_ACTRA = 'REGISTERED_VIA_ACTRA',
    REGISTERED_VIA_ADAMI = 'REGISTERED_VIA_ADAMI',
    REGISTERED_VIA_ITSRIGHT = 'REGISTERED_VIA_ITSRIGHT',
    REGISTERED_VIA_NUOVO_IMAIE = 'REGISTERED_VIA_NUOVO_IMAIE',
    REGISTERED_VIA_PLAYRIGHT = 'REGISTERED_VIA_PLAYRIGHT',
    REGISTERED_VIA_PPL = 'REGISTERED_VIA_PPL',
    REGISTERED_VIA_SENA = 'REGISTERED_VIA_SENA',
    REGISTRATION_CONFIRMED = 'REGISTRATION_CONFIRMED',
    REGISTRATION_ENQUIRY = 'REGISTRATION_ENQUIRY',
    REGISTRATION_GENERATED = 'REGISTRATION_GENERATED',
    REGISTRATION_NOT_REQUIRED = 'REGISTRATION_NOT_REQUIRED',
    REGISTRATION_REQUIRED = 'REGISTRATION_REQUIRED',
    REGISTRATION_SENT = 'REGISTRATION_SENT',
    REPRESENTATION_RELINQUISHED = 'REPRESENTATION_RELINQUISHED',
    REQUIRES_RELINQUISHMENT = 'REQUIRES_RELINQUISHMENT',
    SOCIETY_REVIEWING = 'SOCIETY_REVIEWING',
    WAITING_FOR_REGISTRATION_DEADLINE = 'WAITING_FOR_REGISTRATION_DEADLINE',
    WAITING_FOR_RIGHTS_PERIOD = 'WAITING_FOR_RIGHTS_PERIOD',
}

export type NrContributorSearchFilterInput = {
    hasContributions?: InputMaybe<Scalars['Boolean']['input']>;
    hasUnassignedContributionsCount?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NrContributorSearchOrderByField {
    ABACUS_UNASSIGNED_CONTRIBUTIONS_COUNT = 'ABACUS_UNASSIGNED_CONTRIBUTIONS_COUNT',
    NAME = 'NAME',
}

export enum NrContributorsOrderBy {
    ABACUS_UNASSIGNED_CONTRIBUTIONS_COUNT = 'ABACUS_UNASSIGNED_CONTRIBUTIONS_COUNT',
    ID = 'ID',
    NAME = 'NAME',
}

export type NrDeliveryClaimInput = {
    contributionUuid: Scalars['String']['input'];
    contributionVersion?: InputMaybe<Scalars['String']['input']>;
    job: Scalars['ID']['input'];
    message?: InputMaybe<Scalars['String']['input']>;
    status: NrDeliveryClaimStatus;
};

export enum NrDeliveryClaimStatus {
    ERROR = 'ERROR',
    EXCLUDED = 'EXCLUDED',
    QUEUED = 'QUEUED',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
}

export enum NrDeliveryJobStatus {
    CANCELLED = 'CANCELLED',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
}

export type NrDeliveryJobsFilterInput = {
    contributorId?: InputMaybe<Scalars['ID']['input']>;
    status?: InputMaybe<NrDeliveryJobStatus>;
};

export type NrDeliveryJobsInputFilters = {
    cmoId?: InputMaybe<Scalars['ID']['input']>;
    contributorId?: InputMaybe<Scalars['ID']['input']>;
    orderId?: InputMaybe<Scalars['ID']['input']>;
    orderStatus?: InputMaybe<Array<NrDeliveryOrderStatus>>;
    status?: InputMaybe<NrDeliveryJobStatus>;
};

export enum NrDeliveryJobsOrderBy {
    ID = 'ID',
    LAST_UPDATED = 'LAST_UPDATED',
}

export type NrDeliveryOrderInput = {
    cmoId: Scalars['ID']['input'];
    nrContributorUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
    reDeliver: Scalars['Boolean']['input'];
};

export enum NrDeliveryOrderStatus {
    ERROR = 'ERROR',
    PROCESSING_JOBS = 'PROCESSING_JOBS',
    QUEUED = 'QUEUED',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
}

export type NrDeliveryOrdersFilterInput = {
    cmoId?: InputMaybe<Scalars['ID']['input']>;
    orderId?: InputMaybe<Scalars['ID']['input']>;
    reDeliver?: InputMaybe<Scalars['Int']['input']>;
    status?: InputMaybe<NrDeliveryOrderStatus>;
};

export enum NrDeliveryOrdersOrderBy {
    CREATED_AT = 'CREATED_AT',
    ID = 'ID',
    LAST_UPDATED = 'LAST_UPDATED',
}

export type NrDeliveryQueueItemInput = {
    cmoId: Scalars['ID']['input'];
    contributionId: Scalars['ID']['input'];
    contributorId: Scalars['ID']['input'];
};

export type NrDeliveryQueueItemsFilterInput = {
    cmoId?: InputMaybe<Scalars['ID']['input']>;
    contributionUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
    contributorUuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum NrDeliveryQueueOrderBy {
    CREATED_AT = 'CREATED_AT',
    LAST_UPDATED = 'LAST_UPDATED',
}

export type NrOwnershipTrackFilterInput = {
    isrc?: InputMaybe<Scalars['String']['input']>;
    soundRecordingUuid?: InputMaybe<Scalars['ID']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
    vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum NrOwnershipTrackOrderByField {
    CREATED_AT = 'CREATED_AT',
    END_DATE = 'END_DATE',
    SALES_START_DATE = 'SALES_START_DATE',
    START_DATE = 'START_DATE',
}

export enum NrParticipantsOrderBy {
    ID = 'ID',
    NAME = 'NAME',
}

/**
 * Filters against the value of a nullable int. If multiple filters are provided,
 * they are logically AND-ed.
 *
 * E.g. `{ lt: 10, gte: 5 }` represents the range `[5, 10)`
 */
export type NullableIntFilterInput = {
    /**
     * Checks that the value is exactly equal to the value provided.
     *
     * This field may be `null` and will match only `null` values in this case.
     */
    eq?: InputMaybe<Scalars['Int']['input']>;
    /**
     * Checks that the value is strictly greater than the provided number.
     *
     * This field may not be `null`. Either supply a number or omit the field.
     */
    gt?: InputMaybe<Scalars['Int']['input']>;
    /**
     * Checks that the value is greater than or equal to the provided number.
     *
     * This field may not be `null`. Either supply a number or omit the field.
     */
    gte?: InputMaybe<Scalars['Int']['input']>;
    /**
     * Checks that the value is strictly less than the provided number.
     *
     * This field may not be `null`. Either supply a number or omit the field.
     */
    lt?: InputMaybe<Scalars['Int']['input']>;
    /**
     * Checks that the value is less than or equal to the provided number.
     *
     * This field may not be `null`. Either supply a number or omit the field.
     */
    lte?: InputMaybe<Scalars['Int']['input']>;
    /**
     * Checks that the value is not equal to the value provided.
     *
     * This field may be `null` and will match non-`null` values in this case.
     */
    neq?: InputMaybe<Scalars['Int']['input']>;
};

export type NumberRangeInput = {
    from?: InputMaybe<Scalars['Long']['input']>;
    to?: InputMaybe<Scalars['Long']['input']>;
};

export enum OperatingSystem {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}

/** OrchAdminUserFilter describes ability to filter results during searching OA users by isAccountManager, isProductManager, isQBLabelManager. */
export type OrchAdminUserFilter = {
    isAccountManager?: InputMaybe<Scalars['Boolean']['input']>;
    isProductManager?: InputMaybe<Scalars['Boolean']['input']>;
    isQBLabelManager?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderByParam = {
    dir: SortOrders;
    field: Scalars['String']['input'];
};

export enum OrderDir {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum OwnerCategory {
    CURATED = 'CURATED',
    EDITORIAL = 'EDITORIAL',
    USER_GENERATED = 'USER_GENERATED',
}

export type OwnershipNrContributionInput = {
    contributorId: Scalars['ID']['input'];
    featured: FeaturedInput;
    instruments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    isrc: Scalars['String']['input'];
};

export type OwnershipNrDeliveryContentSoundRecordings = {
    orderId: Scalars['ID']['input'];
    soundRecordings: Array<Scalars['String']['input']>;
};

export type OwnershipNrDeliveryJobInput = {
    jobs: Array<OwnershipNrDeliveryJobInputItem>;
    orderId: Scalars['ID']['input'];
};

export type OwnershipNrDeliveryJobInputItem = {
    entityName: Scalars['String']['input'];
    instanceCount: Scalars['Int']['input'];
    jobFileUrl: Scalars['String']['input'];
    type: OwnershipNrDeliveryJobMediaType;
};

export enum OwnershipNrDeliveryJobMediaType {
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
}

export type OwnershipNrDeliveryJobSoundRecordings = {
    jobId: Scalars['ID']['input'];
    soundRecordingsWithTracks: Array<SoundRecordingWithTracks>;
};

export enum OwnershipNrDeliveryJobStatus {
    CANCELLED = 'CANCELLED',
    DELIVERED = 'DELIVERED',
    READY = 'READY',
}

export type OwnershipNrDeliveryJobStatusInput = {
    jobIds: Array<Scalars['ID']['input']>;
    status: OwnershipNrDeliveryJobStatus;
};

export type OwnershipNrDeliveryJobsInputFilters = {
    cmmId?: InputMaybe<Scalars['Int']['input']>;
    entityName?: InputMaybe<Scalars['String']['input']>;
    orderId?: InputMaybe<Scalars['Int']['input']>;
    status?: InputMaybe<OwnershipNrDeliveryJobStatus>;
    territory?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<OwnershipNrDeliveryJobMediaType>;
};

export enum OwnershipNrDeliveryJobsOrderBy {
    ID = 'ID',
    LAST_UPDATED = 'LAST_UPDATED',
    ORDER_CREATED_AT = 'ORDER_CREATED_AT',
}

export type OwnershipNrDeliveryOrderInput = {
    cmmId: Scalars['ID']['input'];
    territory: Scalars['String']['input'];
};

export type OwnershipNrDeliveryOrderMessage = {
    id: Scalars['ID']['input'];
    message: Scalars['String']['input'];
};

export enum OwnershipNrDeliveryOrderStatus {
    CANCELLED = 'CANCELLED',
    DELIVERED = 'DELIVERED',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    QUEUED = 'QUEUED',
    READY = 'READY',
}

export type OwnershipNrDeliveryOrderStatusInput = {
    ids: Array<Scalars['ID']['input']>;
    status: OwnershipNrDeliveryOrderStatus;
};

export type OwnershipNrDeliveryOrderSummaryInput = {
    outputFile?: InputMaybe<Scalars['String']['input']>;
    outputFileCount: Scalars['Int']['input'];
};

export type OwnershipNrDeliveryOrderSummaryUpdateInput = {
    id: Scalars['ID']['input'];
    summary: OwnershipNrDeliveryOrderSummaryInput;
};

export type OwnershipNrDeliveryOrdersInputFilters = {
    cmmId?: InputMaybe<Scalars['ID']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    status?: InputMaybe<OwnershipNrDeliveryOrderStatus>;
    territory?: InputMaybe<Scalars['String']['input']>;
};

export enum OwnershipNrDeliveryOrdersOrderBy {
    CREATED_AT = 'CREATED_AT',
    ID = 'ID',
    LAST_UPDATED = 'LAST_UPDATED',
}

export enum OwnershipNrProductContentType {
    DIGITAL = 'DIGITAL',
    PHYSICAL = 'PHYSICAL',
}

export enum OwnershipNrProductFormat {
    ALBUM = 'ALBUM',
    CLIP = 'CLIP',
    EP = 'EP',
    LONG_FORM = 'LONG_FORM',
    MEDIUM_FORM = 'MEDIUM_FORM',
    SHORT_FORM = 'SHORT_FORM',
    SINGLE = 'SINGLE',
}

export enum OwnershipNrProductSubType {
    BEHIND_THE_SCENES = 'BEHIND_THE_SCENES',
    BLU_RAY = 'BLU_RAY',
    CASSETTE = 'CASSETTE',
    CD = 'CD',
    DVD = 'DVD',
    LIVE_PERFORMANCE = 'LIVE_PERFORMANCE',
    LYRIC_VIDEO = 'LYRIC_VIDEO',
    OFFICIAL_MUSIC_VIDEO = 'OFFICIAL_MUSIC_VIDEO',
    OTHER = 'OTHER',
    PROMO = 'PROMO',
    PSEUDO_VIDEO = 'PSEUDO_VIDEO',
    VHS = 'VHS',
    VINYL = 'VINYL',
}

export enum OwnershipNrRuleType {
    CARVEOUT = 'CARVEOUT',
    MONETIZE = 'MONETIZE',
}

export type OwnershipNrRulesFilterInput = {
    expandWorld?: InputMaybe<Scalars['Boolean']['input']>;
    ruleType?: InputMaybe<OwnershipNrRuleType>;
    territory?: InputMaybe<Scalars['String']['input']>;
    vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum OwnershipNrServicesOrderByField {
    ID = 'ID',
    NAME = 'NAME',
}

export type OwnershipNrSoundRecordingFilterInput = {
    account?: InputMaybe<Scalars['Int']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    participantId?: InputMaybe<Scalars['ID']['input']>;
    recordingTitle?: InputMaybe<Scalars['String']['input']>;
    reference?: InputMaybe<Scalars['Boolean']['input']>;
    soundRecordingUuid?: InputMaybe<Scalars['ID']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
};

export enum OwnershipNrSoundRecordingOrderByField {
    LAST_MODIFIED = 'LAST_MODIFIED',
    NAME_VERSION = 'NAME_VERSION',
}

export type OwnershipNrSoundRecordingServicesInput = {
    cmmId: Scalars['ID']['input'];
    maxLastModifiedAt: Scalars['DateTime']['input'];
    minLastModifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
    territory: Scalars['String']['input'];
};

export type OwnershipNrSoundRecordingServicesInputV2 = {
    cmmId: Scalars['ID']['input'];
    maxLastModifiedAt: Scalars['DateTime']['input'];
    minLastModifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
    territory: Scalars['String']['input'];
};

export type OwnershipNrTrackInput = {
    associatedAudioIsrc?: InputMaybe<Scalars['String']['input']>;
    discNumber?: InputMaybe<Scalars['Int']['input']>;
    externalId?: InputMaybe<Scalars['String']['input']>;
    isrc: Scalars['String']['input'];
    notes?: InputMaybe<Scalars['String']['input']>;
    recordingType: OwnershipNrTrackRecordingType;
    submittedByVendorId: Scalars['Int']['input'];
    title: Scalars['String']['input'];
    trackNumber?: InputMaybe<Scalars['Int']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
};

export enum OwnershipNrTrackRecordingType {
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
}

export type OwnershipNrTrackUpdateReferenceInput = {
    id: Scalars['ID']['input'];
    soundRecordingUuid: Scalars['ID']['input'];
};

export enum OwnershipParticipantType {
    HAS_FEATURED_ARTIST = 'HAS_FEATURED_ARTIST',
    HAS_PRIMARY_ARTIST = 'HAS_PRIMARY_ARTIST',
}

export enum OwnershipServiceFormat {
    CSV = 'CSV',
    DDEX_RDRN_V_1_4 = 'DDEX_RDRN_V_1_4',
    XLSX = 'XLSX',
}

/**
 * PPAuthDecision represents the effect of an authorization policy.
 *
 * **Only use this type if your application is on-boarded to PP.**
 */
export enum PpAuthDecision {
    ALLOW = 'ALLOW',
    DENY = 'DENY',
}

/**
 * PPResourceTypeActionInput represents a pair of resource type and action in the PP system.
 *
 * **Only use this type if your application is on-boarded to PP.**
 */
export type PpResourceTypeActionInput = {
    action: Scalars['PPAction']['input'];
    resourceType: Scalars['PPResourceType']['input'];
};

export type PpRoleInput = {
    role: Scalars['String']['input'];
};

export enum ParentalAdvisory {
    CLEAN_VERSION = 'CLEAN_VERSION',
    NO = 'NO',
    YES = 'YES',
}

export type ParticipantIncorrectProfileInput = {
    currentArtistName?: InputMaybe<Scalars['String']['input']>;
    message: Scalars['String']['input'];
    newArtistName?: InputMaybe<Scalars['String']['input']>;
    participantId: Scalars['ID']['input'];
    participantName?: InputMaybe<Scalars['String']['input']>;
    productId?: InputMaybe<Scalars['ID']['input']>;
    productName?: InputMaybe<Scalars['String']['input']>;
    subaccountId: Scalars['ID']['input'];
    upc?: InputMaybe<Scalars['String']['input']>;
    vendorId: Scalars['ID']['input'];
};

export enum ParticipantNodeRelationship {
    PRODUCT = 'PRODUCT',
    SOUND_RECORDING = 'SOUND_RECORDING',
}

export enum ParticipantServiceType {
    APPLE_MUSIC = 'APPLE_MUSIC',
    SPOTIFY = 'SPOTIFY',
}

export type ParticipantUpdateInput = {
    appleMusicId?: InputMaybe<Scalars['String']['input']>;
    collaboratorId?: InputMaybe<Scalars['ID']['input']>;
    id: Scalars['ID']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    spotifyId?: InputMaybe<Scalars['String']['input']>;
    subaccountId?: InputMaybe<Scalars['ID']['input']>;
    vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type ParticipationLocalization = {
    languageId: Scalars['Int']['input'];
    name: Scalars['String']['input'];
};

export type PayeeAddressInput = {
    address1: Scalars['String']['input'];
    address2?: InputMaybe<Scalars['String']['input']>;
    city: Scalars['String']['input'];
    countryCode: Scalars['String']['input'];
    province?: InputMaybe<Scalars['String']['input']>;
    zip: Scalars['String']['input'];
};

export type PayeeBankFieldDetailsItemInput = {
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type PayeeCompanyInput = {
    legalType: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type PayeeContactInput = {
    dateOfBirth: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
};

export type PayeePayoutMethodInput = {
    bankAccountType: Scalars['String']['input'];
    bankFieldDetails: Array<PayeeBankFieldDetailsItemInput>;
    country: Scalars['String']['input'];
    currency: Scalars['String']['input'];
};

export type PayeeRegistrationInput = {
    accountPayeeId: Scalars['ID']['input'];
    address: PayeeAddressInput;
    company?: InputMaybe<PayeeCompanyInput>;
    contact?: InputMaybe<PayeeContactInput>;
    payoutMethod: PayeePayoutMethodInput;
    type: Scalars['String']['input'];
};

export enum PaymentType {
    PAYONEER = 'PAYONEER',
    PAYONEER_WHITELABEL = 'PAYONEER_WHITELABEL',
    SAP = 'SAP',
}

export enum PayoneerAccountStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING',
}

export enum PhysicalDeliveryOrderBy {
    CREATED_AT = 'CREATED_AT',
    LAST_UPDATED = 'LAST_UPDATED',
}

export enum PhysicalDeliveryOrderStatus {
    CANCELLED = 'CANCELLED',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
}

export enum PhysicalDeliveryType {
    NEW = 'NEW',
    TAKEDOWN = 'TAKEDOWN',
    UPDATE = 'UPDATE',
}

export enum PlatformChartStreamsPeriod {
    _1_DAY = '_1_DAY',
}

export enum PlatformName {
    AMAZON = 'AMAZON',
    APPLEMUSIC = 'APPLEMUSIC',
    DEEZER = 'DEEZER',
    ITUNES = 'ITUNES',
    LINEMUSIC = 'LINEMUSIC',
    RECOCHOKU = 'RECOCHOKU',
    SHAZAM = 'SHAZAM',
    SOUNDCLOUD = 'SOUNDCLOUD',
    SPOTIFY = 'SPOTIFY',
    TIKTOK = 'TIKTOK',
    YOUTUBE = 'YOUTUBE',
}

export enum PlaylistPlacementsOrderBy {
    LAST_ADDED_ON_DATE = 'LAST_ADDED_ON_DATE',
    PLAYLIST_FOLLOWER_COUNT = 'PLAYLIST_FOLLOWER_COUNT',
    STREAMS_LAST_1_DAY = 'STREAMS_LAST_1_DAY',
    STREAMS_LAST_7_DAYS = 'STREAMS_LAST_7_DAYS',
}

export enum PlaylistPlacementsOrderDir {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type PlaylistPlacementsOrderInput = {
    by: PlaylistPlacementsOrderBy;
    dir: PlaylistPlacementsOrderDir;
};

export enum PlaylistType {
    ALGORITHMIC = 'ALGORITHMIC',
    CHART = 'CHART',
    CURATED = 'CURATED',
    EDITORIAL = 'EDITORIAL',
    HOT_HITS = 'HOT_HITS',
    NEW_MUSIC_FRIDAY = 'NEW_MUSIC_FRIDAY',
    PERSONALIZED = 'PERSONALIZED',
    RADIO = 'RADIO',
    STATION = 'STATION',
    USER_GENERATED = 'USER_GENERATED',
}

export enum PodcastAnalyticsDateRange {
    ALL_TIME = 'ALL_TIME',
    CUSTOM = 'CUSTOM',
    FIRST_7_DAYS = 'FIRST_7_DAYS',
    FIRST_28_DAYS = 'FIRST_28_DAYS',
    FIRST_YEAR = 'FIRST_YEAR',
    PAST_7_DAYS = 'PAST_7_DAYS',
    PAST_28_DAYS = 'PAST_28_DAYS',
    PAST_YEAR = 'PAST_YEAR',
}

export enum PodcastFeedType {
    APPLE_SUBSCRIPTION = 'APPLE_SUBSCRIPTION',
    PRIVATE_RSS = 'PRIVATE_RSS',
    PUBLIC_RSS = 'PUBLIC_RSS',
    YOUTUBE = 'YOUTUBE',
}

export type PodcastParticipantInput = {
    name: Scalars['String']['input'];
    participantId: Scalars['Int']['input'];
    role: Scalars['String']['input'];
};

export type PodcastSeasonInput = {
    externalId?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    name: Scalars['String']['input'];
    number: Scalars['Int']['input'];
};

export enum PotGraphType {
    AREA = 'AREA',
    BAR = 'BAR',
    COLUMN = 'COLUMN',
    LINE = 'LINE',
}

export enum PreOrderType {
    IMMEDIATE = 'IMMEDIATE',
    NO_PREORDER = 'NO_PREORDER',
    SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export enum PricingFamily {
    FILM = 'FILM',
    MUSIC_ALBUM = 'MUSIC_ALBUM',
    MUSIC_TRACK = 'MUSIC_TRACK',
    PHYSICAL_MUSIC = 'PHYSICAL_MUSIC',
    VIDEO = 'VIDEO',
}

export enum Priority {
    HIGH = 'HIGH',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
}

export enum PriorityType {
    A = 'A',
    B = 'B',
}

export enum ProductArtistRole {
    ARRANGER = 'ARRANGER',
    CAST = 'CAST',
    CHORUS = 'CHORUS',
    COMPOSER = 'COMPOSER',
    CONDUCTOR = 'CONDUCTOR',
    ENGINEER = 'ENGINEER',
    ENSEMBLE = 'ENSEMBLE',
    FEATURED_ARTIST = 'FEATURED_ARTIST',
    FEATURE_TO_PRIMARY = 'FEATURE_TO_PRIMARY',
    LYRICIST = 'LYRICIST',
    ORCHESTRA = 'ORCHESTRA',
    PRIMARY_ARTIST = 'PRIMARY_ARTIST',
    PRODUCER = 'PRODUCER',
    REMIXER = 'REMIXER',
    SINGER = 'SINGER',
    SOLOIST = 'SOLOIST',
}

export enum ProductConfiguration {
    DIGITAL_AUDIO = 'DIGITAL_AUDIO',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    DigitalAudio = 'DigitalAudio',
    MOVIE = 'MOVIE',
    MUSIC_VIDEO = 'MUSIC_VIDEO',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    Movie = 'Movie',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    MusicVideo = 'MusicVideo',
    OTHER = 'OTHER',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    Other = 'Other',
    PHYSICAL_AUDIO = 'PHYSICAL_AUDIO',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    PhysicalAudio = 'PhysicalAudio',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    TVShow = 'TVShow',
    TV_SHOW = 'TV_SHOW',
}

export type ProductCorrectionDetailInput = {
    corrections: Array<CorrectionDetailInput>;
    productId: Scalars['Int']['input'];
    releaseCorrectionId: Scalars['Int']['input'];
};

export type ProductMarketingPriorityInput = {
    priorityId?: InputMaybe<Scalars['ID']['input']>;
    priorityType: PriorityType;
    territoryId: Scalars['ID']['input'];
};

export enum ProductOrderBy {
    RELEASE_ID = 'RELEASE_ID',
    SALE_START_DATE = 'SALE_START_DATE',
}

export type ProductParticipationFilterInput = {
    /**
     * Filter on the participatedAs field. This is the raw database field which has a bunch of bad data in it.
     * See `LabelTrackParticipation.participatedAs` for more information
     */
    participated_as_in?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductParticipationInput = {
    labelParticipantUuid: Scalars['ID']['input'];
    /** This field requires product level localizations to be set */
    localizations?: InputMaybe<Array<ParticipationLocalization>>;
    role: ProductArtistRole;
};

export type ProductReleaseScheduleInput = {
    staggered?: InputMaybe<Array<StaggeredReleaseScheduleInput>>;
    timed?: InputMaybe<Array<TimedReleaseScheduleInput>>;
    unsupportedTimed?: InputMaybe<Array<UnsupportedTimedReleaseScheduleInput>>;
};

export enum ProductSummaryType {
    COUNTRY = 'COUNTRY',
    SOS = 'SOS',
    SOUND_RECORDING = 'SOUND_RECORDING',
    STORE = 'STORE',
    TOTAL = 'TOTAL',
}

export type ProductTerritoryCarveoutsInput = {
    countryCodes?: InputMaybe<Array<Scalars['String']['input']>>;
    productId: Scalars['Int']['input'];
    upc: Scalars['String']['input'];
};

export type ProductTerritoryDateInput = {
    countryCodes: Array<Scalars['String']['input']>;
    preorderDate?: InputMaybe<Scalars['String']['input']>;
    releaseDate?: InputMaybe<Scalars['String']['input']>;
    saleStartDate?: InputMaybe<Scalars['String']['input']>;
    vodStartDate?: InputMaybe<Scalars['String']['input']>;
};

export type ProductTerritoryDatesInput = {
    productId: Scalars['Int']['input'];
    territoryDates?: InputMaybe<Array<ProductTerritoryDateInput>>;
};

export enum ProductTimeseriesType {
    PRODUCT_DOWNLOADS = 'PRODUCT_DOWNLOADS',
    PRODUCT_DOWNLOADS_BY_COUNTRY = 'PRODUCT_DOWNLOADS_BY_COUNTRY',
    PRODUCT_DOWNLOADS_BY_STORE = 'PRODUCT_DOWNLOADS_BY_STORE',
    PRODUCT_STREAMS = 'PRODUCT_STREAMS',
    PRODUCT_STREAMS_BY_COUNTRY = 'PRODUCT_STREAMS_BY_COUNTRY',
    PRODUCT_STREAMS_BY_SOS = 'PRODUCT_STREAMS_BY_SOS',
    PRODUCT_STREAMS_BY_STORE = 'PRODUCT_STREAMS_BY_STORE',
    PRODUCT_STREAMS_BY_TRACK = 'PRODUCT_STREAMS_BY_TRACK',
    TRACK_DOWNLOADS = 'TRACK_DOWNLOADS',
    TRACK_DOWNLOADS_BY_COUNTRY = 'TRACK_DOWNLOADS_BY_COUNTRY',
    TRACK_DOWNLOADS_BY_STORE = 'TRACK_DOWNLOADS_BY_STORE',
    TRACK_DOWNLOADS_BY_TRACK = 'TRACK_DOWNLOADS_BY_TRACK',
}

export enum ProductValidationErrorCode {
    ACCOUNT_BLOCKLIST = 'ACCOUNT_BLOCKLIST',
    ARTWORK = 'ARTWORK',
    COMPOSER_REQUIRED = 'COMPOSER_REQUIRED',
    C_LINE_REQUIRED = 'C_LINE_REQUIRED',
    FORMAT_REQUIRED = 'FORMAT_REQUIRED',
    GENRE_ID_REQUIRED = 'GENRE_ID_REQUIRED',
    IMPRINT_REQUIRED = 'IMPRINT_REQUIRED',
    META_LANGUAGE_INVALID = 'META_LANGUAGE_INVALID',
    META_LANGUAGE_REQUIRED = 'META_LANGUAGE_REQUIRED',
    PREORDER_DATE_REQUIRED = 'PREORDER_DATE_REQUIRED',
    PRIMARY_ARTIST_REQUIRED = 'PRIMARY_ARTIST_REQUIRED',
    PRODUCT_CODE_BLANK = 'PRODUCT_CODE_BLANK',
    PRODUCT_CODE_PATTERN = 'PRODUCT_CODE_PATTERN',
    PRODUCT_CODE_REQUIRED = 'PRODUCT_CODE_REQUIRED',
    PRODUCT_CODE_TYPE = 'PRODUCT_CODE_TYPE',
    PRODUCT_NAME_REQUIRED = 'PRODUCT_NAME_REQUIRED',
    RELEASE_DATE_REQUIRED = 'RELEASE_DATE_REQUIRED',
    SALE_START_DATE_REQUIRED = 'SALE_START_DATE_REQUIRED',
    ST_VARIOUS_ARTISTS_RES = 'ST_VARIOUS_ARTISTS_RES',
    SUBGENRE_ID_REQUIRED = 'SUBGENRE_ID_REQUIRED',
}

export enum ProductValidationWarningCode {
    AWAL_ARTIST_APPLICATION_MISMATCH = 'AWAL_ARTIST_APPLICATION_MISMATCH',
    BLOCKLIST = 'BLOCKLIST',
    PRIMARY_ARTIST_MISMATCH = 'PRIMARY_ARTIST_MISMATCH',
}

export enum ProfileType {
    ABACUS = 'ABACUS',
    ACCOUNT360 = 'ACCOUNT360',
    ARTIST = 'ARTIST',
    AUDIENCE = 'AUDIENCE',
    COLLABORATORS = 'COLLABORATORS',
    CONTENT = 'CONTENT',
    DISTRIBUTION = 'DISTRIBUTION',
    DOCUMENTS = 'DOCUMENTS',
    INSIGHTS = 'INSIGHTS',
    LABEL = 'LABEL',
    MONEYHUB = 'MONEYHUB',
    ORCHADMIN = 'ORCHADMIN',
    PODCAST = 'PODCAST',
    PUBLISHING = 'PUBLISHING',
    SETTINGS = 'SETTINGS',
    SONGWHIP = 'SONGWHIP',
}

export type ProjectMarketingHighlightsInput = {
    data?: InputMaybe<Array<MarketingHighlightsInput>>;
    projectId: Scalars['Int']['input'];
};

export type ProjectMarketingPriorityInput = {
    priorityId?: InputMaybe<Scalars['ID']['input']>;
    priorityType: PriorityType;
    territoryId: Scalars['ID']['input'];
};

export type PublishingAgreementsFilter = {
    label?: InputMaybe<LabelUuid>;
    legalNameSearch?: InputMaybe<Scalars['String']['input']>;
};

export type PublishingCompositionAgreementInput = {
    agreementId: Scalars['String']['input'];
    containsPublicDomain?: InputMaybe<Scalars['Boolean']['input']>;
    containsSample?: InputMaybe<Scalars['Boolean']['input']>;
    hasArrangerContribution?: Scalars['Boolean']['input'];
    hasLyricsContribution: Scalars['Boolean']['input'];
    hasMusicContribution: Scalars['Boolean']['input'];
    split: Scalars['String']['input'];
};

export type PublishingCompositionsFilter = {
    brand?: InputMaybe<CompanyBrandName>;
    delivered?: InputMaybe<Scalars['Boolean']['input']>;
    draft?: InputMaybe<Scalars['Boolean']['input']>;
    label?: InputMaybe<LabelUuid>;
    pubSongID?: InputMaybe<Scalars['Int']['input']>;
    songWriters?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    songWritersUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
    titleAndSongIdSearch?: InputMaybe<Scalars['Boolean']['input']>;
    titleSearch?: InputMaybe<Scalars['String']['input']>;
    titleSearchExact?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublishingPublishersFilter = {
    ipi?: InputMaybe<Scalars['String']['input']>;
    label?: InputMaybe<LabelUuid>;
    nameOrIpiSearch?: InputMaybe<Scalars['String']['input']>;
    nameSearch?: InputMaybe<Scalars['String']['input']>;
};

export enum PublishingSongWriterCompositionsOrderByField {
    LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
    TITLE = 'TITLE',
}

export enum PublishingSongWriterOrderByField {
    LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
    LEGAL_NAME = 'LEGAL_NAME',
}

export type PublishingSongWriterPublishersInput = {
    controlled: Scalars['Boolean']['input'];
    excludedTerritories: Array<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    ipi?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    pro?: InputMaybe<Scalars['String']['input']>;
    pubPublisherID?: InputMaybe<Scalars['Int']['input']>;
};

export type PublishingSongWritersFilter = {
    brand?: InputMaybe<CompanyBrandName>;
    ipi?: InputMaybe<Scalars['String']['input']>;
    label?: InputMaybe<LabelUuid>;
    legalNameAndIpiSearch?: InputMaybe<Scalars['Boolean']['input']>;
    legalNameSearch?: InputMaybe<Scalars['String']['input']>;
    professionallyKnownAs?: InputMaybe<Scalars['String']['input']>;
    pubWriterIDs?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum QueryType {
    exact = 'exact',
}

export type RecordChannelVisitInput = {
    channelId: Scalars['String']['input'];
};

/** RecordCountryFilterInput manages available countries and max records for them. */
export type RecordCountryFilterInput = {
    countries: Array<Scalars['String']['input']>;
    maxRecords: Scalars['Int']['input'];
};

export type RecordGlobalParticipantVisitInput = {
    globalParticipantId: Scalars['ID']['input'];
};

export type RecordGlobalSoundRecordingVisitInput = {
    isrc: Scalars['String']['input'];
};

export type RecordProductVisitInput = {
    /** This field is required if `upc` is not provided. */
    productId?: InputMaybe<Scalars['Int']['input']>;
    /** This field is required if `productId` is not provided. */
    upc?: InputMaybe<Scalars['String']['input']>;
};

export type RecordVideoVisitInput = {
    videoId: Scalars['String']['input'];
};

export type RegisterUserDeviceInput = {
    deviceId?: InputMaybe<Scalars['String']['input']>;
    localization?: InputMaybe<Scalars['String']['input']>;
    operatingSystem: OperatingSystem;
    pushToken: Scalars['String']['input'];
};

export type ReplaceCollaboratorSplitsInput = {
    dpSplitsAgreed?: InputMaybe<Scalars['Boolean']['input']>;
    tracks: Array<ReplaceCollaboratorSplitsTrack>;
    vendorId: Scalars['Int']['input'];
};

export type ReplaceCollaboratorSplitsTrack = {
    splits: Array<ReplaceCollaboratorSplitsTrackSplit>;
    tuid: Scalars['String']['input'];
};

export type ReplaceCollaboratorSplitsTrackSplit = {
    collaboratorId: Scalars['Int']['input'];
    rateType?: InputMaybe<CollaboratorSplitRateType>;
    splitRate: Scalars['Float']['input'];
    splitTypeId: Scalars['Int']['input'];
};

export type ReplicateSingleEpisdeInput = {
    copyAdLocationsPodcastIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    originalEpisodeId: Scalars['Int']['input'];
    podcastIds: Array<Scalars['Int']['input']>;
};

export enum ReportDimensionColumnType {
    FINANCIAL_DETAIL = 'FINANCIAL_DETAIL',
    STATEMENT_PERIOD = 'STATEMENT_PERIOD',
    TERRITORY = 'TERRITORY',
}

export enum ReportDimensionRowType {
    COLLECTION_SOCIETY = 'COLLECTION_SOCIETY',
    PRODUCT = 'PRODUCT',
    PRODUCT_ARTIST = 'PRODUCT_ARTIST',
    RECORDING = 'RECORDING',
    SERVICE = 'SERVICE',
    STATEMENT_PERIOD = 'STATEMENT_PERIOD',
    TERRITORY = 'TERRITORY',
    TRANSACTION_TYPE = 'TRANSACTION_TYPE',
}

export enum ReportErrorKey {
    NO_COMPOSITIONS = 'NO_COMPOSITIONS',
    NO_UPDATES = 'NO_UPDATES',
    OTHER_ERROR = 'OTHER_ERROR',
}

export type ReportParticipantDataIssueInput = {
    category: Scalars['String']['input'];
    message?: InputMaybe<Scalars['String']['input']>;
    participantId: Scalars['ID']['input'];
    referrer?: InputMaybe<Scalars['String']['input']>;
};

export enum ReportRevenueType {
    DISTRIBUTION = 'DISTRIBUTION',
    NEIGHBOURING_RIGHTS = 'NEIGHBOURING_RIGHTS',
}

export type ReportSoundRecordingDataIssueInput = {
    category: Scalars['String']['input'];
    isrc: Scalars['ID']['input'];
    message?: InputMaybe<Scalars['String']['input']>;
    referrer?: InputMaybe<Scalars['String']['input']>;
};

export enum ReportStatus {
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
}

export enum ReportType {
    ADHOC_COMPOSITION = 'ADHOC_COMPOSITION',
    ADHOC_VENDOR = 'ADHOC_VENDOR',
    ADHOC_WRITER = 'ADHOC_WRITER',
    NEW_DELIVERY = 'NEW_DELIVERY',
    UPDATE_DELIVERY = 'UPDATE_DELIVERY',
}

export type RequestGlobalParticipantSocialMediaUpdatesInput = {
    id: Scalars['ID']['input'];
    links: Array<SocialMediaUpdate>;
    referrer: Scalars['String']['input'];
};

/** ResourceAccessInput describes resource access structure that should be added or updated for identity. */
export type ResourceAccessInput = {
    roles: Array<Role>;
    type: Scalars['String']['input'];
    updatedOn?: InputMaybe<Scalars['DateTime']['input']>;
    uuid: Scalars['ID']['input'];
};

export type RetrieveGenreInput = {
    genreId?: InputMaybe<Scalars['Int']['input']>;
};

export enum Role {
    ACCOUNT360 = 'ACCOUNT360',
    ACCOUNTING = 'ACCOUNTING',
    ADMINISTRATOR = 'ADMINISTRATOR',
    ADVERTISING = 'ADVERTISING',
    ANALYTICS = 'ANALYTICS',
    AUDIENCE = 'AUDIENCE',
    BULK_CREATE_DIGITAL_AUDIO = 'BULK_CREATE_DIGITAL_AUDIO',
    CATALOG = 'CATALOG',
    CREATE_ADMIN_TOOLS = 'CREATE_ADMIN_TOOLS',
    CREATE_BUNDLE = 'CREATE_BUNDLE',
    DELIVER_PHYSICAL_AUDIO = 'DELIVER_PHYSICAL_AUDIO',
    DOCUMENTS = 'DOCUMENTS',
    DOLBY_ATMOS_PACKAGER_ALPHA = 'DOLBY_ATMOS_PACKAGER_ALPHA',
    MANAGE_DIGITAL_AUDIO = 'MANAGE_DIGITAL_AUDIO',
    MANAGE_NR_DELIVERIES = 'MANAGE_NR_DELIVERIES',
    MANAGE_NR_OWNERSHIP = 'MANAGE_NR_OWNERSHIP',
    MANAGE_NR_REPERTOIRE = 'MANAGE_NR_REPERTOIRE',
    MANAGE_RIGHTS = 'MANAGE_RIGHTS',
    MARKETING = 'MARKETING',
    NETWORK_ADMIN = 'NETWORK_ADMIN',
    OWS_DELIVERY_METADATA = 'OWS_DELIVERY_METADATA',
    PAYEE_MANAGEMENT = 'PAYEE_MANAGEMENT',
    PAYMENTS = 'PAYMENTS',
    PODCAST_ADMIN = 'PODCAST_ADMIN',
    PRODUCER = 'PRODUCER',
    PUBLISHING_COMPOSITIONS_CHANGELOG = 'PUBLISHING_COMPOSITIONS_CHANGELOG',
    READ_ONLY_PRODUCER_ROLE = 'READ_ONLY_PRODUCER_ROLE',
    REVIEW_DIGITAL_AUDIO = 'REVIEW_DIGITAL_AUDIO',
    ROYALTIES = 'ROYALTIES',
    SONGWHIP = 'SONGWHIP',
    TOOLS_ARTIST_NAME_CHANGE = 'TOOLS_ARTIST_NAME_CHANGE',
    VIEW_ORCHARD_SOUND_RECORDING = 'VIEW_ORCHARD_SOUND_RECORDING',
}

export type SampleArtistInput = {
    artistName?: InputMaybe<Scalars['String']['input']>;
    artistType?: InputMaybe<SampleArtistType>;
};

export enum SampleArtistType {
    ARTIST = 'ARTIST',
    PRODUCER = 'PRODUCER',
    SONGWRITER = 'SONGWRITER',
}

export type SaveArtistsInput = {
    create?: InputMaybe<Array<InputMaybe<CreateArtistInput>>>;
};

export type SaveCollaboratorSplitsInput = {
    create?: InputMaybe<Array<CreateCollaboratorSplitInput>>;
    delete?: InputMaybe<Array<DeleteCollaboratorSplitInput>>;
    update?: InputMaybe<Array<UpdateCollaboratorSplitInput>>;
};

export type SaveTracksInput = {
    create?: InputMaybe<CreateTracksInput>;
    delete?: InputMaybe<DeleteTracksInput>;
    update?: InputMaybe<UpdateTracksBodyInput>;
    updatePosition?: InputMaybe<UpdateTracksPositionInput>;
};

export type SaveVideoSingleProductInput = {
    create?: InputMaybe<CreateVideoSingleProductInput>;
    update?: InputMaybe<UpdateVideoSingleProductInput>;
};

export type ScheduleEmailCampaignInput = {
    sendAt: Scalars['String']['input'];
    timezone: Scalars['String']['input'];
};

export type SearchFacetMultiSelectParam = {
    name: Scalars['String']['input'];
    values: Array<Scalars['String']['input']>;
};

export type SearchFacetParam = {
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type SendTestEmailInput = {
    content?: InputMaybe<Scalars['String']['input']>;
    emails: Array<Scalars['String']['input']>;
};

export type SetProductInstantGratsInput = {
    productId: Scalars['ID']['input'];
    trackGrats: Array<TrackInstantGratsInput>;
};

export type SetProductLocalizationInput = {
    deliveredVersion?: InputMaybe<Scalars['String']['input']>;
    languageId: Scalars['Int']['input'];
    productName: Scalars['String']['input'];
};

export type SetTrackLocalizationInput = {
    languageId: Scalars['Int']['input'];
    trackName: Scalars['String']['input'];
    version?: InputMaybe<Scalars['String']['input']>;
};

export type SetTrackPricingTier = {
    orchardPricingTier: Scalars['ID']['input'];
    trackId: Scalars['ID']['input'];
};

export type ShareGoogleAudienceInput = {
    adAccountId: Scalars['ID']['input'];
    audienceId: Scalars['ID']['input'];
    justification: AudienceExportJustification;
    reasonNotes?: InputMaybe<Scalars['String']['input']>;
};

export type ShareMetaAudienceInput = {
    adAccountId: Scalars['ID']['input'];
    audienceId: Scalars['ID']['input'];
    justification: AudienceExportJustification;
    reasonNotes?: InputMaybe<Scalars['String']['input']>;
};

export type ShareTiktokAudienceInput = {
    adAccountId: Scalars['ID']['input'];
    audienceId: Scalars['ID']['input'];
    justification: AudienceExportJustification;
    reasonNotes?: InputMaybe<Scalars['String']['input']>;
};

/** This type is used only for use with the `@shopify/address` npm package. */
export type ShopifyAddressInput = {
    address1: Scalars['String']['input'];
    address2: Scalars['String']['input'];
    city: Scalars['String']['input'];
    company?: InputMaybe<Scalars['String']['input']>;
    /** Country code in ISO 3166-1 alpha-3 */
    countryCode: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    province?: InputMaybe<Scalars['String']['input']>;
    zip: Scalars['String']['input'];
};

export enum SocialAuthUnlinkPermissionType {
    ALLOW_UNLINK = 'ALLOW_UNLINK',
    DENY_UNLINK = 'DENY_UNLINK',
    NOT_LINKED = 'NOT_LINKED',
}

export enum SocialContentType {
    APPLE_MUSIC = 'APPLE_MUSIC',
    IMAGE = 'IMAGE',
    SPOTIFY = 'SPOTIFY',
    VIDEO = 'VIDEO',
}

export enum SocialFansOrderBy {
    FOLLOWERS = 'FOLLOWERS',
    MENTIONS = 'MENTIONS',
    RELEVANCE = 'RELEVANCE',
}

export enum SocialFansType {
    BLOG = 'BLOG',
    EVENT = 'EVENT',
    FAN = 'FAN',
    FAN_PAGE = 'FAN_PAGE',
    RADIO = 'RADIO',
}

export type SocialMediaUpdate = {
    input: Scalars['String']['input'];
    platform: Scalars['String']['input'];
};

export enum SocialPlatform {
    DEEZER = 'DEEZER',
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    SOUNDCLOUD = 'SOUNDCLOUD',
    SPOTIFY = 'SPOTIFY',
    TIKTOK = 'TIKTOK',
    TWITTER = 'TWITTER',
    YOUTUBE = 'YOUTUBE',
}

export enum SocialPostsOrderBy {
    DATE = 'DATE',
    POPULARITY = 'POPULARITY',
}

export enum SortDir {
    ASC = 'ASC',
    DESC = 'DESC',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    asc = 'asc',
    /** @deprecated Enumerated values should be UPPER_CAMEL_CASE. */
    desc = 'desc',
}

export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum SortOrders {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type SoundRecordingWithTracks = {
    id: Scalars['String']['input'];
    tracks: Array<Scalars['String']['input']>;
};

export type SpotifyTrackInput = {
    id: Scalars['ID']['input'];
};

export type StaggeredReleaseScheduleInput = {
    deliveryStore: DeliveryStoreInput;
    saleDate: Scalars['Date']['input'];
};

export type StartBulkMetadataUploadInput = {
    bulkSessionId: Scalars['ID']['input'];
    filename: Scalars['String']['input'];
    parts?: InputMaybe<Scalars['Int']['input']>;
};

export type StartPodcastTranscriptionInput = {
    episodeId: Scalars['Int']['input'];
    language: Scalars['String']['input'];
    numSpeakers: Scalars['Int']['input'];
};

export enum StatementPeriodAdjustmentFileSortOptions {
    CREATED_AT = 'CREATED_AT',
    DATE_APPROVED = 'DATE_APPROVED',
    STATEMENT_PERIOD_ADJUSTMENT_FILE_ID = 'STATEMENT_PERIOD_ADJUSTMENT_FILE_ID',
    STATUS = 'STATUS',
}

export enum StatementPeriodAdjustmentFileStatusOptions {
    APPLIED = 'APPLIED',
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
}

export enum StatementPeriodAdjustmentFileUserActions {
    UPLOADED_FILE = 'UPLOADED_FILE',
}

export type StoreLinkInput = {
    countryCodes?: InputMaybe<Scalars['String']['input']>;
    deliveryDate?: InputMaybe<Scalars['Date']['input']>;
    forcePolling?: InputMaybe<Scalars['Int']['input']>;
    goLiveDate?: InputMaybe<Scalars['Date']['input']>;
    itunesVendorId?: InputMaybe<Scalars['String']['input']>;
    productId: Scalars['Int']['input'];
    provider?: InputMaybe<Scalars['String']['input']>;
    receivedForPollingDate?: InputMaybe<Scalars['Date']['input']>;
    salesStartDate?: InputMaybe<Scalars['Date']['input']>;
    status?: InputMaybe<Scalars['String']['input']>;
    storeId: Scalars['Int']['input'];
    storeInternalId: Scalars['String']['input'];
    storeInternalStatus?: InputMaybe<Scalars['String']['input']>;
    upc: Scalars['String']['input'];
};

export type StoreLinksInput = {
    storeLinks?: InputMaybe<Array<StoreLinkInput>>;
};

export enum StreamSourceFilter {
    ALBUM = 'ALBUM',
    ALBUMPAGE = 'ALBUMPAGE',
    ARTIST = 'ARTIST',
    ARTISTPAGE = 'ARTISTPAGE',
    CHART = 'CHART',
    COLLECTION = 'COLLECTION',
    DAILYMIX = 'DAILYMIX',
    DISCOVERWEEKLY = 'DISCOVERWEEKLY',
    DISCOVERY = 'DISCOVERY',
    EXTERNAL = 'EXTERNAL',
    LIBRARY = 'LIBRARY',
    MUSICKIT = 'MUSICKIT',
    NOWPLAYING = 'NOWPLAYING',
    OTHER = 'OTHER',
    PLAYLIST = 'PLAYLIST',
    PLAYLISTS = 'PLAYLISTS',
    PLAYQUEUE = 'PLAYQUEUE',
    RADIO = 'RADIO',
    RELEASERADAR = 'RELEASERADAR',
    SEARCH = 'SEARCH',
    SONGS = 'SONGS',
    STATION = 'STATION',
    USERPLAYLIST = 'USERPLAYLIST',
    VOICE = 'VOICE',
}

export enum StreamSources {
    ACTIVE = 'ACTIVE',
    COLLECTION = 'COLLECTION',
    PASSIVE = 'PASSIVE',
    UNKNOWN = 'UNKNOWN',
}

export enum StreamsGrowthPeriodEnum {
    _1_DAY = '_1_DAY',
    _7_DAYS = '_7_DAYS',
    _28_DAYS = '_28_DAYS',
}

export type StringRangeInput = {
    from?: InputMaybe<Scalars['String']['input']>;
    to?: InputMaybe<Scalars['String']['input']>;
};

export enum SubaccountSplitType {
    GROSS = 'GROSS',
    NET = 'NET',
}

export type SubmitProductInput = {
    productId: Scalars['ID']['input'];
    subaccountId?: InputMaybe<Scalars['ID']['input']>;
    vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SubscriptionType {
    ADSUPPORTED = 'ADSUPPORTED',
    MIDTIER = 'MIDTIER',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export type TenantProfileRolesCreateInput = {
    /**
     * roles contains the list of RoleV2 to add
     * to the identity's roles for the tenant.
     */
    rolesToAttach: Array<Scalars['ID']['input']>;
    /** Tenant's type for which roles should be added. */
    tenantType: TenantType;
    /** Tenant's UUID for which roles should be added. */
    tenantUuid: Scalars['ID']['input'];
};

export type TenantProfileRolesUpdateInput = {
    /**
     * rolesToAttach contains the list of RoleV2 to add
     * from the identity's roles on the tenant.
     */
    rolesToAttach: Array<Scalars['ID']['input']>;
    /**
     * rolesToDetach contains the list of RoleV2 to remove
     * from the identity's roles on the tenant.
     */
    rolesToDetach: Array<Scalars['ID']['input']>;
    /** Tenant's type for which roles should be added. */
    tenantType: TenantType;
    /** Tenant's UUID for which roles should be added. */
    tenantUuid: Scalars['ID']['input'];
};

export enum TenantType {
    ACCOUNT = 'ACCOUNT',
    COLLABORATOR = 'COLLABORATOR',
    COMPANY_BRAND = 'COMPANY_BRAND',
    LABEL_PARTICIPANT = 'LABEL_PARTICIPANT',
    SUBACCOUNT = 'SUBACCOUNT',
}

export type TerritoryFilterInput = {
    names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum TerritoryStandard {
    ISO_3166_1_2016 = 'ISO_3166_1_2016',
    ORCH_1_2016 = 'ORCH_1_2016',
}

export enum TikTokSoundsOrderBy {
    COMMENTS = 'COMMENTS',
    CREATED_DATE = 'CREATED_DATE',
    CREATIONS = 'CREATIONS',
    LIKES = 'LIKES',
    VIEWS = 'VIEWS',
}

export enum TikTokUsersOrderBy {
    COMMENTS = 'COMMENTS',
    CREATIONS = 'CREATIONS',
    FOLLOWERS = 'FOLLOWERS',
    LIKES = 'LIKES',
    VIEWS = 'VIEWS',
}

export enum TikTokVideosOrderBy {
    COMMENTS = 'COMMENTS',
    CREATED_DATE = 'CREATED_DATE',
    LIKES = 'LIKES',
    VIEWS = 'VIEWS',
}

export type TiktokAdAccountLabelPairInput = {
    adAccountId: Scalars['ID']['input'];
    subaccountId: Scalars['Int']['input'];
    vendorId: Scalars['Int']['input'];
};

export enum TiktokAnalyticsTimeseriesType {
    ALL = 'ALL',
    BY_CONTENT_TYPE = 'BY_CONTENT_TYPE',
    BY_COUNTRY = 'BY_COUNTRY',
}

export enum TiktokConnectionStatus {
    CONNECTED = 'CONNECTED',
    INVALID = 'INVALID',
    NOT_CONNECTED = 'NOT_CONNECTED',
    SCOPES_MISSING = 'SCOPES_MISSING',
}

export type TimedReleaseScheduleInput = {
    deliveryStore: DeliveryStoreInput;
    saleDateTime: Scalars['DateTime']['input'];
};

export type ToggleUserChartFavoriteInput = {
    category: Scalars['String']['input'];
    chartType: Scalars['String']['input'];
    country: Scalars['String']['input'];
    store: Scalars['String']['input'];
};

export type ToggleUserFavoriteInput = {
    podcastId: Scalars['Int']['input'];
};

export type TopGlobalSoundRecordingFamiliesPaginationInput = {
    limit?: Scalars['Int']['input'];
    offset?: Scalars['Int']['input'];
    orderBy?: Scalars['String']['input'];
    orderDir?: Scalars['String']['input'];
};

export type TrackInstantGratInput = {
    date: Scalars['String']['input'];
    storeId: Scalars['Int']['input'];
};

export type TrackInstantGratsInput = {
    grats: Array<TrackInstantGratInput>;
    tuid: Scalars['ID']['input'];
};

export enum TrackOfferType {
    ALBUM_DOWNLOAD_ONLY = 'ALBUM_DOWNLOAD_ONLY',
    ALBUM_DOWNLOAD_STREAM = 'ALBUM_DOWNLOAD_STREAM',
    ALBUM_TRACK_DOWNLOAD = 'ALBUM_TRACK_DOWNLOAD',
    ALL = 'ALL',
    NONE = 'NONE',
    STREAM_ONLY = 'STREAM_ONLY',
    TRACK_DOWNLOAD_ONLY = 'TRACK_DOWNLOAD_ONLY',
    TRACK_DOWNLOAD_STREAM = 'TRACK_DOWNLOAD_STREAM',
}

export type TrackParticipationInput = {
    labelParticipantUuid: Scalars['ID']['input'];
    /**
     * This field requires track level localizations to be set
     * This field does not apply to the WRITER role
     */
    localizations?: InputMaybe<Array<ParticipationLocalization>>;
    role: TrackParticipationRole;
};

export enum TrackParticipationRole {
    COMPOSER = 'COMPOSER',
    CONDUCTOR = 'CONDUCTOR',
    ENSEMBLE = 'ENSEMBLE',
    FEATURED_ARTIST = 'FEATURED_ARTIST',
    FEATURE_TO_PRIMARY = 'FEATURE_TO_PRIMARY',
    ORCHESTRA = 'ORCHESTRA',
    PERFORMER = 'PERFORMER',
    PRODUCER = 'PRODUCER',
    REMIXER = 'REMIXER',
    WRITER = 'WRITER',
}

export enum TrackType {
    MUSIC = 'MUSIC',
    VIDEO = 'VIDEO',
}

export enum TrackValidationErrorCode {
    COMBINED_WORK_MOVEMENT_MAX_LENGTH_EXCEEDED = 'COMBINED_WORK_MOVEMENT_MAX_LENGTH_EXCEEDED',
    EMPTY_ARTIST_NAME = 'EMPTY_ARTIST_NAME',
    FEATURED_ARTIST_MISMATCH = 'FEATURED_ARTIST_MISMATCH',
    GENRE_ARTIST_MISMATCH = 'GENRE_ARTIST_MISMATCH',
    INVALID_ISRC = 'INVALID_ISRC',
    INVALID_LANGUAGE = 'INVALID_LANGUAGE',
    INVALID_PERFORMER_TYPE = 'INVALID_PERFORMER_TYPE',
    INVALID_PREVIEW_START_TIME = 'INVALID_PREVIEW_START_TIME',
    INVALID_P_INFO = 'INVALID_P_INFO',
    LOCALIZED_ARTISTS_MISMATCH = 'LOCALIZED_ARTISTS_MISMATCH',
    MISSING_ASSET_STATUS = 'MISSING_ASSET_STATUS',
    MISSING_AUDIO_FILE = 'MISSING_AUDIO_FILE',
    MISSING_BIRTH_NAME = 'MISSING_BIRTH_NAME',
    MISSING_BOTH_CHINESE_LANGUAGES = 'MISSING_BOTH_CHINESE_LANGUAGES',
    MISSING_CHINESE_LOCALIZATION = 'MISSING_CHINESE_LOCALIZATION',
    MISSING_COMPOSER = 'MISSING_COMPOSER',
    MISSING_EXPLICIT = 'MISSING_EXPLICIT',
    MISSING_ISRC = 'MISSING_ISRC',
    MISSING_LANGUAGE = 'MISSING_LANGUAGE',
    MISSING_META_LANGUAGE_CODE = 'MISSING_META_LANGUAGE_CODE',
    MISSING_ORIGINAL_RIGHTS_HOLDER_COUNTRY_ID = 'MISSING_ORIGINAL_RIGHTS_HOLDER_COUNTRY_ID',
    MISSING_OWNERSHIP_RIGHTS = 'MISSING_OWNERSHIP_RIGHTS',
    MISSING_PERFORMER = 'MISSING_PERFORMER',
    MISSING_PRIMARY_PERFORMER = 'MISSING_PRIMARY_PERFORMER',
    MISSING_PRODUCERS = 'MISSING_PRODUCERS',
    MISSING_PUBLISHER_NAMES = 'MISSING_PUBLISHER_NAMES',
    MISSING_P_INFO = 'MISSING_P_INFO',
    MISSING_RECORDING_COUNTRY_ID = 'MISSING_RECORDING_COUNTRY_ID',
    MISSING_THIRD_PARTY_PUBLISHER = 'MISSING_THIRD_PARTY_PUBLISHER',
    MISSING_TRACK_NAME = 'MISSING_TRACK_NAME',
    MISSING_US_PUBLISHING_OBLIGATION = 'MISSING_US_PUBLISHING_OBLIGATION',
    MISSING_WORK = 'MISSING_WORK',
    MISSING_WRITERS = 'MISSING_WRITERS',
    MOVEMENT_MAX_LENGTH_EXCEEDED = 'MOVEMENT_MAX_LENGTH_EXCEEDED',
    TOO_MANY_LOCALIZATIONS = 'TOO_MANY_LOCALIZATIONS',
    TRACK_NAME_CANNOT_BE_LOCALIZED = 'TRACK_NAME_CANNOT_BE_LOCALIZED',
    TRACK_NAME_VERSION_ALREADY_IN_USE = 'TRACK_NAME_VERSION_ALREADY_IN_USE',
    TRACK_NAME_VERSION_COMPOSER_CLASSICAL_ALREADY_IN_USE = 'TRACK_NAME_VERSION_COMPOSER_CLASSICAL_ALREADY_IN_USE',
    WORK_MAX_LENGTH_EXCEEDED = 'WORK_MAX_LENGTH_EXCEEDED',
}

export enum TrackValidationWarningCode {
    CROSS_ACCOUNT_OSR_CONFLICT = 'CROSS_ACCOUNT_OSR_CONFLICT',
    CROSS_ISRC_OSR_MISMATCH = 'CROSS_ISRC_OSR_MISMATCH',
    CROSS_TRACK_ISRC_MISMATCH = 'CROSS_TRACK_ISRC_MISMATCH',
    EXCESSIVE_ISRC_REUSE = 'EXCESSIVE_ISRC_REUSE',
    POTENTIAL_AUDIO_INFRINGEMENT = 'POTENTIAL_AUDIO_INFRINGEMENT',
}

/**
 * The transaction type groupings differ between apps.
 * For example, `contract_admin` is used by Abacus. The `TransactionTypeGroups` and their child `TransactionTypes` are not the same as those used by `workstation`.
 */
export enum TransactionTypeGroupAdmin {
    CONTRACT_ADMIN = 'CONTRACT_ADMIN',
    /** Used for calculating mechanical deductions. */
    CONTRACT_MECHANICAL_ADMIN = 'CONTRACT_MECHANICAL_ADMIN',
    PRESENTATIONAL = 'PRESENTATIONAL',
    TAX_ADMIN = 'TAX_ADMIN',
    WORKSTATION = 'WORKSTATION',
}

export type TranscriptInput = {
    transcript: Array<TranscriptSectionInput>;
};

export type TranscriptSectionInput = {
    items: Array<TranscriptWordBreakDownInput>;
    speakerLabel: Scalars['String']['input'];
};

export type TranscriptWordBreakDownInput = {
    content: Scalars['String']['input'];
    endTime?: InputMaybe<Scalars['Float']['input']>;
    startTime?: InputMaybe<Scalars['Float']['input']>;
};

export enum TransferWiseBatchImputedStatus {
    AWAITING_FUNDS = 'AWAITING_FUNDS',
    BOUNCED_BACK = 'BOUNCED_BACK',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
}

export type TransferWiseBatchPaymentInput = {
    name?: InputMaybe<Scalars['String']['input']>;
    profileId: Scalars['Int']['input'];
    sourceCurrency: Scalars['String']['input'];
    transfers: Array<InputMaybe<TransferWiseTransferInput>>;
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type TransferWiseBatchQuoteInput = {
    quotes: Array<TransferWiseQuoteInput>;
    vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type TransferWiseFieldValidationQueryParams = {
    parameterName: Scalars['String']['input'];
    parameterValue: Scalars['String']['input'];
};

export enum TransferWiseProfileStatus {
    ERROR = 'ERROR',
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
}

export type TransferWiseQuoteInput = {
    collaboratorId?: InputMaybe<Scalars['ID']['input']>;
    paymentMetadata?: InputMaybe<Scalars['JSONObject']['input']>;
    profile: Scalars['Int']['input'];
    recipientId: Scalars['Int']['input'];
    sourceAmount?: InputMaybe<Scalars['Float']['input']>;
    sourceCurrency: Scalars['String']['input'];
    targetAmount?: InputMaybe<Scalars['Float']['input']>;
    targetCurrency: Scalars['String']['input'];
};

export type TransferWiseTransferInput = {
    collaboratorId?: InputMaybe<Scalars['ID']['input']>;
    details?: InputMaybe<Scalars['JSONObject']['input']>;
    quoteUuid: Scalars['String']['input'];
    targetAccount: Scalars['Int']['input'];
};

export type TransferWiseTransferRequirementsInput = {
    currency: Scalars['String']['input'];
    transfer: TransferWiseTransferInput;
};

export type TriggerCollaboratorsReportGenerationInput = {
    clientEmail?: InputMaybe<Scalars['String']['input']>;
    collaboratorIds: Array<Scalars['ID']['input']>;
    firstPeriodId: Scalars['Int']['input'];
    lastPeriodId: Scalars['Int']['input'];
    migratedToAbacus?: InputMaybe<Scalars['Boolean']['input']>;
    periodName: Scalars['String']['input'];
    reportRunName: Scalars['String']['input'];
    vendorId: Scalars['Int']['input'];
};

export enum TypeOfVideo {
    BEHIND_THE_SCENES = 'BEHIND_THE_SCENES',
    LIVE_PERFORMANCE = 'LIVE_PERFORMANCE',
    LYRIC_MUSIC_VIDEO = 'LYRIC_MUSIC_VIDEO',
    OFFICIAL_MUSIC_VIDEO = 'OFFICIAL_MUSIC_VIDEO',
    OTHER = 'OTHER',
    PROMO = 'PROMO',
    PSEUDO_VIDEO = 'PSEUDO_VIDEO',
}

export enum UsPublishingObligation {
    COMPOSITION = 'COMPOSITION',
    CONTROLLED_BY_YOUR_LABEL = 'CONTROLLED_BY_YOUR_LABEL',
    PUBLIC_DOMAIN = 'PUBLIC_DOMAIN',
}

export type UsTaxFormInput = {
    USTaxFormW8BENEInput?: InputMaybe<UsTaxFormW8BeneInput>;
    USTaxFormW8BENInput?: InputMaybe<UsTaxFormW8BenInput>;
    USTaxFormW8ECIInput?: InputMaybe<UsTaxFormW8EciInput>;
    USTaxFormW8IMYInput?: InputMaybe<UsTaxFormW8ImyInput>;
    USTaxFormW9Input?: InputMaybe<UsTaxFormW9Input>;
};

export type UsTaxFormW8BeneInput = {
    accountPayeeId: Scalars['ID']['input'];
    countryOfTaxResidence: Scalars['String']['input'];
    lob?: InputMaybe<Scalars['String']['input']>;
    signedDate: Scalars['String']['input'];
    taxIdCountry: Scalars['String']['input'];
    taxName: Scalars['String']['input'];
    taxTreatyClaim: Scalars['Boolean']['input'];
    tin?: InputMaybe<Scalars['String']['input']>;
    tinType?: InputMaybe<Scalars['String']['input']>;
    typeOfEntity: Scalars['String']['input'];
};

export type UsTaxFormW8BenInput = {
    accountPayeeId: Scalars['ID']['input'];
    countryOfTaxResidence: Scalars['String']['input'];
    signedDate: Scalars['String']['input'];
    taxIdCountry: Scalars['String']['input'];
    taxName: Scalars['String']['input'];
    taxTreatyClaim: Scalars['Boolean']['input'];
    tin?: InputMaybe<Scalars['String']['input']>;
    tinType?: InputMaybe<Scalars['String']['input']>;
};

export type UsTaxFormW8EciInput = {
    accountPayeeId: Scalars['ID']['input'];
    countryOfTaxResidence: Scalars['String']['input'];
    signedDate: Scalars['String']['input'];
    taxIdCountry: Scalars['String']['input'];
    taxName: Scalars['String']['input'];
    taxTreatyClaim: Scalars['Boolean']['input'];
    tin?: InputMaybe<Scalars['String']['input']>;
    tinType?: InputMaybe<Scalars['String']['input']>;
    typeOfEntity: Scalars['String']['input'];
};

export type UsTaxFormW8ImyInput = {
    accountPayeeId: Scalars['ID']['input'];
    countryOfTaxResidence: Scalars['String']['input'];
    signedDate: Scalars['String']['input'];
    taxIdCountry: Scalars['String']['input'];
    taxName: Scalars['String']['input'];
    taxTreatyClaim: Scalars['Boolean']['input'];
    tin?: InputMaybe<Scalars['String']['input']>;
    tinType?: InputMaybe<Scalars['String']['input']>;
    typeOfEntity: Scalars['String']['input'];
};

export type UsTaxFormW9Input = {
    accountPayeeId: Scalars['ID']['input'];
    countryOfTaxResidence: Scalars['String']['input'];
    signedDate?: InputMaybe<Scalars['String']['input']>;
    taxClassification: Scalars['String']['input'];
    taxIdCountry: Scalars['String']['input'];
    taxName: Scalars['String']['input'];
    tin?: InputMaybe<Scalars['String']['input']>;
    tinType?: InputMaybe<Scalars['String']['input']>;
};

export type UnregisterUserDeviceInput = {
    deviceId: Scalars['String']['input'];
};

export type UnsubmitProductInput = {
    productId: Scalars['ID']['input'];
};

export type UnsupportedTimedReleaseScheduleInput = {
    deliveryStore: DeliveryStoreInput;
    saleDateTime: Scalars['DateTime']['input'];
    timeOffset: Scalars['String']['input'];
};

export type UpdateAbacusScheduleInput = {
    conditions?: InputMaybe<AbacusScheduleConditionsInput>;
    scheduleName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdReadInput = {
    adReadUrl?: InputMaybe<Scalars['String']['input']>;
    assigneeId?: InputMaybe<Scalars['Int']['input']>;
    assigneeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    copyFilename?: InputMaybe<Scalars['String']['input']>;
    copyUrlPath?: InputMaybe<Scalars['String']['input']>;
    dueDate?: InputMaybe<Scalars['DateTime']['input']>;
    id: Scalars['ID']['input'];
    isArchivedEarly?: InputMaybe<Scalars['Boolean']['input']>;
    rejectionReason?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<AdReadStatus>;
};

export type UpdateCollaboratorInput = {
    currency?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    dpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    dpSplitsAgreed?: InputMaybe<Scalars['Boolean']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    internalId?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    performanceRights?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCollaboratorSplitInput = {
    collaboratorId?: InputMaybe<Scalars['ID']['input']>;
    id: Scalars['Int']['input'];
    rateType?: InputMaybe<CollaboratorSplitRateType>;
    splitRate?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateDeliveryStoreExceptionsInput = {
    label: Array<UpdateDeliveryStoreLabelExceptionsInput>;
    labelCountry: Array<UpdateDeliveryStoreLabelCountryExceptionsInput>;
    participant: Array<UpdateDeliveryStoreParticipantExceptionsInput>;
    participantCountry: Array<UpdateDeliveryStoreParticipantCountryExceptionsInput>;
    product: Array<UpdateDeliveryStoreProductExceptionsInput>;
};

export type UpdateDeliveryStoreInput = {
    exceptions?: InputMaybe<UpdateDeliveryStoreExceptionsInput>;
    id: Scalars['ID']['input'];
};

export type UpdateDeliveryStoreLabelCountryExceptionsInput = {
    countries: Array<Scalars['String']['input']>;
    distributionTypeIds: Array<Scalars['Int']['input']>;
};

export type UpdateDeliveryStoreLabelExceptionsInput = {
    distributionTypeIds: Array<Scalars['Int']['input']>;
    labelIds: Array<LabelIdInput>;
};

export type UpdateDeliveryStoreParticipantCountryExceptionsInput = {
    countries: Array<Scalars['String']['input']>;
    distributionTypeIds: Array<Scalars['Int']['input']>;
};

export type UpdateDeliveryStoreParticipantExceptionsInput = {
    distributionTypeIds: Array<Scalars['Int']['input']>;
    globalParticipantIds: Array<Scalars['ID']['input']>;
};

export type UpdateDeliveryStoreProductExceptionsInput = {
    distributionTypeIds: Array<Scalars['Int']['input']>;
    productIds: Array<Scalars['Int']['input']>;
};

export type UpdateEmailCampaignContentInput = {
    css?: InputMaybe<Scalars['String']['input']>;
    html?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailCampaignInput = {
    audienceId?: InputMaybe<Scalars['String']['input']>;
    emailDomainId?: InputMaybe<Scalars['String']['input']>;
    emailUsername?: InputMaybe<Scalars['String']['input']>;
    fanDataListId: FsAudienceFanDataListItemInput;
    name: Scalars['String']['input'];
    previewText?: InputMaybe<Scalars['String']['input']>;
    senderName?: InputMaybe<Scalars['String']['input']>;
    subaccountId: Scalars['Int']['input'];
    subject?: InputMaybe<Scalars['String']['input']>;
    vendorId: Scalars['Int']['input'];
};

export type UpdateEpisodeInput = {
    appleId?: InputMaybe<Scalars['String']['input']>;
    artworkFilename?: InputMaybe<Scalars['String']['input']>;
    audioFilename?: InputMaybe<Scalars['String']['input']>;
    content?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    draft: Scalars['Boolean']['input'];
    episodeNumber?: InputMaybe<Scalars['Int']['input']>;
    episodeType?: InputMaybe<Scalars['String']['input']>;
    externalId?: InputMaybe<Scalars['String']['input']>;
    feedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    id: Scalars['ID']['input'];
    isReviewed?: InputMaybe<Scalars['Boolean']['input']>;
    plannedMidRollCount?: InputMaybe<Scalars['Int']['input']>;
    plannedPostRollCount?: InputMaybe<Scalars['Int']['input']>;
    plannedPreRollCount?: InputMaybe<Scalars['Int']['input']>;
    podcastId: Scalars['Int']['input'];
    publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
    seasonId?: InputMaybe<Scalars['Int']['input']>;
    seasonNumber?: InputMaybe<Scalars['Int']['input']>;
    title: Scalars['String']['input'];
    trailerType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEpisodeReplicationStatusInput = {
    ids: Array<Scalars['ID']['input']>;
    showFamilyId: Scalars['Int']['input'];
};

export type UpdateFocusTrackInput = {
    endDate?: InputMaybe<Scalars['Date']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateInternalContractAdvanceStatusInput = {
    advanceStatus: AbacusContractAdvanceStatus;
    contractAdvanceId: Scalars['ID']['input'];
};

export type UpdateNrContributionInput = {
    countryOfPerformance?: InputMaybe<Scalars['String']['input']>;
    evidence?: InputMaybe<Scalars['String']['input']>;
    featured: FeaturedInput;
    instruments?: InputMaybe<Array<Scalars['String']['input']>>;
    performanceName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNrDeliveryClaimInput = {
    claimId: Scalars['ID']['input'];
    contributionVersion?: InputMaybe<Scalars['String']['input']>;
    message?: InputMaybe<Scalars['String']['input']>;
    status: NrDeliveryClaimStatus;
};

export type UpdateNrDeliveryJobInput = {
    errorFile?: InputMaybe<Scalars['String']['input']>;
    exclusionFile?: InputMaybe<Scalars['String']['input']>;
    jobId: Scalars['ID']['input'];
    status: NrDeliveryJobStatus;
    successFile?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNrDeliveryOrderInput = {
    orderId: Scalars['ID']['input'];
    s3OutputPath?: InputMaybe<Scalars['String']['input']>;
    status: NrDeliveryOrderStatus;
};

export type UpdateNrSoundRecordingInput = {
    albumName?: InputMaybe<Scalars['String']['input']>;
    catalogNumber?: InputMaybe<Scalars['String']['input']>;
    countriesOfRecording?: InputMaybe<
        Array<InputMaybe<Scalars['String']['input']>>
    >;
    countryOfLabel?: InputMaybe<Scalars['String']['input']>;
    countryOfMaster?: InputMaybe<Scalars['String']['input']>;
    duration?: InputMaybe<Scalars['Int']['input']>;
    featuredArtistIds: Array<Scalars['ID']['input']>;
    firstReleaseYear?: InputMaybe<Scalars['Int']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    mainArtist: Scalars['String']['input'];
    originalLabel?: InputMaybe<Scalars['String']['input']>;
    primaryArtistIds: Array<Scalars['ID']['input']>;
    priority: Priority;
    recordingTitle: Scalars['String']['input'];
    recordingType: Scalars['String']['input'];
    recordingYear?: InputMaybe<Scalars['Int']['input']>;
    totalFeaturedPerformers?: InputMaybe<Scalars['Int']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreateOwnershipNrMonetizeRulesByTrackIdInput = {
    endDate?: InputMaybe<Scalars['Date']['input']>;
    id: Scalars['ID']['input'];
    percentage?: InputMaybe<Scalars['Int']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
    territories?: InputMaybe<Array<Scalars['String']['input']>>;
    vendorId: Scalars['Int']['input'];
};

export type UpdateOrchardSoundRecordingInput = {
    id: Scalars['ID']['input'];
    primaryTrackId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOwnershipNrTrackInput = {
    countriesOfRecording: Array<Scalars['String']['input']>;
    duration: Scalars['Int']['input'];
    externalId?: InputMaybe<Scalars['String']['input']>;
    firstYearOfRecording?: InputMaybe<Scalars['Int']['input']>;
    id: Scalars['ID']['input'];
    title: Scalars['String']['input'];
    version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhysicalDeliveryOrderInput = {
    id: Scalars['ID']['input'];
    outputFile?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<PhysicalDeliveryOrderStatus>;
};

export type UpdatePodcastInput = {
    artworkFilename?: InputMaybe<Scalars['String']['input']>;
    categories?: InputMaybe<Array<Scalars['Int']['input']>>;
    channelId?: InputMaybe<Scalars['String']['input']>;
    copyright?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    explicit?: InputMaybe<Scalars['String']['input']>;
    host?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    language?: InputMaybe<Scalars['String']['input']>;
    link?: InputMaybe<Scalars['String']['input']>;
    owner?: InputMaybe<Scalars['String']['input']>;
    participants?: InputMaybe<Array<PodcastParticipantInput>>;
    seasons?: InputMaybe<Array<PodcastSeasonInput>>;
    showType?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePodcastTranscriptionInput = {
    episodeId: Scalars['Int']['input'];
    id: Scalars['ID']['input'];
    transcription: TranscriptInput;
};

export type UpdatePodcastUserInput = {
    allNetworks?: InputMaybe<Scalars['Boolean']['input']>;
    id: Scalars['ID']['input'];
    networkIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    podcastIds?: InputMaybe<Array<Scalars['Int']['input']>>;
    role?: InputMaybe<Scalars['String']['input']>;
    showFamilyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdatePodcastUserLastLoginInput = {
    lastLogin: Scalars['DateTime']['input'];
    uuid: Scalars['String']['input'];
};

export type UpdatePodcastUserSettingsInput = {
    adNotifications?: InputMaybe<Scalars['Boolean']['input']>;
    podcastNotifications?: InputMaybe<Scalars['Boolean']['input']>;
    spikeNotifications?: InputMaybe<Scalars['Boolean']['input']>;
    transcriptionNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProductInput = {
    accountId?: InputMaybe<Scalars['Int']['input']>;
    accountType?: InputMaybe<Scalars['String']['input']>;
    artistId?: InputMaybe<Scalars['Int']['input']>;
    cLine?: InputMaybe<Scalars['String']['input']>;
    channelSelection?: InputMaybe<Scalars['String']['input']>;
    deliveredVersion?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    format?: InputMaybe<Scalars['String']['input']>;
    genreId?: InputMaybe<Scalars['Int']['input']>;
    imprint?: InputMaybe<Scalars['String']['input']>;
    manufacturerUpc?: InputMaybe<Scalars['String']['input']>;
    metaLanguage?: InputMaybe<Scalars['String']['input']>;
    notForDistribution?: InputMaybe<Scalars['String']['input']>;
    pLine?: InputMaybe<Scalars['String']['input']>;
    participations?: InputMaybe<Array<ProductParticipationInput>>;
    preorderDate?: InputMaybe<Scalars['String']['input']>;
    previewable?: InputMaybe<Scalars['String']['input']>;
    /** Prefer to use `participations` instead of this field. */
    primaryArtists?: InputMaybe<Array<InputMaybe<CreateArtistInput>>>;
    productCode?: InputMaybe<Scalars['String']['input']>;
    productConfiguration?: InputMaybe<ProductConfiguration>;
    productHighlights?: InputMaybe<Scalars['String']['input']>;
    productId: Scalars['Int']['input'];
    productLocalizations?: InputMaybe<Array<SetProductLocalizationInput>>;
    productName?: InputMaybe<Scalars['String']['input']>;
    projectCode?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['String']['input']>;
    releaseDate?: InputMaybe<Scalars['String']['input']>;
    saleStartDate?: InputMaybe<Scalars['String']['input']>;
    specialInstructions?: InputMaybe<Scalars['String']['input']>;
    subgenreId?: InputMaybe<Scalars['Int']['input']>;
    vendorReleaseIdentifier?: InputMaybe<Scalars['String']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductInternalNoteInput = {
    internalNote: Scalars['String']['input'];
    productId: Scalars['Int']['input'];
};

export type UpdateProductMarketingPrioritiesInput = {
    priorities: Array<ProductMarketingPriorityInput>;
    productId: Scalars['Int']['input'];
};

export type UpdateProductPricingTier = {
    orchardPricingTier: Scalars['ID']['input'];
    pricingFamily: PricingFamily;
    productId: Scalars['ID']['input'];
    trackOverrides?: InputMaybe<Array<SetTrackPricingTier>>;
};

export type UpdateProductStoreCarveoutsInput = {
    productId: Scalars['Int']['input'];
    storeCarveouts: Array<CreateStoreCarveoutInput>;
};

export type UpdateProductTimedRelease = {
    productId: Scalars['Int']['input'];
    storeIds: Array<Scalars['Int']['input']>;
    timeOfDayProduct: Scalars['String']['input'];
    timeZone: Scalars['String']['input'];
};

export type UpdateProjectInput = {
    artistId?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    projectCode?: InputMaybe<Scalars['String']['input']>;
    projectHighlights?: InputMaybe<Scalars['String']['input']>;
    projectId: Scalars['Int']['input'];
};

export type UpdateProjectInternalNoteInput = {
    internalNote: Scalars['String']['input'];
    projectId: Scalars['Int']['input'];
};

export type UpdateProjectMarketingPrioritiesInput = {
    priorities: Array<ProjectMarketingPriorityInput>;
    projectId: Scalars['Int']['input'];
};

export type UpdatePublishingCompositionInput = {
    agreements: Array<PublishingCompositionAgreementInput>;
    alternateTitles: Array<Scalars['String']['input']>;
    associatedGlobalSoundRecordings: Array<Scalars['String']['input']>;
    associatedLabelSoundRecordings: Array<Scalars['String']['input']>;
    containsPublicDomain?: InputMaybe<Scalars['Boolean']['input']>;
    containsSample?: InputMaybe<Scalars['Boolean']['input']>;
    draft: Scalars['Boolean']['input'];
    id: Scalars['ID']['input'];
    iswc?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
};

export type UpdatePublishingPublisherInput = {
    id: Scalars['ID']['input'];
    ipi: Scalars['String']['input'];
    name: Scalars['String']['input'];
    pro: Scalars['String']['input'];
};

export type UpdatePublishingSongWriterInput = {
    id: Scalars['ID']['input'];
    ipi?: InputMaybe<Scalars['String']['input']>;
    label: LabelUuid;
    name: Scalars['String']['input'];
    pro?: InputMaybe<Scalars['String']['input']>;
    professionallyKnownAs?: InputMaybe<Array<Scalars['String']['input']>>;
    publishers: Array<PublishingSongWriterPublishersInput>;
};

export type UpdatePublishingSongWriterMetaDataInput = {
    controlled?: InputMaybe<Scalars['Boolean']['input']>;
    id: Scalars['ID']['input'];
    ipi?: InputMaybe<Scalars['String']['input']>;
    label: LabelUuid;
    name: Scalars['String']['input'];
    pro?: InputMaybe<Scalars['String']['input']>;
    professionallyKnownAs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateReleaseScheduleInput = {
    productId: Scalars['Int']['input'];
    releaseSchedule: ProductReleaseScheduleInput;
};

export type UpdateTrackBodyInput = {
    /** Prefer to use `participations` instead of this field. */
    artists?: InputMaybe<Array<InputMaybe<CreateTrackArtistInput>>>;
    explicit?: InputMaybe<Scalars['String']['input']>;
    focusTrack?: InputMaybe<UpdateFocusTrackInput>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    localizations?: InputMaybe<Array<InputMaybe<SetTrackLocalizationInput>>>;
    lyrics?: InputMaybe<Scalars['String']['input']>;
    metaLanguageCode?: InputMaybe<Scalars['String']['input']>;
    /**
     * This field is only editable by Orchard Admin profiles.
     *
     * Any other profile will be silently ignored. This behavior may change at any point in the future, and so clients operating in contexts other than OA should never include this field in their requests.
     */
    offerType?: InputMaybe<TrackOfferType>;
    originalRightsHolderCountryId?: InputMaybe<Scalars['Int']['input']>;
    ownershipRights?: InputMaybe<Scalars['String']['input']>;
    pInfo?: InputMaybe<Scalars['String']['input']>;
    participations?: InputMaybe<Array<InputMaybe<TrackParticipationInput>>>;
    performers?: InputMaybe<Array<InputMaybe<CreateTrackPerformerInput>>>;
    previewStartTime?: InputMaybe<Scalars['Int']['input']>;
    publishers?: InputMaybe<Array<InputMaybe<CreateTrackPublisherInput>>>;
    recordingCountryId?: InputMaybe<Scalars['Int']['input']>;
    samples?: InputMaybe<Array<InputMaybe<CreateSampleInput>>>;
    trackName?: InputMaybe<Scalars['String']['input']>;
    trackType?: InputMaybe<Scalars['String']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
    usPublishingObligation?: InputMaybe<UsPublishingObligation>;
    version?: InputMaybe<Scalars['String']['input']>;
    /** Prefer to use `participations` instead of this field. */
    writers?: InputMaybe<Array<InputMaybe<CreateTrackWriterInput>>>;
};

export type UpdateTrackPositionInput = {
    trackNumber: Scalars['Int']['input'];
    tuid: Scalars['Int']['input'];
    volumeNumber: Scalars['Int']['input'];
};

export type UpdateTracksBodyInput = {
    body?: InputMaybe<UpdateTrackBodyInput>;
    tracks: Array<Scalars['ID']['input']>;
};

export type UpdateTracksPositionInput = {
    productId: Scalars['Int']['input'];
    tracks: Array<UpdateTrackPositionInput>;
};

export type UpdateVideoSingleProductInput = {
    accountId?: InputMaybe<Scalars['Int']['input']>;
    associatedTrackId?: InputMaybe<Scalars['Int']['input']>;
    channelSelection?: InputMaybe<Scalars['String']['input']>;
    clineCopyrightHolder?: InputMaybe<Scalars['String']['input']>;
    clineYear?: InputMaybe<Scalars['Int']['input']>;
    customThumbnailPath?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    genreId?: InputMaybe<Scalars['Int']['input']>;
    imprint?: InputMaybe<Scalars['String']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    keywords?: InputMaybe<Array<Scalars['String']['input']>>;
    languageOfVideoContent?: InputMaybe<Scalars['String']['input']>;
    languageOfVideoTitle?: InputMaybe<Scalars['String']['input']>;
    latestPipelineRunId?: InputMaybe<Scalars['Int']['input']>;
    lyrics?: InputMaybe<Scalars['String']['input']>;
    manufacturerUpc?: InputMaybe<Scalars['String']['input']>;
    newRelease?: InputMaybe<Scalars['Boolean']['input']>;
    notForDistribution?: InputMaybe<Scalars['String']['input']>;
    originalReleaseDate?: InputMaybe<Scalars['String']['input']>;
    parentalAdvisory?: InputMaybe<Scalars['String']['input']>;
    plineCopyrightHolder?: InputMaybe<Scalars['String']['input']>;
    plineYear?: InputMaybe<Scalars['Int']['input']>;
    primaryArtists?: InputMaybe<Array<InputMaybe<CreateArtistInput>>>;
    productCode?: InputMaybe<Scalars['String']['input']>;
    productId: Scalars['String']['input'];
    releaseDate?: InputMaybe<Scalars['String']['input']>;
    specialInstructions?: InputMaybe<Scalars['String']['input']>;
    subaccountId?: InputMaybe<Scalars['Int']['input']>;
    subgenreId?: InputMaybe<Scalars['Int']['input']>;
    thumbnailPath?: InputMaybe<Scalars['String']['input']>;
    typeOfVideo?: InputMaybe<Scalars['String']['input']>;
    vendorCatalogNumber?: InputMaybe<Scalars['String']['input']>;
    vendorReleaseIdentifier?: InputMaybe<Scalars['String']['input']>;
    version?: InputMaybe<Scalars['String']['input']>;
    videoTitle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorksheetPaymentContractAdvanceInput = {
    salesforceId?: InputMaybe<Scalars['String']['input']>;
    worksheetPaymentContractAdvanceId: Scalars['ID']['input'];
};

export type UploadPartInput = {
    etag: Scalars['String']['input'];
    partNumber: Scalars['Int']['input'];
};

/**
 * This enum represents list of possible upper profit centers for filtering the response.
 * There is mapping defined product -> upper profit center.
 */
export enum UpperProfitCenter {
    ARIOLA_HANSA = 'ARIOLA_HANSA',
    CATALOG = 'CATALOG',
    CENTURY_MEDIA = 'CENTURY_MEDIA',
    CLASSIC = 'CLASSIC',
    COLUMBIA = 'COLUMBIA',
    COMEDY = 'COMEDY',
    EPIC = 'EPIC',
    FAMILY_ENTERT = 'FAMILY_ENTERT',
    FAMOUZ = 'FAMOUZ',
    FOUR_MUSIC = 'FOUR_MUSIC',
    GOLD_LEAGUE = 'GOLD_LEAGUE',
    JIVE = 'JIVE',
    MEDIA_COMPILATIONS = 'MEDIA_COMPILATIONS',
    NITRON = 'NITRON',
    RCA = 'RCA',
    _1991_ARTIST_LABEL = '_1991_ARTIST_LABEL',
}

export type UpsertMarketingProgramInfoInput = {
    data: Array<MarketingProgramInfoUpsert>;
    entityId: Scalars['Int']['input'];
    entityType: MarketingProgramInfoEntity;
};

export type UpsertTrackAudioAttributeInput = {
    audioAttributeIds: Array<Scalars['ID']['input']>;
    tuid: Scalars['ID']['input'];
};

export type UpsertTrackRightsAttributeInput = {
    rightsAttributeIds: Array<Scalars['ID']['input']>;
    tuid: Scalars['ID']['input'];
};

/**
 * UserMetadataFlag includes the label for the user metadata flag that indicates whether or not the user has opted in
 * to receive email notifications about new resources, vendor_name and value as JSON.
 */
export type UserMetadataFlag = {
    label: UserMetadataLabel;
    value: Scalars['JSON']['input'];
};

export enum UserMetadataLabel {
    /**
     * This is the label for the user metadata flag that indicates whether or not
     * the user has opted in to receive email notifications about new resources
     */
    INVOKED_FROM_LAMBDA = 'INVOKED_FROM_LAMBDA',
    SHOULD_SEND_COLLABORATOR_ACCESS_EMAIL = 'SHOULD_SEND_COLLABORATOR_ACCESS_EMAIL',
    VENDOR_NAME = 'VENDOR_NAME',
}

export enum UserType {
    ARTIST = 'ARTIST',
    EMPLOYEE = 'EMPLOYEE',
    LABEL = 'LABEL',
}

export enum ValidationContext {
    POST_SUBMISSION = 'POST_SUBMISSION',
    PRE_SUBMISSION = 'PRE_SUBMISSION',
}

/** VendorInput for updateVendor mutation. OA context */
export type VendorInput = {
    /** ff show release builder */
    allowAlwReleaseBuilder?: InputMaybe<Scalars['String']['input']>;
    /** OA user ID. main point of contact for the label. */
    assignedReviewerId?: InputMaybe<Scalars['ID']['input']>;
    assignedToId?: InputMaybe<Scalars['ID']['input']>;
    companyBrandId?: InputMaybe<Scalars['ID']['input']>;
    contactEmail?: InputMaybe<Scalars['String']['input']>;
    contactName?: InputMaybe<Scalars['String']['input']>;
    countryId?: InputMaybe<Scalars['Int']['input']>;
    estimatedTotalProducts?: InputMaybe<Scalars['Int']['input']>;
    estimatedTotalTracks?: InputMaybe<Scalars['Int']['input']>;
    /**
     * OA Edit Label field. Indicates if label is
     * owned/operated by the Orchard or if it is a client.
     */
    isOwned?: InputMaybe<Scalars['Boolean']['input']>;
    labelIdentifier?: InputMaybe<LabelIdentifier>;
    labelSummary?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    owner?: InputMaybe<Scalars['String']['input']>;
    parentCompanyUuid?: InputMaybe<Scalars['ID']['input']>;
    primaryGenreId?: InputMaybe<Scalars['ID']['input']>;
    priority?: InputMaybe<Scalars['Int']['input']>;
    /** OA user Id. the product manager for this label. */
    productManagerId?: InputMaybe<Scalars['ID']['input']>;
    /** OA user Id. backup point of contact for the label. */
    quarterbackLabelManagerId?: InputMaybe<Scalars['ID']['input']>;
    /**
     * Legacy AR region table id, distinct from country
     * defined as non-official geographical regions in which
     * SME/the Orchard has conducted business.
     * Ex. South Asia, N. Africa, Central America & Carrib
     */
    regionId?: InputMaybe<Scalars['ID']['input']>;
    serviceTierUuid?: InputMaybe<Scalars['ID']['input']>;
    soundScanCodeCA?: InputMaybe<Scalars['String']['input']>;
    soundScanCodeUSA?: InputMaybe<Scalars['String']['input']>;
    supportContactEmail?: InputMaybe<Scalars['String']['input']>;
    website?: InputMaybe<Scalars['String']['input']>;
    welcomeEmailSent?: InputMaybe<WelcomeEmailInput>;
};

export enum VendorStatus {
    APPROVED = 'APPROVED',
    DELETION = 'DELETION',
    INACTIVE = 'INACTIVE',
    PASSED = 'PASSED',
    PENDING = 'PENDING',
    PITCHED = 'PITCHED',
    SIGNED = 'SIGNED',
    VERBAL = 'VERBAL',
    WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
}

export enum VendorType {
    CATALOG = 'CATALOG',
    CLIENT_SERVICES = 'CLIENT_SERVICES',
    D3 = 'D3',
    FILM = 'FILM',
    FRONTLINE = 'FRONTLINE',
    TEST = 'TEST',
    TV = 'TV',
}

export type VideoCustomThumbnailTokenInput = {
    filename: Scalars['String']['input'];
    workflowJobId: Scalars['Int']['input'];
};

export type VideoIngestWorkflowContextType = {
    productId: Scalars['Int']['input'];
};

export type VideoIngestWorkflowInput = {
    context: VideoIngestWorkflowContextType;
    path: Scalars['String']['input'];
    type: VideoIngestWorkflowType;
};

export enum VideoIngestWorkflowType {
    INGEST_FROM_BROWSER = 'INGEST_FROM_BROWSER',
    INGEST_FROM_DROPBOX = 'INGEST_FROM_DROPBOX',
    INGEST_FROM_GOOGLE_DRIVE = 'INGEST_FROM_GOOGLE_DRIVE',
    INGEST_FROM_S3 = 'INGEST_FROM_S3',
}

export enum VideoSingleProductApprovalType {
    CONTENT = 'CONTENT',
    FINAL = 'FINAL',
    REJECTION = 'REJECTION',
    RELEASE = 'RELEASE',
}

export enum ViewsType {
    AD_SUPPORTED = 'AD_SUPPORTED',
    ALL = 'ALL',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export type WelcomeEmailInput = {
    date: Scalars['Date']['input'];
    senderId: Scalars['ID']['input'];
};

export type WorksheetPaymentContractAdvanceInput = {
    contractAdvanceId: Scalars['ID']['input'];
    paymentName: Scalars['String']['input'];
};

export type _Neo4jDateTimeInput = {
    formatted?: InputMaybe<Scalars['String']['input']>;
};

export type _PublicSocialAccountFilter = {
    platform?: InputMaybe<Scalars['String']['input']>;
};

export type _VideoFilter = {
    timePublished_gte?: InputMaybe<_Neo4jDateTimeInput>;
};
