import React from 'react';
import {
    Alert,
    Form,
    Card,
    LoadingIndicator,
    Button,
} from '@theorchard/suite-components';
import { formatMessage, Segment } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { useCreateGlobalSoundRecordingFromSpotify } from 'src/data/mutations';
import { useLazySoundRecordingSearch } from 'src/data/queries/soundRecordingSearch/soundRecordingSearch';
import { songUrl } from 'src/utils/urls';
import AssociatedSoundRecording from './associatedSoundRecording';
import type { PublishingGlobalSoundRecordingFromSpotifyMutation as SpotifyGSR } from 'src/data/mutations/createGlobalSoundRecordingFromSpotify/__generated__/createGlobalSoundRecordingFromSpotify';
import type { AssociatedSoundRecording as AssociatedSoundRecordingType } from 'src/types';

interface Props {
    associatedSounds: AssociatedSoundRecordingType[];
    setAssociatedSounds: (
        associatedSounds: AssociatedSoundRecordingType[]
    ) => void;
    debounceTime: number;
    category: string;
    isrcDuplicateId?: string;
}

const AssociatedSoundRecordings: React.FC<Props> = ({
    associatedSounds,
    setAssociatedSounds,
    debounceTime,
    category,
    isrcDuplicateId,
}) => {
    const [term, setTerm] = React.useState('');
    const createGlobalSoundRecordingFromSpotify =
        useCreateGlobalSoundRecordingFromSpotify((data: SpotifyGSR) => {
            const gsrToAdd = {
                id: data.createGlobalSoundRecordingFromSpotify.id,
                isrc: data.createGlobalSoundRecordingFromSpotify.isrc,
                name: data.createGlobalSoundRecordingFromSpotify.name,
                performers:
                    data.createGlobalSoundRecordingFromSpotify.globalParticipants?.map(
                        g => g?.name || ''
                    ) || [],
                writers: [],
                isLsr: false,
                isSpotify: true,
            };
            setAssociatedSounds([...associatedSounds, gsrToAdd]);
        });

    const [
        doSoundRecordingSearch,
        { soundRecordings, error, isLoading, isCalled },
    ] = useLazySoundRecordingSearch(associatedSounds, term);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = React.useCallback(
        debounce((searchTerm: string) => {
            const variables = { term: searchTerm.toLowerCase() };
            doSoundRecordingSearch(variables);
        }, debounceTime),
        []
    );

    React.useEffect(() => {
        debounceSearch(term.trim());
        return () => debounceSearch.cancel();
    }, [term, debounceSearch]);

    const remove = (i: number) => {
        const copy = [...associatedSounds];
        copy.splice(i, 1);
        setAssociatedSounds(copy);
    };

    const addGsr = (isrc: string) => {
        void Segment.trackEvent('Click - Added Associated Sound Recording', {
            category,
        });

        const item = soundRecordings.find(r => r.isrc === isrc);
        if (!item) return;

        if (item.isSpotify)
            void createGlobalSoundRecordingFromSpotify({
                variables: { id: item.id },
            });
        else
            setAssociatedSounds([
                ...associatedSounds,
                {
                    id: item.id || '',
                    isrc: item.isrc || '',
                    name: item.name,
                    performers: item.artists || [],
                    writers: [],
                    isLsr: item.isLsr,
                },
            ]);

        setTerm('');
    };

    let errorMessage = '';
    if (error) {
        if (error.spotifyError)
            errorMessage = formatMessage(
                'newSong.errorFetchingSongsFromSpotify'
            );

        if (
            error.owsError ||
            error.gsrError ||
            error.labelParticipantSearchError
        )
            errorMessage = formatMessage('newSong.errorFetchingSongs');
    }
    return (
        <Card body className="NewSong-sound-recordings">
            <div className="NewSong-header">
                <div className="NewSong-header-title">
                    {formatMessage('newSong.associatedSoundRecording')}
                </div>
                <div className="NewSong-header-subtitle">
                    {formatMessage('newSong.associatedSoundRecordingSubTitle')}
                </div>
            </div>

            {associatedSounds.map((associatedSound, i) => (
                <AssociatedSoundRecording
                    associatedSound={associatedSound}
                    remove={() => remove(i)}
                    key={associatedSound.id}
                />
            ))}

            {!!isrcDuplicateId && (
                <Alert
                    variant="warn"
                    text={
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                {formatMessage('newSong.sameIsrcSongExists')}
                            </span>
                            <Link to={songUrl(isrcDuplicateId)}>
                                <Button variant="link" size="sm">
                                    {formatMessage('newSong.goToSong')}
                                    <GlyphIcon name="arrowRight" size={12} />
                                </Button>
                            </Link>
                        </div>
                    }
                />
            )}

            <div className="NewSong-sound-recording-search">
                <div className="NewSong-sound-recording-search-title">
                    {formatMessage('newSong.searchForSound')}
                </div>
                <Form.Control
                    type="text"
                    value={term}
                    placeholder={formatMessage(
                        'newSong.searchForSoundPlaceholder'
                    )}
                    onChange={e => setTerm(e.target.value)}
                />

                {term.length > 0 && (
                    <>
                        {isLoading && <LoadingIndicator />}
                        {soundRecordings.length === 0 &&
                            isCalled &&
                            !isLoading && (
                                <div className="NewSong-sound-recordings-search-no-results">
                                    {formatMessage(
                                        'selectExistingSong.noResults'
                                    )}
                                </div>
                            )}
                        {errorMessage.length > 0 && !isLoading && isCalled && (
                            <div className="NewSong-header-subtitle">
                                {errorMessage}
                            </div>
                        )}
                        <div className="NewSong-sound-recordings-search-results">
                            {!isLoading &&
                                soundRecordings.map(r => (
                                    <div
                                        key={r.isrc}
                                        className="NewSong-sound-recordings-search-row"
                                        onClick={() => r.isrc && addGsr(r.isrc)}
                                    >
                                        <div className="NewSong-sound-recordings-search-row-left">
                                            {r?.artworkUrl ? (
                                                <img
                                                    alt="album artwork"
                                                    src={r.artworkUrl}
                                                    className="NewSong-sound-recordings-search-image"
                                                />
                                            ) : (
                                                <div className="NewSong-sound-recordings-search-image-placeholder" />
                                            )}
                                            <div>
                                                <div>{r?.name}</div>
                                                <div>
                                                    {r?.artists.join(', ')}
                                                </div>
                                            </div>
                                        </div>
                                        <div>{r?.isrc}</div>
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

export default AssociatedSoundRecordings;
