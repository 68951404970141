/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TrackSearchQueryVariables = Types.Exact<{
    term: Types.Scalars['String']['input'];
}>;

export type TrackSearchQuery = {
    __typename?: 'Query';
    trackSearch: {
        __typename?: 'TrackSearchResults';
        totalCount: number;
        tracks: Array<{
            __typename?: 'Track';
            isrc?: string | null;
            trackName?: string | null;
            analytics?: {
                __typename?: 'TrackAnalytics';
                streams: {
                    __typename?: 'Streams';
                    aggregate: {
                        __typename?: 'StreamData';
                        allTime?: number | null;
                    };
                };
            } | null;
            labelSoundRecording?: {
                __typename?: 'LabelSoundRecording';
                id: string;
                globalSoundRecording?: {
                    __typename?: 'GlobalSoundRecording';
                    id: string;
                } | null;
            } | null;
            participations: Array<{
                __typename?: 'LabelTrackParticipation';
                participatedAs: string;
                participant?: {
                    __typename?: 'LabelParticipant';
                    name: string;
                } | null;
            }>;
            product?: {
                __typename?: 'Product';
                releaseDate?: string | null;
                imageLocation?: string | null;
                upc?: string | null;
                productName: string;
                status?: string | null;
            } | null;
        }>;
    };
};

export const TrackSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'TrackSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'term' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'term' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'term' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '20' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: { kind: 'IntValue', value: '0' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tracks' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isrc',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'trackName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analytics',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'streams',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'aggregate',
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'allTime',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'labelSoundRecording',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'globalSoundRecording',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'participations',
                                                },
                                                arguments: [
                                                    {
                                                        kind: 'Argument',
                                                        name: {
                                                            kind: 'Name',
                                                            value: 'filter',
                                                        },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: {
                                                                        kind: 'Name',
                                                                        value: 'participated_as_in',
                                                                    },
                                                                    value: {
                                                                        kind: 'ListValue',
                                                                        values: [
                                                                            {
                                                                                kind: 'StringValue',
                                                                                value: 'performer',
                                                                                block: false,
                                                                            },
                                                                            {
                                                                                kind: 'StringValue',
                                                                                value: 'track_writer',
                                                                                block: false,
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'participatedAs',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'participant',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'product',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'releaseDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'imageLocation',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'upc',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'productName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'status',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TrackSearchQuery, TrackSearchQueryVariables>;
