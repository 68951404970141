import { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    TrackSearchDocument,
    type TrackSearchQuery,
    type TrackSearchQueryVariables,
} from 'src/data/queries/trackSearch/__generated__/trackSearch';
import type { ApolloError } from '@apollo/client';
import type { OwsSearchTracks } from 'src/types';

interface Variables extends TrackSearchQueryVariables {
    queryId?: number;
}

export type OwsSearchResult = [
    (variables: Variables) => void,
    {
        loading: boolean;
        called: boolean;
        error?: ApolloError;
        songs: OwsSearchTracks;
    },
];
export const useLazyTrackSearch = (): OwsSearchResult => {
    const releaseDate = useRef<string | null>(null);
    const queryId = useRef(0);
    const abortController = useRef<AbortController>();
    if (!abortController.current)
        abortController.current = new AbortController();

    const { signal } = abortController.current;

    const [doTrackSearch, trackSearchResult] = useLazyQuery<TrackSearchQuery>(
        TrackSearchDocument,
        {
            context: { fetchOptions: { signal } },
        }
    );

    const songsData = trackSearchResult?.data?.trackSearch.tracks || [];

    const filteredSongsData: OwsSearchTracks = [];

    if (releaseDate.current) {
        const now = new Date().getTime();
        filteredSongsData.push(
            ...songsData.filter(
                song =>
                    song.product?.releaseDate &&
                    song.product?.status === 'in_content' &&
                    new Date(song.product?.releaseDate).getTime() > now
            )
        );
    } else filteredSongsData.push(...songsData);

    return [
        variables => {
            abortController.current?.abort();
            abortController.current = new AbortController();
            if (variables.term.length)
                void doTrackSearch({
                    variables: { queryId: queryId.current++, ...variables },
                });
        },
        {
            ...trackSearchResult,
            songs: filteredSongsData,
        },
    ];
};
