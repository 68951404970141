import React, { useState } from 'react';
import {
    Form,
    Field,
    NumberInput,
    Dropdown,
    Sidecar,
    Alert,
    Control,
    Button,
    Label,
} from '@theorchard/suite-components';
import {
    formatMessage,
    Segment,
    useAppConfig,
} from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import { CONTACT_EMAILS, PRO_LIST } from 'src/constants';
import { useUpdateSongWriterMetaData } from 'src/data/mutations';
import { pkaError } from 'src/utils/error';
import { useLimitModifyAccessFF } from 'src/utils/features';
import { isValidIpi } from 'src/utils/validators';
import type { PublishingCreateSongWriterMutation } from 'src/data/mutations/createSongWriter/__generated__/createSongWriter';
import type { SongWriter } from 'src/types';

interface Props {
    isOpen: boolean;
    onClose: (result?: PublishingCreateSongWriterMutation) => void;
    songWriter: SongWriter | null;
}

const EditSongWriter: React.FC<Props> = ({ songWriter, isOpen, onClose }) => {
    const isLimitedModifyAccess = useLimitModifyAccessFF();
    const config = useAppConfig();
    const publishingAppContactEmail = CONTACT_EMAILS[config.brand].appContact;

    const isControlledMixed =
        songWriter?.agreements?.some(a => a.controlled) &&
        songWriter?.agreements?.some(a => !a.controlled);
    const [name, setName] = useState(songWriter?.legalName);
    const [professionallyKnownAs, setProfessionallyKnownAs] = useState(
        songWriter?.professionallyKnownAs || []
    );
    const [pro, setPro] = useState(songWriter?.pro);
    const [ipi, setIpi] = useState(songWriter?.ipi);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [songWriterValidationErrorKeys, setSongWriterValidationErrorKeys] =
        useState<string[] | null>(null);

    const determineIsControlled = () => {
        if (
            songWriter?.agreements.every(({ controlled }) => controlled) &&
            (!ipi || !pro)
        )
            return true;
        if (songWriter?.agreements.every(({ controlled }) => controlled))
            return true;
        if (songWriter?.agreements.every(({ controlled }) => !controlled))
            return false;
        return null;
    };

    const [isControlled, setIsControlled] = useState<boolean | null>(
        determineIsControlled()
    );

    const saveEnabled =
        (isControlled && !!ipi && !!pro) ||
        !isControlled ||
        (isControlledMixed && !!ipi && !!pro);

    const reset = () => {
        setHasSubmitted(false);
        setName('');
        setIsControlled(null);
        setIpi('');
        setPro(null);
        setSongWriterValidationErrorKeys(null);
        setProfessionallyKnownAs([]);
    };

    const closeAndReset = () => {
        onClose();
        reset();
    };
    const onSubmitCB = (
        result: object | null,
        errors: string[] | undefined
    ) => {
        if (result) onClose(result as PublishingCreateSongWriterMutation);
        else if (errors) setSongWriterValidationErrorKeys(errors);
    };
    const updateSongWriterMetaData = useUpdateSongWriterMetaData(onSubmitCB);

    const nameIsValid = /\w \w/.exec(name || '');
    const nameIsInvalid = !nameIsValid;
    const category = formatMessage('songWriterManager.editSongWriter');
    const songWriterIpiIsInvalid = ipi && !isValidIpi(ipi);
    const songWriterIpiIsNotUnique = songWriterValidationErrorKeys?.includes(
        'songWriterIPICollision'
    );
    const professionallyKnownAsIsInvalid =
        professionallyKnownAs?.some(pkaError);

    const onIpiChange = (value: string, setter: (v: string) => void) => {
        setSongWriterValidationErrorKeys(null);
        let formatted = value.slice(-11);
        if (value.length < 11) formatted = `00000000000${value}`.slice(-11);
        setter(formatted);
    };

    const addPKA = () => {
        setProfessionallyKnownAs([...professionallyKnownAs, '']);
    };
    const setPKA = (value: string, i: number) => {
        const newValues = [...professionallyKnownAs];
        newValues[i] = value;
        setProfessionallyKnownAs(newValues);
    };

    const removePKA = (i: number) => {
        const copy = [...professionallyKnownAs];
        copy.splice(i, 1);
        setProfessionallyKnownAs(copy);
    };

    const onSave = () => {
        if (isLimitedModifyAccess || !saveEnabled) return;

        setHasSubmitted(true);

        if (
            nameIsInvalid ||
            songWriterIpiIsInvalid ||
            songWriterValidationErrorKeys ||
            professionallyKnownAsIsInvalid
        )
            return;

        void Segment.trackEvent('Click - Edit Songwriter', {
            category,
            songWriterId: songWriter?.id,
            songWriterLegalName: songWriter?.legalName,
            songWriterProfessionallyKnownAs: songWriter?.professionallyKnownAs,
            songWriterIpi: songWriter?.ipi,
            songWriterPro: songWriter?.pro,
        });

        const variables = {
            id: songWriter?.id || '',
            name: name || songWriter?.legalName || '',
            professionallyKnownAs:
                professionallyKnownAs.length !== 0 ||
                songWriter?.professionallyKnownAs.length !== 0
                    ? professionallyKnownAs || songWriter?.professionallyKnownAs
                    : null,
            ipi: ipi || songWriter?.ipi || '',
            pro: pro || songWriter?.pro || '',
            label: { uuid: songWriter?.vendor?.uuid || '' },
            controlled: isControlled,
            publishers: [],
        };

        void updateSongWriterMetaData({ variables });
    };

    return (
        <Sidecar
            isOpen={isOpen}
            onRequestClose={closeAndReset}
            className={cx(
                'SongWriterManager',
                isLimitedModifyAccess ? 'save-disabled' : ''
            )}
            header={formatMessage('songWriterManager.editSongWriter')}
            cancelTitle={formatMessage('generic.cancel')}
            confirmTitle={formatMessage('generic.save')}
            onConfirm={onSave}
            confirmDisabled={!saveEnabled}
        >
            <Form validated={hasSubmitted}>
                <Field
                    controlId="legal-name"
                    labelText={formatMessage(
                        'songWriterManager.songWriterLegalName'
                    )}
                    isRequired
                    errorMessage={
                        (hasSubmitted &&
                            !nameIsValid &&
                            formatMessage('errors.nameNeedsSpace')) ||
                        undefined
                    }
                >
                    <Form.Control
                        type="text"
                        placeholder={formatMessage(
                            'songWriterManager.songWriterLegalNamePlaceholder'
                        )}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Field>

                {professionallyKnownAs.length !== 0 && (
                    <Label
                        text={formatMessage(
                            'songWriterManager.professionallyKnownAs'
                        )}
                        isRequired
                    />
                )}
                {professionallyKnownAs.map((pka, i) => (
                    <Control
                        errorMessage={
                            (hasSubmitted && pkaError(pka)) || undefined
                        }
                        key={i}
                        className="SongWriterManager-pka-field-control"
                    >
                        <div className="SongWriterManager-pka-field">
                            <Form.Control
                                type="text"
                                value={pka}
                                placeholder={formatMessage(
                                    'songWriterManager.professionallyKnownAsPlaceholder'
                                )}
                                onChange={e => setPKA(e.target.value, i)}
                            />
                            <div
                                className="SongWriterManager-pka-field-x"
                                onClick={() => removePKA(i)}
                            >
                                <GlyphIcon name="close" size={16} />
                            </div>
                        </div>
                    </Control>
                ))}

                <Button variant="link" className="icon-left" onClick={addPKA}>
                    <GlyphIcon name="plus" size={16} />
                    {formatMessage(
                        'songWriterManager.addProfessionallyKnownAs'
                    )}
                </Button>

                <Field
                    controlId="isControlled"
                    labelText={formatMessage('songWriterManager.isControlled')}
                    isRequired
                >
                    <Form.Check
                        checked={isControlled === true}
                        onChange={() => setIsControlled(true)}
                        name="isControlled"
                        label={formatMessage('generic.yes')}
                        type="radio"
                        id="isControlled1"
                    />
                    <Form.Check
                        checked={isControlled === false}
                        onChange={() => setIsControlled(false)}
                        name="isControlled"
                        label={formatMessage('generic.no')}
                        type="radio"
                        id="isControlled2"
                    />
                    <Form.Check
                        disabled
                        checked={isControlledMixed && isControlled === null}
                        name="isControlled"
                        label={formatMessage('generic.both')}
                        type="radio"
                        id="isControlled3"
                    />
                </Field>
                <div>
                    <Field
                        controlId="pro"
                        labelText={formatMessage(
                            'songWriterManager.songwritersPro'
                        )}
                        isRequired={!!isControlled}
                    >
                        <Dropdown
                            options={PRO_LIST.map((p: string) => ({
                                value: p,
                                label: p,
                            }))}
                            placeholder={formatMessage(
                                'songWriterManager.songwriterProPlaceholder'
                            )}
                            selectedValue={pro || undefined}
                            onChange={option => setPro(option?.value)}
                        />
                    </Field>

                    <Field
                        controlId="ipi"
                        labelText={formatMessage(
                            'songWriterManager.songwritersIpi'
                        )}
                        isRequired={!!isControlled}
                        errorMessage={
                            (hasSubmitted &&
                                ((songWriterIpiIsInvalid &&
                                    formatMessage('errors.ipiNeeds9Chars')) ||
                                    (songWriterIpiIsNotUnique &&
                                        formatMessage(
                                            'errors.songWriterIPICollision'
                                        )))) ||
                            undefined
                        }
                    >
                        <NumberInput
                            value={ipi || ''}
                            onChange={(value: string) =>
                                onIpiChange(value, setIpi)
                            }
                            allowNegatives={false}
                            placeholder={formatMessage(
                                'songWriterManager.songwriterIpiPlaceholder'
                            )}
                        />
                    </Field>
                </div>
                {isControlled && (!ipi || !pro) && (
                    <Alert
                        title={formatMessage(
                            'songWriterManager.notRegisteredTitle'
                        )}
                        variant="warn"
                        text={
                            <>
                                {formatMessage(
                                    'songWriterManager.notRegisteredSubTitle'
                                )}
                                <a href={`mailto:${publishingAppContactEmail}`}>
                                    {publishingAppContactEmail}
                                </a>
                            </>
                        }
                    />
                )}
            </Form>
        </Sidecar>
    );
};

export default EditSongWriter;
